/**
 * @author zhengji.su
 * @description AnimationShowElement
 */

import { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { gsap } from 'gsap/dist/gsap';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import useUnMount from 'utils/Hooks/updateEffect/useUnMount';
import useDeepCompareEffect from 'utils/Hooks/updateEffect/useDeepCompareEffect';

const useStyles = makeStyles(() => ({
  root: {
    top: ({ top }) => top ?? 0,
    right: 0,
    zIndex: ({ zIndex }) => zIndex ?? 999,
  },
  animationCover: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  initial: {
    transform: 'initial !important',
  },
}));

const AnimationShowElement = forwardRef(
  (
    {
      from = {},
      to = {},
      children,
      visible = true,
      onBackdropClose,
      top,
      hover,
      zIndex,
      initialTransform,
      duration = 0.5,
      scale = 1.01,
      ...other
    },
    ref,
  ) => {
    const classes = useStyles({ top, zIndex, ...other });
    const [show, setShow] = useState(false);
    const [initial, setInitial] = useState(null);

    const boxRef = useRef(null);
    const bodyRef = useRef(document.querySelector('body'));

    const vars = useRef({
      opacity: 0,
      y: 0,
    });

    useImperativeHandle(ref, () => ({
      clientHeight: boxRef.current?.clientHeight,
    }));

    useDeepCompareEffect(() => {
      if (boxRef && visible) {
        gsap.fromTo(
          boxRef.current,
          {
            duration,
            scale,
            ...vars.current,
            ...from,
          },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            onStart: () => setShow(true),
            onComplete: () => setInitial('open'),
            ...to,
          },
        );
      }
    }, [boxRef, visible, duration, scale, from, to]);

    const onClose = () => {
      gsap.to(boxRef.current, {
        duration,
        scale,
        ...vars.current,
        ...from,
        onComplete: () => {
          setShow(false);
          if (onBackdropClose) {
            onBackdropClose();
          }
        },
      });
    };

    useEffect(() => {
      if (!visible) {
        onClose();
      }
    }, [visible]);

    useUnMount(() => setInitial(null));

    return (
      <>
        <Box
          className={clsx(classes.root, 'animation', {
            [classes.initial]: initialTransform && initial === 'open',
          })}
          position="relative"
          ref={boxRef}
          {...other}
        >
          {onBackdropClose ? <Box>{show && children}</Box> : show && children}
        </Box>
        {onBackdropClose &&
          show &&
          createPortal(
            <Box
              className={classes.animationCover}
              {...(hover ? { onMouseEnter: onClose } : { onClick: onClose })}
            />,
            bodyRef.current,
          )}
      </>
    );
  },
);

AnimationShowElement.propTypes = {
  from: PropTypes.object,
  to: PropTypes.object,
  children: PropTypes.node,
  visible: PropTypes.bool,
  onBackdropClose: PropTypes.func,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hover: PropTypes.bool,
  zIndex: PropTypes.number,
  initialTransform: PropTypes.bool,
  duration: PropTypes.number,
  scale: PropTypes.number,
};

export default AnimationShowElement;
