/*
 * Cart Messages
 *
 * This contains all the text for the Cart container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Cart';

export default defineMessages({
  cart: {
    id: `${scope}.cart`,
    defaultMessage: 'Cart',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Add to Cart',
  },
  addingToCart: {
    id: `${scope}.addingToCart`,
    defaultMessage: 'Adding...',
  },
  onSale: {
    id: `${scope}.onSale`,
    defaultMessage: 'On Sale',
  },
  timeLeft: {
    id: `${scope}.timeLeft`,
    defaultMessage: 'Time left: ',
  },
  pleaseWaiting: {
    id: `${scope}.pleaseWaiting`,
    defaultMessage: 'Please wait a moment',
  },
  myCart: {
    id: `${scope}.myCart`,
    defaultMessage: 'My Cart',
  },
  cartPage: {
    id: `${scope}.cartPage`,
    defaultMessage: 'Cart Page',
  },
  loadingCart: {
    id: `${scope}.loadingCart`,
    defaultMessage: 'Loading your cart',
  },
  productHasBeenAddedToCart: {
    id: `${scope}.productHasBeenAddedToCart`,
    defaultMessage: '{name} has been added to your cart.',
  },
  checkOut: {
    id: `${scope}.checkOut`,
    defaultMessage: 'Check Out{quantity, plural, =0 {} one { ({quantity})} other { ({quantity})}}',
  },
  cartIsEmpty: {
    id: `${scope}.cartIsEmpty`,
    defaultMessage: 'Your Cart is Empty',
  },
  youHaveWishListItems: {
    id: `${scope}.youHaveWishListItems`,
    defaultMessage:
      'You have {quantity, plural, =0 {nothing} one {one item} other {{quantity} items}} in your wish list',
  },
  selectItemsToAdd: {
    id: `${scope}.selectItemsToAdd`,
    defaultMessage: 'Select items to add to your cart',
  },
  addSelectedItemsToCart: {
    id: `${scope}.addSelectedItemsToCart`,
    defaultMessage: 'Add selected items to cart',
  },
  addFromWishlist: {
    id: `${scope}.addFromWishlist`,
    defaultMessage:
      'Add From Wishlist{quantity, plural, =0 {} one { ({quantity})} other { ({quantity})}}',
  },
  estimation: {
    id: `${scope}.estimation`,
    defaultMessage: 'Estimation',
  },
  continueShopping: {
    id: `${scope}.continueShopping`,
    defaultMessage: 'Continue',
  },
  editCart: {
    id: `${scope}.editCart`,
    defaultMessage: 'View And Edit Cart',
  },
  confirmRemovalOfItem: {
    id: `${scope}.confirmRemovalOfItem`,
    defaultMessage: 'Confirm removal of item',
  },
  deleteItem: {
    id: `${scope}.deleteItem`,
    defaultMessage: 'Delete Item',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing',
  },
  destructiveAction: {
    id: `${scope}.destructiveAction`,
    defaultMessage: 'Permanently delete this {cartName}? This action cannot be undone.',
  },
  cartSelectAll: {
    id: `${scope}.cartSelectAll`,
    defaultMessage: 'Select All',
  },
  buyOthers: {
    id: `${scope}.buyOthers`,
    defaultMessage: 'Buy Others',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue browsing',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit shopping cart',
  },
  shoppingListQRCode: {
    id: `${scope}.shoppingListQRCode`,
    defaultMessage: 'List QR code',
  },
  stock: {
    id: `${scope}.stock`,
    defaultMessages: 'stock: {total} items',
  },
  stockInsufficient: {
    id: `${scope}.stockInsufficient`,
    defaultMessages: 'Insufficient',
  },
  insufficient: {
    id: `${scope}.insufficient`,
    defaultMessage: 'Insufficient inventory of goods',
  },
  checkStore: {
    id: `${scope}.checkStore`,
    defaultMessage: 'Check store',
  },
  flagshipStoreCheckout: {
    id: `${scope}.flagshipStoreCheckout`,
    defaultMessages: 'Flagship store checkout',
  },
  insufficientNotice: {
    id: `${scope}.insufficientNotice`,
    defaultMessages:
      'Dear customer, there are goods in stock in this store. You can choose to continue checking out. The goods will be shipped from the flagship store when checking out, or you can switch stores to buy',
  },
  repeatWish: {
    id: `${scope}.repeatWish`,
    defaultMessages: 'The product is already in your wishlist',
  },
  joinFavSuccess: {
    id: `${scope}.joinFavSuccess`,
    defaultMessage: 'Added to favorites',
  },
  successfullyDeleted: {
    id: `${scope}.successfullyDeleted`,
    defaultMessage: 'Successfully Deleted',
  },
});
