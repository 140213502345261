import { forwardRef, useRef, useImperativeHandle } from 'react';
import { FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';

const Form = forwardRef(({ children, onFinish, onError, observer = {}, ...other }, ref) => {
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    form: formRef.current,
  }));

  const { handleSubmit } = observer;

  return (
    <FormProvider {...observer}>
      <form onSubmit={handleSubmit(onFinish, onError)} {...other} ref={formRef}>
        {children}
      </form>
    </FormProvider>
  );
});

Form.propTypes = {
  children: PropTypes.node,
  onFinish: PropTypes.func,
  onError: PropTypes.func,
  handleSubmit: PropTypes.func,
  observer: PropTypes.object,
};

export default Form;
