import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { AimIcon, AngleLeftIcon16 } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';
import SvgIcon from '@material-ui/core/SvgIcon';
import WholePageDialog from 'components/Dialogs/WholePageDialog';
import NavigationBar from 'components/NavigationBar';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import useStore from 'containers/StoreProvider/hooks/useStore';
import PageLoader from 'components/PageLoader';
import FindStore from 'containers/StoreProvider/components/FindStore';
import { MapProvider } from 'containers/StoreProvider/components/MapContext';
import StoreList from './components/StoreList';
import messages from './messages';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StoreProvider = () => {
  const { storeList, getNearbyStores, updateSource, toggleStoreVisible, storeVisible, isLoading } =
    useStore();

  const handleClose = () => {
    toggleStoreVisible(false);
  };

  const handleSelection = s => {
    updateSource(s);
    handleClose();
  };

  const handleLocation = () => {
    getNearbyStores();
  };

  const renderHead = ({ setOpen }) => (
    <NavigationBar
      border
      title={<FormattedMessage {...messages.selectStore} />}
      onClick={setOpen}
      rightItem={
        <Buttons
          startIcon={
            <SvgIcon component={AimIcon} viewBox="0 0 12 12" fontSize="small" color="primary" />
          }
          messageId={
            <Typography variant="h4">
              <FormattedMessage {...messages.reLocation} />
            </Typography>
          }
          onClick={handleLocation}
        />
      }
    />
  );

  return (
    <>
      <PageLoader loading={isLoading} />
      <Hidden lgUp>
        <WholePageDialog
          title={<FormattedMessage {...messages.selectStore} />}
          open={storeVisible}
          setOpen={handleClose}
          icon={<SvgIcon component={AngleLeftIcon16} viewBox="0 0 16 16" color="primary" />}
          headRender={renderHead}
          containerProps={{ style: { padding: 0 } }}
          TransitionComponent={Transition}
        >
          <Box minHeight={300} overflow="scroll" pb="64px" maxHeight="calc(100vh - 48px)">
            <StoreList list={storeList} onSelect={handleSelection} />
          </Box>
        </WholePageDialog>
      </Hidden>
      <Hidden mdDown>
        <MapProvider>
          <FindStore open={storeVisible} onClose={handleClose} />
        </MapProvider>
      </Hidden>
    </>
  );
};

StoreProvider.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default StoreProvider;
