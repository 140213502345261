/**
 *
 * LocationPicker
 *
 * Localized region
 *
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isNull from 'lodash/isNull';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MultiPicker from 'rmc-picker/lib/MultiPicker';
import Picker from 'rmc-picker/lib/Picker';
import 'rmc-picker/assets/index.css';
import 'rmc-picker/assets/popup.css';
import BottomDialog from 'components/Dialogs/BottomDialog';
import { useGetCities, useDistricts } from 'containers/AddressBookPage/queries';
import { useGetStoreCountries } from 'containers/App/queries';
import validationMessages from 'utils/Validation/messages';
import appMessages from 'containers/App/messages';
import SvgIcon from '@material-ui/core/SvgIcon';
import { TriangleIcon } from 'components/Icons';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-end',
    textAlign: 'left',
  },
  paper: {
    width: '100%',
    margin: 0,
    borderRadius: 0,
  },
  field: {
    maxHeight: '64px',
    height: 56,
    padding: '21px 14px',
    backgroundColor: theme.palette.background.peach,
    border: `1px solid ${theme.palette.background.peach}`,
    borderRadius: 8,
    textTransform: 'initial',
    overflow: 'hidden',
    '&:hover': {
      borderColor: theme.palette.background.darkPeach,
      backgroundColor: theme.palette.background.peach,
    },
  },
  fieldError: {
    borderColor: theme.palette.warning.main,
    '&:hover': {
      borderColor: theme.palette.warning.main,
    },
  },
  fieldIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  fieldLabel: {
    flexGrow: 1,
    textAlign: 'left',
    maxWidth: '80%',
    overflow: 'hidden',
    fontSize: '0.875rem',
  },
  buttonLabel: {
    justifyContent: 'space-between',
    fontWeight: 400,
  },
}));

function LocationPicker({ initialValues, onSubmit, error }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [region, setRegion] = useState(617);
  const [city, setCity] = useState(417);
  const [district, setDistrict] = useState(null);
  const [placeholder, setPlaceholder] = useState(
    <Typography component="span" color="textSecondary" className={classes.fieldLabel}>
      <FormattedMessage {...messages.locationPlaceholder} />
    </Typography>,
  );

  const { data: regionsData } = useGetStoreCountries();
  const { data: citiesData, isLoading: loadingCities, error: errorCities } = useGetCities(region);
  const {
    data: discticsData,
    isLoading: loadingDistrics,
    error: errorDistrics,
  } = useDistricts(city);

  useEffect(() => {
    if (!loadingCities && !errorCities) {
      setCity(citiesData[0].id);
    }
  }, [citiesData]);

  useEffect(() => {
    if (!loadingDistrics && !errorDistrics) {
      setDistrict(discticsData[0].id);
    }
  }, [discticsData]);

  const handleOpenPicker = () => setOpen(true);

  const formatCurrentLocation = () => {
    if (
      isNull(region) ||
      !citiesData[region] ||
      isNull(city) ||
      !discticsData[city] ||
      isNull(district)
    ) {
      return (
        <Typography component="span" color="textSecondary" className={classes.fieldLabel}>
          <FormattedMessage {...messages.locationPlaceholder} />
        </Typography>
      );
    }

    const currentRegionRaw = regionsData.find(r => r.id === region);
    const currentRegion = currentRegionRaw ? `${currentRegionRaw.name}, ` : '';
    const currentCityRaw = citiesData[region].find(r => r.id === city);
    const currentCity = currentCityRaw ? `${currentCityRaw.name}, ` : '';
    const currentDistrictRaw = discticsData[city].find(r => r.id === district);
    const currentDistrict = currentDistrictRaw ? currentDistrictRaw.name : '';

    return (
      <Typography variant="body1" display="inline" className={classes.fieldLabel}>
        {currentRegion + currentCity + currentDistrict}
      </Typography>
    );
  };

  useEffect(() => {
    if (initialValues && !initialValues.some(v => !v?.value || v?.value === '')) {
      setPlaceholder(
        `${initialValues[0]?.label}, ${initialValues[1]?.label}, ${initialValues[2]?.label}`,
      );
      setRegion(initialValues[0]?.value);
      setCity(initialValues[1]?.value);
      setDistrict(initialValues[2]?.value);
    }
  }, [initialValues]);

  const handleClosePicker = () => {
    setPlaceholder(formatCurrentLocation);
    if (onSubmit) {
      const currentRegion = regionsData?.find(r => r?.id === region);
      const currentCity = citiesData?.find(r => r?.id === city);
      const currentDistrict = discticsData?.find(r => r?.id === district);
      onSubmit({
        region: { value: parseInt(region, 10), label: currentRegion.name },
        city: { value: parseInt(city, 10), label: currentCity.name },
        district: { value: parseInt(district, 10), label: currentDistrict.name },
      });
    }
    setOpen(false);
  };

  const handleChange = v => {
    if (v[0] !== region) setRegion(v[0]);
    if (v[1] !== city) setCity(v[1]);
    if (v[2] !== district) setDistrict(v[2]);
  };

  return (
    <>
      <FormControl error={error} fullWidth>
        <Button
          endIcon={<SvgIcon component={TriangleIcon} viewBox="0 0 16 16" color="primary" />}
          onClick={handleOpenPicker}
          classes={{
            root: clsx(classes.field, { [classes.fieldError]: error }),
            label: classes.buttonLabel,
          }}
          variant="outlined"
          color="inherit"
          fullWidth
        >
          {placeholder}
        </Button>

        {error && (
          <FormHelperText variant="filled">
            <FormattedMessage {...validationMessages.required} />
          </FormHelperText>
        )}
      </FormControl>
      <BottomDialog
        open={open}
        setOpen={handleClosePicker}
        messageId={
          <Typography variant="h5" component="span" color="textPrimary">
            <FormattedMessage {...appMessages.ok} />
          </Typography>
        }
      >
        <MultiPicker selectedValue={[region, city, district]} onValueChange={handleChange}>
          <Picker>
            {regionsData &&
              regionsData?.map(p => (
                <Picker.Item value={p.id} key={p.id}>
                  {p.name}
                </Picker.Item>
              ))}
          </Picker>
          <Picker>
            {citiesData &&
              citiesData?.map(p => (
                <Picker.Item value={p.id} key={p.id}>
                  {p.name}
                </Picker.Item>
              ))}
          </Picker>
          <Picker>
            {discticsData &&
              discticsData?.map(p => (
                <Picker.Item value={p.id} key={p.id}>
                  {p.name}
                </Picker.Item>
              ))}
          </Picker>
        </MultiPicker>
      </BottomDialog>
    </>
  );
}

LocationPicker.defaultProps = {
  initialValues: [],
  error: false,
};

LocationPicker.propTypes = {
  initialValues: PropTypes.array,
  onSubmit: PropTypes.func,
  error: PropTypes.bool,
};

export default LocationPicker;
