/* eslint-disable */
import PropTypes from 'prop-types';

function CheckoutCompleteIcon4({ width, height, fill }) {
  return (
    <svg height={height} width={width} viewBox="0 0 110 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g id="---7.-Checkout" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g id="Checkout---Order-Placed---Offline-Payment" transform="translate(-133 -104)">
            <g id="Group-2" transform="translate(16 104)">
              <g id="Group" transform="translate(117)">
                <g id="gift-exchange" transform="translate(15 22)">
                  <path
                    id="Path"
                    d="M72.417 57.9c-1.795-2.072-7.213-4.558-7.213-4.558l-3.422 2.28.357 4.558 8.91.207 1.368-2.486z"
                    fill="#EAD8C5"
                    fillRule="nonzero"
                  />
                  <path
                    id="Shape"
                    d="M56.757 0C49.28 0 45.103 6.074 43.045 10.493 40.987 6.073 36.81 0 29.333 0c-4.725 0-8.57 3.838-8.57 8.555s3.845 8.555 8.57 8.555h27.424c4.725 0 8.57-3.838 8.57-8.555S61.482 0 56.757 0zM29.333 13.688c-2.836 0-5.142-2.303-5.142-5.133s2.306-5.133 5.142-5.133c6.521 0 9.973 6.723 11.338 10.266H29.333zm27.424 0H45.418c1.366-3.543 4.818-10.266 11.339-10.266 2.835 0 5.142 2.302 5.142 5.133 0 2.83-2.307 5.133-5.142 5.133z"
                    fill="#F6D7A4"
                  />
                  <path
                    id="Path"
                    d="M68.485 57.871h-53.1a1.712 1.712 0 0 1-1.712-1.711V25.363c0-.945.767-1.711 1.713-1.711h54.811c.946 0 1.713.766 1.713 1.71V54.45a3.424 3.424 0 0 1-3.425 3.422z"
                    fill="#F6D7A4"
                  />
                  <path
                    id="Path"
                    d="M75.576 27.174H10.514c-1.891 0-3.424-1.52-3.424-3.397v-6.793c0-1.876 1.533-3.397 3.424-3.397h65.062c1.89 0 3.424 1.52 3.424 3.397v6.793c0 1.876-1.533 3.397-3.424 3.397z"
                    fill="#FFF3E0"
                  />
                  <path id="Rectangle" d="M34.436 13.587h17.218v13.587H34.436z" fill="#F5CCD7" />
                  <path id="Rectangle" d="M34.436 27.174h17.218v30.697H34.436z" fill="#CD0039" />
                  <path
                    id="Path"
                    d="M72.43 57.786H27.478c-1.897 0-3.434-1.525-3.434-3.405s1.537-3.405 3.434-3.405h15.166c2.79 0 5.29-2.07 5.436-4.832.156-2.946-2.207-5.383-5.145-5.383H0v24.65l28.25 11.675c3.127 1.162 6.587 1.145 9.77.349L75.961 63.95c-.799-2.64-2.018-4.66-3.532-6.165z"
                    fill="#FFEDDA"
                    fillRule="nonzero"
                  />
                </g>
                <path
                  id="Path"
                  d="M13.672 59c-.233 0-.462-.06-.668-.173l-4.008-2.205-4.007 2.205c-.484.266-1.07.22-1.512-.116a1.539 1.539 0 0 1-.572-1.47l.764-4.666-3.242-3.311a1.555 1.555 0 0 1-.356-1.537 1.458 1.458 0 0 1 1.153-1.022l4.48-.683 2.012-4.25A1.482 1.482 0 0 1 9.004 41c.53 0 1.02.294 1.287.772l2.006 4.25 4.479.683c.538.085.984.48 1.153 1.022a1.555 1.555 0 0 1-.356 1.537l-3.242 3.311.764 4.666c.072.438-.045.887-.32 1.227-.274.339-.678.534-1.103.532z"
                  fill="#FFF3E0"
                />
                <path
                  id="Path"
                  d="M73.682 18c-.233 0-.463-.06-.669-.173l-4.01-2.203-4.01 2.203a1.386 1.386 0 0 1-1.514-.115 1.537 1.537 0 0 1-.572-1.47l.765-4.663-3.245-3.308a1.552 1.552 0 0 1-.356-1.537 1.458 1.458 0 0 1 1.154-1.02l4.482-.683L67.708.77A1.483 1.483 0 0 1 68.998 0c.53 0 1.02.294 1.288.772l2.007 4.247 4.482.683c.538.084.985.48 1.154 1.02a1.552 1.552 0 0 1-.356 1.537l-3.245 3.308.765 4.663c.075.437-.038.887-.31 1.229-.273.341-.676.54-1.1.541z"
                  fill="#FFF3E0"
                />
                <path
                  id="Path"
                  d="M44.8 13.8a3.6 3.6 0 0 1-3.6-3.6 1.2 1.2 0 1 0-2.4 0 3.6 3.6 0 0 1-3.6 3.6 1.2 1.2 0 1 0 0 2.4 3.6 3.6 0 0 1 3.6 3.6 1.2 1.2 0 1 0 2.4 0 3.6 3.6 0 0 1 3.6-3.6 1.2 1.2 0 1 0 0-2.4z"
                  fill="#F5CCD7"
                />
                <path
                  id="Path-Copy"
                  d="M108.8 55.8a3.6 3.6 0 0 1-3.6-3.6 1.2 1.2 0 1 0-2.4 0 3.6 3.6 0 0 1-3.6 3.6 1.2 1.2 0 1 0 0 2.4 3.6 3.6 0 0 1 3.6 3.6 1.2 1.2 0 1 0 2.4 0 3.6 3.6 0 0 1 3.6-3.6 1.2 1.2 0 1 0 0-2.4z"
                  fill="#F5CCD7"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

CheckoutCompleteIcon4.defaultProps = {
  width: 110,
  height: 100,
  fill: 'currentColor',
};

CheckoutCompleteIcon4.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default CheckoutCompleteIcon4;
