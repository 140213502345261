/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.HomePage';

export default defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  productCategories: {
    id: `${scope}.productCategories`,
    defaultMessage: 'Product Categories',
  },
  allYouNeedIsHere: {
    id: `${scope}.allYouNeedIsHere`,
    defaultMessage: 'All you need is here',
  },
  news: {
    id: `${scope}.news`,
    defaultMessage: 'Featured Articles',
  },
  viewPromotions: {
    id: `${scope}.viewPromotions`,
    defaultMessage: 'View Our Promotions',
  },
  flashSale: {
    id: `${scope}.flashSale`,
    defaultMessage: 'Flash Sales',
  },
  noFlashSale: {
    id: `${scope}.noFlashSale`,
    defaultMessage: 'No Flash Sales',
  },
  more: {
    id: `${scope}.more`,
    defaultMessage: 'View More',
  },
  viewBundles: {
    id: `${scope}.viewBundles`,
    defaultMessage: 'View Our Bundles',
  },
  popularProducts: {
    id: `${scope}.popularProducts`,
    defaultMessage: 'Popular Products',
  },
  transformationalProducts: {
    id: `${scope}.transformationalProducts`,
    defaultMessage: 'Transformational Products',
  },
  ourBundles: {
    id: `${scope}.ourBundles`,
    defaultMessage: 'Our Bundles',
  },
  viewNow: {
    id: `${scope}.viewNow`,
    defaultMessage: 'View Now',
  },
  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'View All Products',
  },
  bestCombination: {
    id: `${scope}.bestCombination`,
    defaultMessage: 'Best Combination',
  },
  fromGermany: {
    id: `${scope}.fromGermany`,
    defaultMessage: 'From germany',
  },
  officialProduct: {
    id: `${scope}.officialProduct`,
    defaultMessage: 'official product',
  },
  uninterruptedCustomerService: {
    id: `${scope}.uninterruptedCustomerService`,
    defaultMessage: 'Uninterrupted customer service',
  },
  fitLineExperience: {
    id: `${scope}.fitLineExperience`,
    defaultMessage: 'FitLine Experience',
  },
  allProductCatalogs: {
    id: `${scope}.allProductCatalogs`,
    defaultMessage: 'All product catalogs',
  },
  soldOut: {
    id: `${scope}.soldOut`,
    defaultMessage: 'Sold Out',
  },
  bindPhoneGoBack: {
    id: `${scope}.bindPhoneGoBack`,
    defaultMessage: 'Do not register',
  },
  finishBinding: {
    id: `${scope}.finishBinding`,
    defaultMessage: 'FINISH',
  },
  failedBindingText: {
    id: `${scope}.failedBindingText`,
    defaultMessage:
      'Failed to bind mobile phone. Please check the information or contact the administrator',
  },
});
