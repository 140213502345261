/* eslint-disable react/no-unstable-nested-components */
/**
 * MiniCart
 */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import routes from 'utils/routes';
import get from 'lodash/get';
import messages from 'containers/CartProvider/messages';
import { updateEcommerceCart, handleClearEcommerceCart } from 'utils/ga';
import isEmpty from 'lodash/isEmpty';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ShoppingCartIcon, AngleLeftIcon, LogoIcon } from 'components/Icons';
import { compose } from 'redux';
import FormatMessage from 'components/FormatMessage';
import ConfirmDiaLog from 'components/ConfirmDiaLog';
import Hidden from '@material-ui/core/Hidden';
import WholePageDialog from 'components/Dialogs/WholePageDialog';
import PageContainer from 'components/NavigationBar/PageContainer';
import authMessages from 'containers/AuthorizationProvider/messages';
import Carts from 'containers/CartProvider/components/Carts';
import { useIntl } from 'react-intl';
import { toggleStoreShow } from 'containers/StoreProvider/slices';
import { toggleMiniCart } from 'containers/App/slices';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import clsx from 'clsx';
import useDeepCompareEffect from 'utils/Hooks/updateEffect/useDeepCompareEffect';
import useUpdateEffect from 'utils/Hooks/updateEffect/useUpdateEffect';
import { timeSleep } from 'utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: ({ placedOnTop }) =>
      placedOnTop ? theme.zIndex.drawer : `${theme.zIndex.appBar - 1} !important`,
  },
  drawer: {
    height: 'auto',
    overflow: 'auto',
    borderRadius: '12px 12px 0 0',
  },
  anchorBottom: {
    top: 30,
    paddingBottom: ({ isBottom }) =>
      isBottom ? theme.mixins.toolbar.compatibleHeight() : theme.mixins.toolbar.safeAreaInsetBottom,
  },
  miniCartButton: {
    minWidth: 50,
    padding: theme.spacing(1.25, 1),
    fontSize: 24,
    '& .MuiBottomNavigationAction-label': {
      marginTop: -4,
    },
    '&.Mui-selected': {
      paddingTop: theme.spacing(1.25),
      '&:before': {
        top: -2,
        left: '50%',
        width: 36,
        height: 2,
        content: '',
        display: 'table',
        position: 'absolute',
        marginLeft: theme.spacing(-2.25),
        backgroundColor: theme.palette.background.primary,
      },
    },
  },
  pageRoot: {
    padding: 0,
  },
}));

export function MiniCart({ hideLabel, isCheckout, match, closeMiniCart, isBottom = true, active }) {
  const placedOnTop = match?.path === routes.product();
  const classes = useStyles({ placedOnTop, isBottom });
  const {
    palette: { primary },
  } = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { cartItems, showMiniCart } = useShoppingCart();

  const [buyOthers, setBuyOthers] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useUpdateEffect(() => {
    if (!showMiniCart && closeMiniCart) closeMiniCart();
  }, [showMiniCart]);

  useDeepCompareEffect(() => {
    let total = 0;
    if (!isEmpty(cartItems)) {
      // eslint-disable-next-line array-callback-return
      cartItems.map(item => {
        const price = get(item.extension_attributes, 'calculated_price', 0);
        total += item.qty * price;
      });
    }
    if (total === 0) {
      handleClearEcommerceCart();
    } else if (!isEmpty(cartItems)) {
      timeSleep(() => updateEcommerceCart(cartItems, total), 1000);
    }
  }, [cartItems]);

  const iconColor = useMemo(() => {
    if (showMiniCart) return primary.main;
    return isCheckout ? 'text.primary' : 'text.secondary';
  }, [isCheckout, showMiniCart]);

  const toggleDrawer = () => {
    dispatch(toggleMiniCart(!showMiniCart));
  };

  const handleDrawerClose = () => {
    dispatch(toggleMiniCart(false));
  };

  const handleCheckStore = () => {
    dispatch(toggleStoreShow(true));
  };

  // eslint-disable-next-line consistent-return
  // const handleToBuyOthers = () => {
  //   if (selectedItems.length) {
  //     toggleOthersBuyItems(selectedOthersBuyItems(cartItems, selectedItems));
  //     return setBuyOthers(true);
  //   }
  //   setShowConfirm(true);
  // };

  return (
    <>
      <Hidden lgUp>
        <BottomNavigationAction
          onClick={toggleDrawer}
          className={clsx(classes.miniCartButton, {
            'Mui-selected': active,
          })}
          showLabel
          label={
            !hideLabel && (
              <FormatMessage
                message={messages.cart}
                color={showMiniCart ? 'primary' : 'textSecondary'}
                variant="h4"
                component="span"
                style={{ fontWeight: `${showMiniCart ? 550 : 300}` }}
              />
            )
          }
          icon={
            <Badge style={{ marginTop: 2 }} badgeContent={cartItems.length} color="primary">
              <Box color={iconColor}>
                <SvgIcon component={ShoppingCartIcon} color="inherit" fontSize="large" />
              </Box>
            </Badge>
          }
        />
        <Drawer
          anchor="bottom"
          open={showMiniCart}
          transitionDuration={{ enter: 250, exit: 100 }}
          onClose={handleDrawerClose}
          classes={{
            root: classes.root,
            paper: classes.drawer,
            paperAnchorBottom: classes.anchorBottom,
          }}
        >
          <Carts />
        </Drawer>
        <WholePageDialog
          open={buyOthers}
          headRender={() => (
            <PageContainer
              classes={{ root: classes.pageRoot }}
              title={<LogoIcon width={50} height={30} />}
              iconColor="inherit"
              buttonText={authMessages.goBack}
              onGoBack={() => setBuyOthers(false)}
            />
          )}
          icon={<SvgIcon component={AngleLeftIcon} viewBox="0 0 12 12" color="primary" />}
        />
        <ConfirmDiaLog
          open={showConfirm}
          title={intl.formatMessage(messages.insufficient)}
          confirmBtnMsg={intl.formatMessage(messages.checkStore)}
          onConfirm={handleCheckStore}
          onAssistant={() => setShowConfirm(false)}
          assistantBtnMsg={intl.formatMessage(messages.flagshipStoreCheckout)}
          renderContent={() => (
            <FormatMessage message={messages.insufficientNotice} color="textPrimary" />
          )}
        />
      </Hidden>
      <Hidden mdDown>
        <Carts />
      </Hidden>
    </>
  );
}

MiniCart.defaultProps = {
  hideLabel: false,
  isCheckout: false,
};

MiniCart.propTypes = {
  hideLabel: PropTypes.bool,
  isCheckout: PropTypes.bool,
  match: PropTypes.object,
  closeMiniCart: PropTypes.func,
  isBottom: PropTypes.bool,
  active: PropTypes.number,
};

export default compose(withRouter)(MiniCart);
