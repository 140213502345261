import axios from 'axios';
import { sprintf } from 'utils/helpers';
import { actions as notificationActions } from 'utils/Notifications/actions';
import { deleteAuthorization } from 'utils/Auth';
import { push } from 'connected-react-router/immutable';
import routes from 'utils/routes';
import { resetCart } from 'containers/CartProvider/slices';
import { resetCheckout } from 'containers/CheckoutPage/slices';
// import determineEnvironment from 'utils/WeChat/determineEnvironment';

export default ({ dispatch }, queryClient) =>
  axios.interceptors.response.use(
    response => response,
    error => {
      const {
        response: { config, status, data },
      } = error;
      if (
        status === 401 &&
        data.message === "The consumer isn't authorized to access %resources." &&
        !config.url.endsWith('/logout')
      ) {
        // Specific to the user's token being expired
        deleteAuthorization();
        dispatch(resetCart());
        dispatch(resetCheckout());
        console.log('axiosError');
        dispatch(push(routes.home));
        // if (determineEnvironment()) {
        //   dispatch(push(routes.home));
        // } else {
        //   dispatch(push(routes.logIn));
        // }
        queryClient.refetchQueries(['cart.quote']);
        queryClient.removeQueries('couponPage.checkout', { exact: true });
        queryClient.removeQueries('couponPage.myCoupons', { exact: true });
        queryClient.removeQueries('membershipPage.upgradeSMData', { exact: true });
        queryClient.removeQueries('orderListPage.orders', { exact: true });
        queryClient.removeQueries('loyalty.pointsPayout', { exact: true });
        queryClient.removeQueries('userProvider.customer', { exact: true });
        queryClient.removeQueries('sharingProvider.myShareDownline', { exact: true });
        queryClient.removeQueries('wishlistPage.allWishlist', { exact: true });
        queryClient.removeQueries('fapiaoPage.fapiaoList', { exact: true });
      }
      if (
        status >= 400 &&
        status !== 401 &&
        data.message !== "The consumer isn't authorized to access %resources." &&
        !config.url.includes('loyalty/use-points')
      ) {
        dispatch(
          notificationActions.enqueueSnackbar({
            message:
              typeof data === 'object'
                ? sprintf(data.message, data.parameters)
                : `${data.slice(0, 500)}...`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'default',
            },
          }),
        );
      }
      return Promise.reject(error);
    },
  );
