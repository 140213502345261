// /* eslint-disable*/
/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */

import { useEffect, useState } from 'react';
import { getCities, getDistricts } from 'containers/AddressBookPage/queries';
import { useGetStoreCountries } from 'containers/App/queries';

const format = (list, isLeaf = false) =>
  list.map(r => ({
    ...r,
    id: +r.id,
    label: r.name,
    value: +r.id,
    isLeaf,
  }));

const useAddress = value => {
  const [data, setData] = useState([]);

  const { data: regions, isLoading: loadingRegions } = useGetStoreCountries();

  const requestCity = async id => {
    const cities = await getCities(id);
    return format(cities);
  };

  const requestDistrict = async id => {
    const districts = await getDistricts(id);
    return format(districts, true);
  };

  const loadData = async selectedOptions => {
    if (selectedOptions.length === 1) {
      return requestCity(selectedOptions[0].id);
    }
    if (selectedOptions.length === 2) {
      return requestDistrict(selectedOptions[1].id);
    }
    return [];
  };

  useEffect(() => {
    if (!loadingRegions && regions) {
      const nextData = format(regions);
      setData(nextData);
    }
  }, [regions]);

  useEffect(() => {
    const loadSelectedData = async () => {
      if (regions && value && Array.isArray(value) && value.length) {
        const currentRegions = data.length ? data : format(regions);

        const selectedRegion = currentRegions.find(r => r.id === value[0]);
        if (!selectedRegion?.children) {
          selectedRegion.children = await requestCity(value[0]);
        }

        const selectedCity = selectedRegion.children.find(c => c.id === value[1]);
        if (!selectedCity?.children) {
          selectedCity.children = await requestDistrict(value[1]);
        }
        setData([...currentRegions]);
      }
    };

    loadSelectedData();
  }, [regions, value]);

  return { data, loadData };
};

export default useAddress;
