import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import useFindStore from 'containers/StoreProvider/hooks/useFindStore';
import useMap from 'utils/Hooks/useMap';
import StoreFilter from './StoreFilter';
import StoreMap from './StoreMap';
import StoreDetail from './StoreDetail';
import StoreList from './StoreList';
// import Popover from '@material-ui/core/Popover';
// import Grow from '@material-ui/core/Grow';
// import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(theme => ({
  storeContainer: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: 999,
    left: 0,
    top: 0,
  },
  sidebarContainer: {
    maxWidth: 1408,
    margin: '0 auto',
  },
  sidebar: {
    position: 'relative',
    overflow: 'hidden',
    width: 420,
    zIndex: 9999,
    top: 104,
    bottom: 16,
    boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)',
    borderRadius: 8,
    background: theme.palette.background.white,
  },
  content: {
    overflowY: 'auto',
    height: 'calc(100vh - 330px)',
  },
  locationButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
  closeButton: {
    marginLeft: theme.spacing(-1.5),
  },
}));

const FindStore = ({ open, onClose }) => {
  const classes = useStyles();

  const { zoomTo, panTo, openWindowInfo } = useMap();
  const { storeList, cityOptions, filterValues, filteredStoreList, onSearch, onReset } =
    useFindStore();

  const [openStoreDetail, setOpenStoreDetail] = useState(false);
  const [activeStore, setActiveStore] = useState({});

  const toggleWindowInfo = (visible, item) => {
    if (visible) {
      const position = new TMap.LatLng(item.latitude, item.longitude);
      openWindowInfo(item.source_code, position);
    }
  };

  const moveToLocation = item => {
    const center = new TMap.LatLng(item.latitude, item.longitude);
    panTo({ center, zoom: 12 });
  };

  const handleOpenDetail = useCallback(
    (item, mapStatus = { zoom: 5 }) => {
      setOpenStoreDetail(true);
      setActiveStore(item);
      if (TMap) {
        const center = new TMap.LatLng(item.latitude, item.longitude);
        panTo({ center, ...mapStatus }, { duration: 500 }); // 平滑缩放,旋转到指定级别
        toggleWindowInfo(true, item);
      }
    },
    [panTo],
  );

  const handleCloseDetail = () => {
    setOpenStoreDetail(false);
    zoomTo(); // 平滑缩放,旋转到指定级别
  };

  return (
    <Slide in={open} direction="up" mountOnEnter unmountOnExit>
      <Box className={classes.storeContainer}>
        <StoreMap list={storeList} onView={handleOpenDetail} />
        <Box className={classes.sidebarContainer}>
          <Box className={classes.sidebar}>
            <Fade in={!openStoreDetail} mountOnEnter unmountOnExit>
              <Box>
                <StoreFilter
                  filterValues={filterValues}
                  list={filteredStoreList}
                  cityList={cityOptions}
                  onBack={onClose}
                  onSearch={onSearch}
                  onReset={onReset}
                />
                <Box className={classes.content}>
                  <StoreList
                    list={filteredStoreList}
                    onSelect={handleOpenDetail}
                    onReset={onReset}
                  />
                </Box>
              </Box>
            </Fade>
            <Slide in={openStoreDetail} direction="left" mountOnEnter unmountOnExit>
              <Box>
                <StoreDetail
                  item={activeStore}
                  onBack={handleCloseDetail}
                  onMoveToLocation={moveToLocation}
                />
              </Box>
            </Slide>
          </Box>
        </Box>
      </Box>
    </Slide>
  );
};

FindStore.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FindStore;
