import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  const {
    palette: { primary },
    breakpoints,
  } = theme;

  return {
    container: {
      margin: '0 1rem',
      padding: '16px 0',
      display: 'flex',
      alignItems: 'flex-start',
      [breakpoints.up('lg')]: {
        margin: theme.spacing(0, 3),
        padding: theme.spacing(3, 0),
      },
    },
    pcContainer: {
      alignItems: 'center',
    },
    cartItemName: {
      marginTop: '-4px',
      lineHeight: 1.5,
      [breakpoints.up('lg')]: {
        marginTop: '-4px',
        fontSize: 16,
        fontWeight: 700,
      },
    },
    comImg: {
      overflow: 'hidden',
      margin: '0 8px 0 0',
      border: `1px solid ${primary.darkPeach}`,
      borderRadius: 4,
    },
    PCDetailsComImg: {
      margin: '0 8px',
    },
    buttonStyle: {
      padding: '0 1.43rem',
      height: 32,
      borderRadius: '16px',
      border: `1px solid ${primary.darkPeach}`,
    },
    pcNotice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '9px',
      '& .notice-content': {
        width: '65%',
      },
    },
    notice: {
      '& .notice-content': {
        width: '100%',
      },
    },
    checkRoot: {
      marginLeft: -8,
      padding: 8,
    },
    dialogTitle: {
      fontSize: '2rem',
      fontWeight: 700,
      [breakpoints.up('lg')]: {
        fontSize: 24,
      },
    },
    smallSize: {
      marginLeft: -4,
      transform: 'scale(0.8)',
      [breakpoints.up('lg')]: {
        marginLeft: 0,
        transform: 'scale(1)',
      },
    },
    PCButtons: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: 184,
    },
    checkStore: {
      padding: 0,
      minHeight: 'auto',
    },
  };
});

export default useStyles;
