import PropTypes from 'prop-types';
import wxQrCode from 'images/home/FEIDEKAI.jpg';
import Box from '@material-ui/core/Box';
import FormatMessage from 'components/FormatMessage';
import appMessages from 'containers/App/messages';
import PopoverElement from 'components/Parallax/PopoverElement';
import { useGetQrCode } from 'containers/SharingProvider/queries';

const MpQrDropdown = props => {
  const { data: imgSrc } = useGetQrCode(1);
  return (
    <PopoverElement {...props}>
      <Box p={2} textAlign="center">
        <Box width={120} height={120} mb={2}>
          <img src={imgSrc || wxQrCode} alt="微信小程序" width="120" height="120" />
        </Box>
        <FormatMessage message={appMessages.wechatMini} variant="h5" />
      </Box>
    </PopoverElement>
  );
};

MpQrDropdown.defaultProps = {
  onClose: () => {},
};

MpQrDropdown.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
};

export default MpQrDropdown;
