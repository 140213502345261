/**
 * @author zhengji.su
 * @description SearchInput
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { AngleLeftIcon16, SearchIcon, TimesCircleIcon } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Form, { FormText, useForm } from 'components/FormFields';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    '& .input': {
      flex: 1,
    },
    '& input[type="search"]::-webkit-search-cancel-button': {
      display: 'none',
    },
  },
  back: {
    paddingRight: theme.spacing(1.5),
    '& .MuiButton-textSizeLarge': {
      padding: 0,
    },
  },
}));

const SearchInput = props => {
  const {
    onClear,
    onChange,
    onKeyDown,
    onGoBack,
    buttonOptions,
    buttonStyles,
    style,
    defaultValue,
    ...other
  } = props;
  const classes = useStyles(props);
  const { observer, watch, setValue } = useForm();

  const value = watch('search');

  useEffect(() => {
    setValue('search', defaultValue);
  }, [defaultValue]);

  const handleChange = e => {
    onChange?.(e);
  };

  const handleKeyDown = event => onKeyDown?.(event, value);

  const handleClear = event => {
    setValue('search', '');
    onClear?.(event);
  };

  useEffect(() => {
    handleChange({ target: { value } });
  }, [value]);

  return (
    <Form observer={observer} style={{ width: '100%' }} onFinish={() => {}}>
      <Box
        style={style}
        className={clsx(classes.root, 'search-input')}
        onKeyDown={event => handleKeyDown(event)}
      >
        {onGoBack && (
          <Box display="flex" alignItems="center" className={clsx(classes.back, 'back-button')}>
            <Buttons
              style={{ minWidth: 16, ...buttonStyles }}
              onClick={onGoBack}
              messageId={
                <SvgIcon color="primary" component={AngleLeftIcon16} viewBox="4 0 16 16" />
              }
              {...buttonOptions}
            />
          </Box>
        )}

        <Box flex={1}>
          <FormText
            id="search"
            name="search"
            InputProps={{
              startAdornment: (
                <SvgIcon component={SearchIcon} color="primary" viewBox="0 0 16 16" />
              ),
              endAdornment: (
                <SvgIcon
                  component={TimesCircleIcon}
                  style={{ cursor: 'pointer' }}
                  color="primary"
                  viewBox="0 0 16 16"
                  onClick={handleClear}
                />
              ),
            }}
            className="input"
            variant="outlined"
            type="search"
            {...other}
          />
        </Box>
      </Box>
    </Form>
  );
};

SearchInput.defaultProps = {
  buttonOptions: {},
  buttonStyles: {},
};

SearchInput.propTypes = {
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onGoBack: PropTypes.func,
  buttonOptions: PropTypes.object,
  buttonStyles: PropTypes.object,
  style: PropTypes.object,
  defaultValue: PropTypes.string,
};

export default SearchInput;
