/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
import CenteredDialog from 'components/Dialogs/CenteredDialog';
import Buttons from 'components/Buttons/Buttons';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import messages from 'containers/FapiaoPage/messages';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import appMessages from 'containers/App/messages';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    width: 343,
    [theme.breakpoints.up('lg')]: {
      width: 343,
      margin: '0 auto',
    },
  },
  title: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
    },
  },
  paper: {
    [theme.breakpoints.up('lg')]: {
      boxShadow: 'none',
      border: `2px solid ${theme.palette.background.peach}`,
    },
  },
}));

const StockShortageDialog = ({ open, onConfirm, onCancel, cancelText, ...other }) => {
  const classes = useStyles();

  return (
    <CenteredDialog open={open} fullWidth={false} classes={{ paper: classes.paper }} {...other}>
      <Box align="center" px={2} pt={4} pb={3} className={classes.root}>
        <Box mb={3}>
          <Typography variant="h1" paragraph className={classes.title}>
            <FormattedMessage {...messages.outOfStockTitle} />
          </Typography>
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage {...messages.outOfStockDesc} />
          </Typography>
        </Box>
        <Box display="grid" gridTemplateColumns="100%" gridRowGap={8}>
          <Buttons
            variant="contained"
            color="primary"
            onClick={onConfirm}
            messageId={<FormattedMessage {...appMessages.switchStore} />}
          />
          <Buttons
            color="primary"
            size="medium"
            messageId={cancelText || <FormattedMessage {...appMessages.addToCart} />}
            onClick={onCancel}
          />
        </Box>
      </Box>
    </CenteredDialog>
  );
};

StockShortageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelText: PropTypes.node,
};

export default StockShortageDialog;
