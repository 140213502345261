/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import useAddress from 'utils/Hooks/useAddress';
import Cascader from 'components/Cascader/Cascader';
import SvgIcon from '@material-ui/core/SvgIcon';
import { TriangleIcon } from 'components/Icons';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import validationMessages from 'utils/Validation/messages';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-end',
    textAlign: 'left',
  },
  paper: {
    width: '100%',
    margin: 0,
    borderRadius: 0,
  },
  field: {
    maxHeight: '64px',
    height: 56,
    padding: '21px 14px',
    backgroundColor: theme.palette.background.peach,
    border: `1px solid ${theme.palette.background.peach}`,
    borderRadius: 8,
    textTransform: 'initial',
    overflow: 'hidden',
    '&:hover': {
      borderColor: theme.palette.background.darkPeach,
      backgroundColor: theme.palette.background.peach,
    },
  },
  fieldError: {
    borderColor: theme.palette.warning.main,
    '&:hover': {
      borderColor: theme.palette.warning.main,
    },
  },
  fieldIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  fieldLabel: {
    flexGrow: 1,
    textAlign: 'left',
    maxWidth: '80%',
    overflow: 'hidden',
    fontSize: '0.875rem',
  },
  buttonLabel: {
    justifyContent: 'space-between',
    fontWeight: 400,
  },
}));

const LocationCascader = ({ value: propsValue, onChange, error }) => {
  const classes = useStyles();
  const [value, setValue] = useState();
  const { data, loadData } = useAddress(value);
  const [options, setOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState(
    <Typography component="span" color="textSecondary" className={classes.fieldLabel}>
      <FormattedMessage {...messages.locationPlaceholder} />
    </Typography>,
  );

  const handleChange = (val, selectedOptions) => {
    setValue(val);
    if (onChange) {
      onChange(
        {
          region: { value: parseInt(val[0], 10), label: selectedOptions[0].name },
          city: { value: parseInt(val[1], 10), label: selectedOptions[1].name },
          district: { value: parseInt(val[2], 10), label: selectedOptions[2].name },
        },
        selectedOptions,
      );
    }
  };

  const handleLoadData = async selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    // load options lazily
    if (!targetOption.children) {
      const children = await loadData(selectedOptions);
      targetOption.children = children;
    }
    targetOption.loading = false;
    setOptions([...options]);
  };

  useEffect(() => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    if (propsValue && !propsValue.some(v => !v?.value || v?.value === '')) {
      const nextValue = propsValue.map(v => +v.value);
      const nextPlaceholder = propsValue.map(v => v.label).join(',');
      setValue(nextValue);
      setPlaceholder(nextPlaceholder);
      console.log('value changed');
    }
  }, [propsValue]);

  return (
    <Cascader
      value={value}
      options={options}
      loadData={handleLoadData}
      onChange={handleChange}
      placeholder="Please select"
    >
      <FormControl error={error} fullWidth>
        <Button
          endIcon={<SvgIcon component={TriangleIcon} viewBox="0 0 16 16" color="primary" />}
          classes={{
            root: clsx(classes.field, { [classes.fieldError]: error }),
            label: classes.buttonLabel,
          }}
          variant="outlined"
          color="inherit"
          fullWidth
        >
          {placeholder}
        </Button>

        {error && (
          <FormHelperText variant="filled">
            <FormattedMessage {...validationMessages.required} />
          </FormHelperText>
        )}
      </FormControl>
    </Cascader>
  );
};

LocationCascader.defaultProps = {
  value: [],
  error: false,
};

LocationCascader.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

export default LocationCascader;
