/**
 * @author zhengji.su
 * @description Carts
 */

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CartHeader from 'containers/CartProvider/components/CartHeader';
import MiniCartListItem from 'containers/CartProvider/components/MiniCartListItem';
import Checkbox from '@material-ui/core/Checkbox';
import { CartEmptyIcon, CheckboxCheckedIcon, CheckboxIcon } from 'components/Icons';
import FormatMessage from 'components/FormatMessage';
import messages from 'containers/CartProvider/messages';
import appMessages from 'containers/App/messages';
import { formatPrice } from 'utils/helpers';
import Hidden from '@material-ui/core/Hidden';
import Buttons from 'components/Buttons/Buttons';
import routes from 'utils/routes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EmptyState from 'components/EmptyState/Loadable';
import { FormattedMessage } from 'react-intl';
import Access, { useAccess } from 'components/Access';
import Skeleton from '@material-ui/lab/Skeleton';
import Total from 'containers/CartProvider/components/Total';
import StockShortageDialog from 'containers/StoreProvider/components/StockShortageDialog';
import useStore from 'containers/StoreProvider/hooks/useStore';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import useCheckout from 'utils/Hooks/useCheckout';
import { toggleMiniCart } from 'containers/App/slices';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  carts: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 0,
    },
  },
  items: {
    overflowY: 'scroll',
    flex: 1,
    // height: `calc(100% - 162px - ${theme.mixins.toolbar.safeAreaInsetBottom} - ${theme.mixins.toolbar.minHeight}px)`,
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      height: 'calc(100% - 236px)',
    },
  },
  handle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.5rem 1rem 1rem',
    height: 112,
    zIndex: 100,
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 3),
      bottom: '0px !important',
      height: 184,
      borderTop: `2px solid ${theme.palette.primary.peach}`,
      borderRadius: '0 0 8px 8px',
    },
  },
  checkRoot: {
    marginLeft: -8,
    padding: 8,
  },
  checkAllText: {
    marginLeft: 5,
    fontSize: 14,
    fontWeight: 400,
    verticalAlign: 'text-top',
  },
  total: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    flex: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      '& .MuiTypography-root': {
        fontSize: 16,
      },
    },
  },
  btnRoot: {
    '&.MuiButton-textSizeLarge': {
      padding: '0 !important',
    },
  },
  btn: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      marginTop: 0,
      textAlign: 'center',
    },
  },
  submit: {
    marginLeft: theme.spacing(2),
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      '& .MuiTypography-root': {
        fontSize: 14,
      },
    },
  },
  editBtn: {
    padding: theme.spacing(3, 0),
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  emptyTitle: {
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
      lineHeight: '34px',
    },
  },
}));

const Carts = () => {
  const classes = useStyles();
  const matchLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const { toggleStoreVisible, selectFlagshipStore } = useStore();
  const { authorization, loading: accessLoading } = useAccess();
  const dispatch = useDispatch();
  const { checkout: proceedToCheckout, isLoading: checkoutLoading } = useCheckout();
  const history = useHistory();
  const {
    cartItems,
    subTotal,
    pvTotal,
    isAllChecked,
    selectedItems,
    lockCheckout,
    toggleAllItems,
    isLoading,
  } = useShoppingCart();

  const [loading, setLoading] = useState(false);
  const [openStockDialog, setOpenStockDialog] = useState(false);

  const handleDrawerClose = () => {
    dispatch(toggleMiniCart(false));
  };

  const handleSelectAll = () => toggleAllItems(!isAllChecked);

  const handleShowCheckout = () => proceedToCheckout();

  const handleRedirect = () => {
    history.push(routes.catalogEmpty);
    dispatch(toggleMiniCart(false));
  };

  const handleSwitchStore = async () => {
    setOpenStockDialog(false);
    const skus = cartItems
      .filter(item => selectedItems.includes(item.item_id))
      .map(item => ({ sku: item.sku, qty: item.qty }));
    await toggleStoreVisible(true, { skus });
  };

  const switchToFlagshipStore = () => {
    setOpenStockDialog(false);
    selectFlagshipStore();
  };

  const handleCheckStock = async () => {
    const outOfStockItems = cartItems.filter(
      item =>
        selectedItems.includes(item.item_id) &&
        (item?.extension_attributes?.stock_status !== 'IN_STOCK' ||
          item?.extension_attributes?.qty_left <= 0 ||
          item?.extension_attributes?.qty_left < item.qty),
    );
    if (outOfStockItems.length) {
      setOpenStockDialog(true);
      return;
    }
    await handleShowCheckout();
  };

  return (
    <>
      {cartItems && cartItems.length > 0 ? (
        <Box minHeight={1} height="100%" overflow="auto" className={classes.carts}>
          <CartHeader />
          <Box className={classes.items}>
            {cartItems.map(item => (
              <MiniCartListItem
                key={item.item_id}
                cartItem={item}
                proceedingToCheckout={bool => setLoading(bool)}
                small
              />
            ))}
          </Box>
          <Box className={classes.handle} width={1} bgcolor="primary.white">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={{ lg: 2 }}
              lineHeight={1}
            >
              <Box>
                <Checkbox
                  checked={isAllChecked}
                  value="selectAll"
                  onChange={handleSelectAll}
                  icon={<CheckboxIcon />}
                  classes={{ root: classes.checkRoot }}
                  checkedIcon={<CheckboxCheckedIcon />}
                />
                <FormatMessage
                  message={{
                    ...messages.cartSelectAll,
                    values: { select: matchLg ? '全选' : '选择全部' },
                  }}
                  className={classes.checkAllText}
                  variant="subtitle2"
                  component="span"
                  color="textPrimary"
                />
              </Box>
              {accessLoading ? (
                <Skeleton height={25} width="50%" />
              ) : (
                <Box className={classes.total}>
                  {/* <Total total={formatPrice(30, '¥', 2)} title={`${intl.formatMessage(appMessages.shippingAmount)}: `} /> */}
                  <Access accessible={false && authorization([3, 4])}>
                    <Total
                      total={pvTotal}
                      title={appMessages.PVColon}
                      style={{ marginLeft: '10px' }}
                    />
                  </Access>
                  <Total
                    total={formatPrice(subTotal, '¥', 2)}
                    title={appMessages.totalColon}
                    style={{ marginLeft: '10px' }}
                    variant="h6"
                  />
                </Box>
              )}
            </Box>
            <Box className={classes.btn}>
              <Hidden lgUp>
                <Buttons
                  route={routes.catalogEmpty}
                  color="primary"
                  messageId={
                    <FormatMessage
                      message={messages.continueShopping}
                      variant="inherit"
                      component="span"
                      color="primary"
                    />
                  }
                  onClick={handleDrawerClose}
                  classes={{ root: classes.btnRoot }}
                />
                {/* <Buttons */}
                {/*  color="primary" */}
                {/*  messageId={<FormatMessage message={messages.buyOthers} variant="h3" component="span" color="primary" />} */}
                {/*  onClick={handleToBuyOthers} */}
                {/* /> */}
              </Hidden>
              <Buttons
                variant="contained"
                color="primary"
                onClick={handleCheckStock}
                loading={loading || isLoading || checkoutLoading}
                disabled={
                  selectedItems.length < 1 || loading || lockCheckout > 0 || checkoutLoading
                }
                messageId={
                  lockCheckout > 0 ? (
                    <FormatMessage
                      variant="inherit"
                      color="primary"
                      message={appMessages.processing}
                    />
                  ) : (
                    <FormatMessage
                      variant="inherit"
                      message={{ ...messages.checkOut, values: { quantity: selectedItems.length } }}
                    />
                  )
                }
                style={matchLg ? {} : { marginLeft: 16, flex: 1 }}
                className={classes.submit}
              />
              <Hidden mdDown>
                <Buttons
                  route={routes.shoppingCart}
                  color="primary"
                  messageId={
                    <FormatMessage
                      message={messages.edit}
                      variant="h3"
                      component="span"
                      color="primary"
                    />
                  }
                  onClick={handleDrawerClose}
                  className={classes.editBtn}
                />
              </Hidden>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box height="100%" mb={10.5}>
          <CartHeader />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 'calc(100% - 50px)' }}
          >
            <EmptyState
              body={
                <FormatMessage
                  className={classes.emptyTitle}
                  component="span"
                  message={appMessages.tapToStartShopping}
                  variant="h1"
                  color="textPrimary"
                />
              }
              icon={<CartEmptyIcon />}
              buttonStyles={{ height: '3rem', fontSize: '0.875rem' }}
              action={{
                label: <FormattedMessage {...appMessages.startShopping} />,
                event: handleRedirect,
              }}
              fullWidth
            />
          </Box>
        </Box>
      )}
      <StockShortageDialog
        open={openStockDialog}
        onConfirm={handleSwitchStore}
        cancelText={<FormattedMessage {...appMessages.checkoutFromFlagshipStore} />}
        onCancel={switchToFlagshipStore}
        onClose={() => setOpenStockDialog(false)}
      />
    </>
  );
};

export default Carts;
