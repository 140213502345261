/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * Asynchronously loads the component for SmLogin
 *
 */

import loadable from 'utils/loadable';

export const FindStorePage = loadable(() => import('./FindStorePage'));
export default loadable(() => import('./index'));
