import FormatMessage from 'components/FormatMessage';
import appMessages from 'containers/App/messages';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
  // AccountIcon,
  AngleRightIcon,
  DownTriangle,
  LanguageIcon,
  StoreStarIcon,
  LogoIcon,
  SearchIcon,
  // ShoppingCartIcon,
  // WechatIcon,
} from 'components/Icons';
import Typography from '@material-ui/core/Typography';
import { DEFAULT_STORE_NAME } from 'utils/constants';
import cataMessages from 'containers/CatalogPage/messages';
import routes from 'utils/routes';
import homeMessages from 'containers/HomePage/messages';
import cmsMessages from 'containers/CustomerService/messages';
import { FormattedMessage } from 'react-intl';

export const languageOptions = [
  { id: 'zh', title: '中文-简体' },
  { id: 'en', title: 'English' },
];

export const headTools = [
  // {
  //   id: 'wechat',
  //   messageId: (
  //     <FormatMessage
  //       message={appMessages.wechatMini}
  //       variant="caption"
  //       style={{ color: '#00C25F' }}
  //     />
  //   ),
  //   startIcon: (
  //     <SvgIcon
  //       component={WechatIcon}
  //       htmlColor="#00C25F"
  //       style={{ fontSize: '0.75rem' }}
  //       viewBox="0 0 16 16"
  //     />
  //   ),
  //   color: 'primary',
  //   border: true,
  // },
  {
    id: 'store',
    messageId: (
      <Typography style={{ fontWeight: 700, fontSize: 14 }}>
        <FormattedMessage {...appMessages.findStore} />
      </Typography>
    ),
    startIcon: (
      <SvgIcon
        component={StoreStarIcon}
        color="primary"
        viewBox="0 0 24 24"
        style={{ fontSize: '1rem' }}
      />
    ),
    border: true,
    route: routes.findStore,
  },
  {
    id: 'language',
    messageId: <Typography style={{ fontWeight: 700 }}>中文-简体</Typography>,
    startIcon: <SvgIcon component={LanguageIcon} htmlColor="#090909" viewBox="0 0 16 16" />,
    endIcon: (
      <SvgIcon
        component={DownTriangle}
        style={{ marginLeft: 4 }}
        color="primary"
        className="end-icon"
        viewBox="0 0 16 16"
      />
    ),
    border: true,
  },
  {
    id: 'search',
    messageId: (
      <SvgIcon
        component={SearchIcon}
        htmlColor="#090909"
        viewBox="0 0 16 16"
        style={{ fontSize: 18 }}
      />
    ),
  },
  // {
  //   id: 'cart',
  //   messageId: (
  //     <SvgIcon
  //       component={ShoppingCartIcon}
  //       htmlColor="#090909"
  //       viewBox="0 0 24 24"
  //       style={{ fontSize: 24 }}
  //     />
  //   ),
  //   style: { padding: 0 },
  // },
  // {
  //   id: 'account',
  //   messageId: (
  //     <SvgIcon
  //       component={AccountIcon}
  //       htmlColor="#090909"
  //       viewBox="0 0 24 24"
  //       style={{ fontSize: 24 }}
  //     />
  //   ),
  //   style: { padding: 0 },
  // },
];

export const headNavs = [
  {
    id: 'home',
    title: DEFAULT_STORE_NAME,
    messageId: <LogoIcon width={53} height={40} />,
    className: 'logo',
    url: routes.home,
  },
  {
    id: 'catalog',
    messageId: <FormatMessage message={cataMessages.products} variant="h4" component="a" />,
    className: 'title',
    url: routes.catalog('all'),
  },
  {
    id: 'flashsale',
    messageId: <FormatMessage message={appMessages.activity} variant="h4" />,
    className: 'title',
    url: routes.flashsale,
  },
  {
    id: 'blog',
    messageId: <FormatMessage message={homeMessages.fitLineExperience} variant="h4" />,
    url: routes.blog,
    className: 'title',
  },
  {
    id: 'about',
    messageId: <FormatMessage message={cmsMessages.aboutUs} variant="h4" />,
    className: 'title',
    url: routes.cms('about-us'),
  },
];

export const accountTagMenu = [
  { id: 1, title: appMessages.myAccount, path: routes.dashboard },
  { id: 2, title: appMessages.shippingAddress, path: routes.addressBook },
  { id: 3, title: appMessages.orderManage, path: routes.ordersAll },
  { id: 4, title: appMessages.wishlist, path: routes.wishlist },
  { id: 5, title: appMessages.logOut, icon: AngleRightIcon },
];

export default headTools;
