/* eslint-disable */
function CelendarIcon(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const strokewidth = props.strokewidth || 1;
	const width = props.width || '100%';
	const height = props.height || '100%';

	return (
		<svg width={width} height={height} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
			<title>2.Icons/16/16-celendar</title>
			<g id="---Icons-(Dont'-Check)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="2.Icons/16/16-celendar" fill="#CD0039" >
					<path d="M6.25,1 L6.25,2.75 L9.75,2.75 L9.75,1 L12.375,1 L12.375,2.75 L14.125,2.75 C14.608,2.75 15,3.142 15,3.625 L15,3.625 L15,14.125 C15,14.608 14.608,15 14.125,15 L14.125,15 L1.875,15 C1.392,15 1,14.608 1,14.125 L1,14.125 L1,3.625 C1,3.142 1.392,2.75 1.875,2.75 L1.875,2.75 L3.625,2.75 L3.625,1 L6.25,1 Z M13.25,5.375 L2.75,5.375 L2.75,13.25 L13.25,13.25 L13.25,5.375 Z M6.25,9.75 L6.25,11.5 L4.5,11.5 L4.5,9.75 L6.25,9.75 Z M8.875,9.75 L8.875,11.5 L7.125,11.5 L7.125,9.75 L8.875,9.75 Z M11.5,9.75 L11.5,11.5 L9.75,11.5 L9.75,9.75 L11.5,9.75 Z M6.25,7.125 L6.25,8.875 L4.5,8.875 L4.5,7.125 L6.25,7.125 Z M8.875,7.125 L8.875,8.875 L7.125,8.875 L7.125,7.125 L8.875,7.125 Z M11.5,7.125 L11.5,8.875 L9.75,8.875 L9.75,7.125 L11.5,7.125 Z" id="Combined-Shape"></path>
				</g>
			</g>
		</svg>
	);
}

export default CelendarIcon;
