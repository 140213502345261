/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import QUERY_ALL_PRODUCTS from 'containers/CatalogPage/gql/queryGetAllProducts';
import QUERY_ALL_CATEGORIES from 'containers/CatalogPage/gql/queryGetCategories';
import QUERY_PRODUCT_LINES from 'containers/CatalogPage/gql/queryGetProductLines';
import QUERY_SINGLE_CATEGORY from 'containers/CatalogPage/gql/queryGetSingleCategory';
import requestGql, { querySetting } from 'utils/requestGql';
import get from 'lodash/get';
import useGetSourceCode from 'utils/Hooks/useGetSourceCode';

const getAllProductsQuery = sourceCode => requestGql(QUERY_ALL_PRODUCTS(sourceCode));

const getAllCategoriesQuery = () => requestGql(QUERY_ALL_CATEGORIES);
const getProductLinsQuery = () => requestGql(QUERY_PRODUCT_LINES);
const getSingleCategoryQuery = (id, sourceCode) =>
  requestGql(QUERY_SINGLE_CATEGORY(id, sourceCode));

export const useGetAllProducts = () => {
  const sourceCode = useGetSourceCode();
  return useQuery(
    ['catalogPage.allProducts', sourceCode],
    () =>
      getAllProductsQuery(sourceCode).then(response =>
        get(response, 'category.products.items', []),
      ),
    { ...querySetting, enabled: sourceCode !== undefined },
  );
};

export const useGetAllCategories = () =>
  useQuery(
    'catalogPage.allCategories',
    () =>
      getAllCategoriesQuery().then(response =>
        get(response, 'category.children', []).filter(c => c.include_in_menu === 1),
      ),
    querySetting,
  );

export const useGetAllProductLines = () =>
  useQuery(
    'catalogPage.allProductLines',
    () =>
      getProductLinsQuery().then(response =>
        get(response, 'customAttributeMetadata.items[0].attribute_options', []),
      ),
    querySetting,
  );

export const useGetSingleCategory = id => {
  const sourceCode = useGetSourceCode();
  return useQuery(
    ['catalogPage.singleCategory', { id, sourceCode }],
    () =>
      getSingleCategoryQuery(id, sourceCode).then(response =>
        get(response, 'category.products.items', []),
      ),
    querySetting,
  );
};
