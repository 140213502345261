import { createSlice } from '@reduxjs/toolkit';

import indexOf from 'lodash/indexOf';
import union from 'lodash/union';
import without from 'lodash/without';

const toggle = (a, b, isToggle) =>
  isToggle ?? indexOf(a, b) === -1 ? union(a, [b]) : without(a, b);

const initialState = {
  selectedItems: [],
  lock: 0,
  lock1000: false,
};

export const cartState = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: () => initialState,
    toggleLockCheckout: (state, action) => {
      if (action.payload.lock) {
        state.lock += 1;
      } else {
        state.lock -= 1;
      }
    },
    toggleLock1000: (state, action) => {
      state.lock1000 = action.payload.lock1000;
    },
    toggleCartItem: (state, action) => {
      state.selectedItems = toggle(
        state.selectedItems,
        action.payload.itemId,
        action.payload.toggle,
      );
    },
    selectCartItems: (state, action) => {
      state.selectedItems = action.payload;
    },
  },
});

export const { resetCart, toggleLockCheckout, toggleCartItem, selectCartItems, toggleLock1000 } =
  cartState.actions;

export const selectCartItemsSelected = state => state.cart.selectedItems;
export const lockCheckout = state => state.cart.lock;
export const lock1000 = state => state.cart.lock1000;

export default cartState.reducer;
