/* eslint-disable */
function Icon24Navigate(props) {
  const fill = props.fill || 'currentColor';
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="2.Icons/24/24-navigate" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M12,2 C6.5,2 2,6.5 2,12 C2,17.5 6.5,22 12,22 C17.5,22 22,17.5 22,12 C22,6.5 17.5,2 12,2 Z M16.5,11.0833333 L12.6666667,12.5833333 L11.1666667,16.4166667 C11,16.8333333 10.6666667,17 10.3333333,17 C10.3333333,17 10.3333333,17 10.25,17 C9.91666667,17 9.58333333,16.75 9.5,16.4166667 L7.41666667,8.5 C7.33333333,8.25 7.41666667,7.91666667 7.66666667,7.66666667 C7.91666667,7.41666667 8.16666667,7.33333333 8.5,7.41666667 L16.4166667,9.5 C16.75,9.58333333 17,9.91666667 17,10.25 C17,10.5833333 16.8333333,11 16.5,11.0833333 Z"
          id="Shape"
          fill={fill}
          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
        />
      </g>
    </svg>
  );
}

export default Icon24Navigate;
