/*
 * Generic App Messages
 *
 * This contains all generic messages that could be used in other containers or components
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.generic';

export default defineMessages({
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Open',
  },
  copy: {
    id: `${scope}.copy`,
    defaultMessage: 'Copy',
  },
  backToHomePage: {
    id: `${scope}.backToHomePage`,
    defaultMessage: 'Go Back',
  },
  modalConfirmPay: {
    id: `${scope}.modalConfirmPay`,
    defaultMessage: 'Confirm that the information is consistent and pay',
  },
  modalNotSure: {
    id: `${scope}.modalNotSure`,
    defaultMessage: 'Not sure, check back',
  },
  confirmRemovalOfItem: {
    id: `${scope}.confirmRemovalOfItem`,
    defaultMessage: 'Confirm removal of item',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Go Back',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  moveToWishlist: {
    id: `${scope}.moveToWishlist`,
    defaultMessage: 'move to wishlist',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
  applying: {
    id: `${scope}.applying`,
    defaultMessage: 'Applying',
  },
  finish: {
    id: `${scope}.finish`,
    defaultMessage: 'Finish',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'OK',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  confirmSelection: {
    id: `${scope}.confirmSelection`,
    defaultMessage: 'Confirm',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  submitting: {
    id: `${scope}.submitting`,
    defaultMessage: 'Submitting',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  saving: {
    id: `${scope}.saving`,
    defaultMessage: 'Saving',
  },
  saveAll: {
    id: `${scope}.saveAll`,
    defaultMessage: 'Save All',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  updating: {
    id: `${scope}.updating`,
    defaultMessage: 'Updating',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  editAll: {
    id: `${scope}.editAll`,
    defaultMessage: 'Edit All',
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: 'Change',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  addSelected: {
    id: `${scope}.addSelected`,
    defaultMessage: 'Add Selected',
  },
  addItems: {
    id: `${scope}.addItems`,
    defaultMessage: 'Add Items',
  },
  adding: {
    id: `${scope}.adding`,
    defaultMessage: 'Adding',
  },
  move: {
    id: `${scope}.move`,
    defaultMessage: 'Move',
  },
  moveSelected: {
    id: `${scope}.moveSelected`,
    defaultMessage: 'Move Selected',
  },
  moveItems: {
    id: `${scope}.moveItems`,
    defaultMessage: 'Move Items',
  },
  moving: {
    id: `${scope}.moving`,
    defaultMessage: 'Moving',
  },
  movingItems: {
    id: `${scope}.movingItems`,
    defaultMessage: 'Moving Items',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  removeSelected: {
    id: `${scope}.removeSelected`,
    defaultMessage: 'Remove Selected',
  },
  removeItems: {
    id: `${scope}.removeItems`,
    defaultMessage: 'Remove Items',
  },
  removing: {
    id: `${scope}.removing`,
    defaultMessage: 'Removing',
  },
  removingItems: {
    id: `${scope}.removingItems`,
    defaultMessage: 'Removing Items',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reset',
  },
  resetSelected: {
    id: `${scope}.resetSelected`,
    defaultMessage: 'Reset Selected',
  },
  resetItems: {
    id: `${scope}.resetItems`,
    defaultMessage: 'Reset Items',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  deleteSelected: {
    id: `${scope}.deleteSelected`,
    defaultMessage: 'Delete Selected',
  },
  deleteItems: {
    id: `${scope}.deleteItems`,
    defaultMessage: 'Delete Items',
  },
  deleting: {
    id: `${scope}.deleting`,
    defaultMessage: 'Deleting',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  selectAll: {
    id: `${scope}.selectAll`,
    defaultMessage: 'Select All',
  },
  process: {
    id: `${scope}.process`,
    defaultMessage: 'Process',
  },
  processSelected: {
    id: `${scope}.processSelected`,
    defaultMessage: 'Process Selected',
  },
  processItems: {
    id: `${scope}.processItems`,
    defaultMessage: 'Process Items',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'View All',
  },
  checkDetails: {
    id: `${scope}.checkDetails`,
    defaultMessage: 'Details',
  },
  of: {
    id: `${scope}.of`,
    defaultMessage: 'of',
  },
  rowsPerPage: {
    id: `${scope}.rowsPerPage`,
    defaultMessage: 'Rows per page: ',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading',
  },
  openNavigation: {
    id: `${scope}.openNavigation`,
    defaultMessage: 'Open Navigation',
  },
  thisMonth: {
    id: `${scope}.thisMonth`,
    defaultMessage: 'This Month',
  },
  nextMonth: {
    id: `${scope}.nextMonth`,
    defaultMessage: 'Next Month',
  },
  somethingWentWrong: {
    id: `${scope}.somethingWentWrong`,
    defaultMessage: 'Something went wrong',
  },
  internalIssues: {
    id: `${scope}.internalIssues`,
    defaultMessage: 'We seem to be having some internal issues.',
  },
  ourTeamHasBeenNotified: {
    id: `${scope}.ourTeamHasBeenNotified`,
    defaultMessage:
      "Our team has been notified. If you'd like to help us you can tell us what happened below.",
  },
  reportFeedback: {
    id: `${scope}.reportFeedback`,
    defaultMessage: 'Report Report',
  },
  looksLikeWereHavingProblems: {
    id: `${scope}.looksLikeWereHavingProblems`,
    defaultMessage: "It looks like we're having problems",
  },
  NA: {
    id: `${scope}.NA`,
    defaultMessage: 'N/A',
  },
  default: {
    id: `${scope}.default`,
    defaultMessage: 'Default',
  },

  // Address related messages
  addAddress: {
    id: `${scope}.addAddress`,
    defaultMessage: 'Add Address',
  },
  editAddress: {
    id: `${scope}.editAddress`,
    defaultMessage: 'Edit Address',
  },
  deleteAddress: {
    id: `${scope}.deleteAddress`,
    defaultMessage: 'Delete Address',
  },
  selectAddress: {
    id: `${scope}.selectAddress`,
    defaultMessage: 'Select Address',
  },
  myAddresses: {
    id: `${scope}.myAddresses`,
    defaultMessage: 'My Addresses',
  },
  chineseIDNumber: {
    id: `${scope}.chineseIDNumber`,
    defaultMessage: 'Chinese ID Number',
  },
  IDNumber: {
    id: `${scope}.IDNumber`,
    defaultMessage: 'ID Number',
  },
  firstname: {
    id: `${scope}.firstname`,
    defaultMessage: 'First Name',
  },
  lastname: {
    id: `${scope}.lastname`,
    defaultMessage: 'Last Name',
  },
  cardholderName: {
    id: `${scope}.cardholderName`,
    defaultMessage: 'Cardholder Name',
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email Address',
  },
  dob: {
    id: `${scope}.dob`,
    defaultMessage: 'Date of Birth',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  countryCode: {
    id: `${scope}.countryCode`,
    defaultMessage: 'Country Code',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  address1Default: {
    id: `${scope}.address1Default`,
    defaultMessage: 'Address',
  },
  address2Default: {
    id: `${scope}.address2Default`,
    defaultMessage: 'Address Additional Line',
  },
  uploadPhoto: {
    id: `${scope}.uploadPhoto`,
    defaultMessage: '* Upload Photo',
  },
  uploadTakePhoto: {
    id: `${scope}.uploadTakePhoto`,
    defaultMessage: 'Upload / Take Photo',
  },
  uploadTakePhotoOrVideo: {
    id: `${scope}.uploadTakePhotoOrVideo`,
    defaultMessage: 'Upload / Take Photo / Video',
  },
  reUploadTakePhotoOrVideo: {
    id: `${scope}.reUploadTakePhotoOrVideo`,
    defaultMessage: 'Re-upload / Take Photo / Video',
  },
  attachmentRestrictions: {
    id: `${scope}.attachmentRestrictions`,
    defaultMessage:
      'The maximum allowed file size is {size} per attachment. The accepted file formats are {formats}.',
  },
  imageLimitRestriction: {
    id: `${scope}.imageLimitRestriction`,
    defaultMessage: 'You can only add up to {limit} images',
  },
  invalidFileFormat: {
    id: `${scope}.invalidFileFormat`,
    defaultMessage: 'File format is not allowed, please check your selection.',
  },
  imageSizeRestriction: {
    id: `${scope}.imageSizeRestriction`,
    defaultMessage: 'Selected image(s) are too big, please use smaller images.',
  },
  regionDefault: {
    id: `${scope}.regionDefault`,
    defaultMessage: 'State / Province / Region',
  },
  cityDefault: {
    id: `${scope}.cityDefault`,
    defaultMessage: 'City / Town',
  },
  districtDefault: {
    id: `${scope}.districtDefault`,
    defaultMessage: 'District / Neighbourhood',
  },
  postalDefault: {
    id: `${scope}.postalDefault`,
    defaultMessage: 'ZIP Code / Postal Code',
  },

  // Custom form labels
  colony: {
    id: `${scope}.colony`,
    defaultMessage: 'Colony',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  province: {
    id: `${scope}.province`,
    defaultMessage: 'Province',
  },
  region: {
    id: `${scope}.region`,
    defaultMessage: 'Region',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  town: {
    id: `${scope}.town`,
    defaultMessage: 'Town',
  },
  district: {
    id: `${scope}.district`,
    defaultMessage: 'District',
  },
  neighbourhood: {
    id: `${scope}.neighbourhood`,
    defaultMessage: 'Neighbourhood',
  },
  postalCode: {
    id: `${scope}.postalCode`,
    defaultMessage: 'Postal Code',
  },
  zipCode: {
    id: `${scope}.zipCode`,
    defaultMessage: 'ZIP Code',
  },

  // Order related messages
  orderSummary: {
    id: `${scope}.orderSummary`,
    defaultMessage: 'Order Summary',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  itemPrice: {
    id: `${scope}.itemPrice`,
    defaultMessage: 'Item Price',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
  pv: {
    id: `${scope}.pv`,
    defaultMessage: 'PV',
  },
  pvc: {
    id: `${scope}.pvc`,
    defaultMessage: '{total}PV',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Total',
  },
  totalColon: {
    id: `${scope}.totalColon`,
    defaultMessage: 'Total:',
  },
  PVColon: {
    id: `${scope}.PVColon`,
    defaultMessage: 'PV total:',
  },
  subtotalInclTax: {
    id: `${scope}.subtotalInclTax`,
    defaultMessage: 'Subtotal(including tax)',
  },
  taxA: {
    id: `${scope}.taxA`,
    defaultMessage: 'Tax A',
  },
  taxB: {
    id: `${scope}.taxB`,
    defaultMessage: 'Tax B',
  },
  taxC: {
    id: `${scope}.taxC`,
    defaultMessage: 'Tax C',
  },
  discountAmount: {
    id: `${scope}.discountAmount`,
    defaultMessage: 'Discount Amount',
  },
  grandTotal: {
    id: `${scope}.grandTotal`,
    defaultMessage: 'Grand Total',
  },
  shippingAmount: {
    id: `${scope}.shippingAmount`,
    defaultMessage: 'Shipping Fee',
  },
  remainingTime: {
    id: `${scope}.remainingTime`,
    defaultMessage: 'Remaining Time',
  },
  payNow: {
    id: `${scope}.payNow`,
    defaultMessage: 'Pay Now',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Add to Cart',
  },
  buyNow: {
    id: `${scope}.buyNow`,
    defaultMessage: 'Buy Now',
  },
  orderNumberColon: {
    id: `${scope}.orderNumberColon`,
    defaultMessage: 'Order Number: {orderId}',
  },
  selectOptions: {
    id: `${scope}.selectOptions`,
    defaultMessage: 'Select Options',
  },

  // Empty pages related messages
  startShopping: {
    id: `${scope}.startShopping`,
    defaultMessage: 'Start Shopping',
  },
  noArticles: {
    id: `${scope}.noArticles`,
    defaultMessage: 'No articles',
  },
  hotArticle: {
    id: `${scope}.hotArticle`,
    defaultMessage: 'Hot',
  },
  tapToStartShopping: {
    id: `${scope}.tapToStartShopping`,
    defaultMessage: 'Tap the button to start shopping',
  },
  wrongPhoneNumber: {
    id: `${scope}.wrongPhoneNumber`,
    defaultMessage: 'Wrong Phone Number',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Experience',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  cart: {
    id: `${scope}.cart`,
    defaultMessage: 'Cart',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account',
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'Expand',
  },
  collapse: {
    id: `${scope}.collapse`,
    defaultMessage: 'Collapse',
  },
  wechatPay: {
    id: `${scope}.wechatPay`,
    defaultMessage: 'Wechat Pay',
  },
  aliPay: {
    id: `${scope}.aliPay`,
    defaultMessage: 'AliPay',
  },
  applePay: {
    id: `${scope}.applePay`,
    defaultMessage: 'ApplePay',
  },
  optional: {
    id: `${scope}.optional`,
    defaultMessage: 'Optional',
  },
  orderNumber: {
    id: `${scope}.orderNumber`,
    defaultMessage: 'Order Number',
  },
  orderTime: {
    id: `${scope}.orderTime`,
    defaultMessage: 'Order Time',
  },
  fapiaoType: {
    id: `${scope}.fapiaoType`,
    defaultMessage: 'Invoice Type',
  },
  showMore: {
    id: `${scope}.showMore`,
    defaultMessage: 'Show More',
  },
  viewMore: {
    id: `${scope}.viewMore`,
    defaultMessage: 'View More',
  },
  fitLine: {
    id: `${scope}.fitLine`,
    defaultMessage: 'FitLine',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'New',
  },
  left: {
    id: `${scope}.left`,
    defaultMessage: 'Left',
  },
  noData: {
    id: `${scope}.noData`,
    defaultMessage: 'No Data',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  recipient: {
    id: `${scope}.recipient`,
    defaultMessage: 'Recipient',
  },
  tag: {
    id: `${scope}.tag`,
    defaultMessage: 'Tag',
  },
  orderManage: {
    id: `${scope}.orderManage`,
    defaultMessage: 'My Order',
  },
  shippingAddress: {
    id: `${scope}.shippingAddress`,
    defaultMessage: 'Shipping Address',
  },
  wishlist: {
    id: `${scope}.wishlist`,
    defaultMessage: 'Wishlist',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Log Out',
  },
  myAccount: {
    id: `${scope}.myAccount`,
    defaultMessage: 'My Account',
  },
  timeLeft: {
    id: `${scope}.timeLeft`,
    defaultMessage: 'Time Left ',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start',
  },
  days: {
    id: `${scope}.days`,
    defaultMessage: 'days',
  },
  hours: {
    id: `${scope}.hours`,
    defaultMessage: 'hours',
  },
  minutes: {
    id: `${scope}.minutes`,
    defaultMessage: 'minutes',
  },
  seconds: {
    id: `${scope}.seconds`,
    defaultMessage: 'seconds',
  },
  upgradeToSM: {
    id: `${scope}.upgradeToSM`,
    defaultMessage: 'Upgrade to SM',
  },
  readAndAgree: {
    id: `${scope}.readAndAgree`,
    defaultMessage: 'Have read and agree to our',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms and laws',
  },
  applyType: {
    id: `${scope}.applyType`,
    defaultMessage: 'Apply Type',
  },
  others: {
    id: `${scope}.others`,
    defaultMessage: 'Others',
  },
  pleaseChangeInfobeforeSubmit: {
    id: `${scope}.pleaseChangeInfobeforeSubmit`,
    defaultMessage: 'Please Change the Information before Submitting',
  },
  statusWaitting: {
    id: `${scope}.statusWaitting`,
    defaultMessage: 'Waitting',
  },
  welcomeToSMApplication: {
    id: `${scope}.welcomeToSMApplication`,
    defaultMessage: 'Welcome to SM application',
  },
  licenseTips: {
    id: `${scope}.licenseTips`,
    defaultMessage:
      '*If you do not have a business license, you can contact Lihua to help you proceed.',
  },
  pleaseEnterEmailAddress: {
    id: `${scope}.pleaseEnterEmailAddress`,
    defaultMessage: 'Please enter your email address first',
  },
  updateEmailAddress: {
    id: `${scope}.updateEmailAddress`,
    defaultMessage: 'Update email address',
  },
  adjustEmailUsedForApplication: {
    id: `${scope}.adjustEmailUsedForApplication`,
    defaultMessage: 'Adjust the email address used for your SM application',
  },
  requiredDocuments: {
    id: `${scope}.requiredDocuments`,
    defaultMessage: 'Required Documents',
  },
  youMustSubmitAllInformation: {
    id: `${scope}.youMustSubmitAllInformation`,
    defaultMessage: 'You must submit all information to apply for SM',
  },
  pleaseInputInfoWithCare: {
    id: `${scope}.pleaseInputInfoWithCare`,
    defaultMessage: 'Please input all information with care',
  },
  optionalParentheses: {
    id: `${scope}.optionalParentheses`,
    defaultMessage: '(optional)',
  },
  chineseIDInformation: {
    id: `${scope}.chineseIDInformation`,
    defaultMessage: 'Chinese ID Information',
  },
  frontOfID: {
    id: `${scope}.frontOfID`,
    defaultMessage: 'Front of ID Card',
  },
  backOfID: {
    id: `${scope}.backOfID`,
    defaultMessage: 'Back of ID Card',
  },
  contract: {
    id: `${scope}.contract`,
    defaultMessage: 'Contract',
  },
  pleaseUploadOrTakePhoto: {
    id: `${scope}.pleaseUploadOrTakePhoto`,
    defaultMessage: 'Please Upload or Take a Photo',
  },
  pleaseAddBothFrontAndBack: {
    id: `${scope}.pleaseAddBothFrontAndBack`,
    defaultMessage: 'Please add both front and back pictures',
  },
  chinaLLCInformation: {
    id: `${scope}.chinaLLCInformation`,
    defaultMessage: 'China LLCForm Information',
  },
  fullLegalName: {
    id: `${scope}.fullLegalName`,
    defaultMessage: 'Full legal name',
  },
  idNumber: {
    id: `${scope}.idNumber`,
    defaultMessage: '18-digit ID number',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Company name',
  },
  businessLicense: {
    id: `${scope}.businessLicense`,
    defaultMessage: 'Business license',
  },
  businessLicenseNumber: {
    id: `${scope}.businessLicenseNumber`,
    defaultMessage: 'Business license number',
  },
  relatedCertificate: {
    id: `${scope}.relatedCertificate`,
    defaultMessage: 'Related Certificate',
  },
  certificateType: {
    id: `${scope}.certificateType`,
    defaultMessage: 'Certificate type',
  },
  certificate: {
    id: `${scope}.certificate`,
    defaultMessage: 'Certificate',
  },
  bankDepositInformation: {
    id: `${scope}.bankDepositInformation`,
    defaultMessage: 'Bank Deposit Information',
  },
  bankName: {
    id: `${scope}.bankName`,
    defaultMessage: 'Bank Name',
  },
  bankBranchName: {
    id: `${scope}.bankBranchName`,
    defaultMessage: 'Bank Branch Name',
  },
  bankNamePlaceholder: {
    id: `${scope}.bankNamePlaceholder`,
    defaultMessage: 'Select bank name',
  },
  bankBranchNamePlaceholder: {
    id: `${scope}.bankBranchNamePlaceholder`,
    defaultMessage: 'Enter province and city',
  },
  bankBranchNameNote: {
    id: `${scope}.bankBranchNameNote`,
    defaultMessage:
      'Please provide province and city information and we can more easily help you find your branch name.',
  },
  receivingAccountName: {
    id: `${scope}.receivingAccountName`,
    defaultMessage: 'Receiving Account Name',
  },
  receivingAccountNumber: {
    id: `${scope}.receivingAccountNumber`,
    defaultMessage: 'Receiving Account Number',
  },
  previousUploads: {
    id: `${scope}.previousUploads`,
    defaultMessage: 'Previous Upload(s)',
  },
  submissionIsUnderReview: {
    id: `${scope}.submissionIsUnderReview`,
    defaultMessage: 'Your submission is under review, please be patient.',
  },
  statusSubmit: {
    id: `${scope}.statusSubmit`,
    defaultMessage: 'Submited',
  },
  statusPending: {
    id: `${scope}.statusPending`,
    defaultMessage: 'Under Review',
  },
  statusDeclined: {
    id: `${scope}.statusDeclined`,
    defaultMessage: 'Declined',
  },
  statusExpired: {
    id: `${scope}.statusExpired`,
    defaultMessage: 'Expired',
  },
  statusApproved: {
    id: `${scope}.statusApproved`,
    defaultMessage: 'Approved',
  },
  statusWaiting: {
    id: `${scope}.statusWaiting`,
    defaultMessage: 'Waiting',
  },
  backToEdit: {
    id: `${scope}.backToEdit`,
    defaultMessage: 'Back to Edit',
  },
  individualApplication: {
    id: `${scope}.individualApplication`,
    defaultMessage: 'Individual Application',
  },
  IBEApplication: {
    id: `${scope}.IBEApplication`,
    defaultMessage: 'IBE Application',
  },
  input: {
    id: `${scope}.input`,
    defaultMessage: 'Input',
  },
  submitAndReturn: {
    id: `${scope}.submitAndReturn`,
    defaultMessage: 'Submit And Return',
  },
  expireTitle: {
    id: `${scope}.expireTitle`,
    defaultMessage: 'Reason',
  },
  SMsubmit: {
    id: `${scope}.SMsubmit`,
    defaultMessage: 'Submit',
  },
  copySuccess: {
    id: `${scope}.copySuccess`,
    defaultMessage: 'Copy Success',
  },
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: 'Mobile',
  },
  ibeid: {
    id: `${scope}.ibeid`,
    defaultMessage: 'IBE Number',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Created At',
  },
  operation: {
    id: `${scope}.operation`,
    defaultMessage: 'Operation',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'ID',
  },
  pass: {
    id: `${scope}.pass`,
    defaultMessage: 'Pass',
  },
  reject: {
    id: `${scope}.reject`,
    defaultMessage: 'Reject',
  },
  rejectReason: {
    id: `${scope}.rejectReason`,
    defaultMessage: 'Reject Reason',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'All',
  },
  activity: {
    id: `${scope}.activity`,
    defaultMessage: 'Activity',
  },
  innovate: {
    id: `${scope}.innovate`,
    defaultMessage: 'Innovation & Quality',
  },
  wechatMini: {
    id: `${scope}.wechatMini`,
    defaultMessage: 'Wechat applet',
  },
  refreshNow: {
    id: `${scope}.refreshNow`,
    defaultMessage: 'Let go and refresh immediately',
  },
  refreshComplete: {
    id: `${scope}.refreshComplete`,
    defaultMessage: 'Complete',
  },
  shareQrCode: {
    id: `${scope}.shareQrCode`,
    defaultMessage: 'Share Qr Code',
  },
  pleaseSelectAnAddressFirst: {
    id: `${scope}.pleaseSelectAnAddressFirst`,
    defaultMessage: 'Address first',
  },
  noPaymentMethods: {
    id: `${scope}.noPaymentMethods`,
    defaultMessage: 'No payment methods available',
  },
  productInformationNotExist: {
    id: `${scope}.productInformationNotExist`,
    defaultMessage: 'Product information does not exist',
  },
  switchStore: {
    id: `${scope}.switchStore`,
    defaultMessage: 'Switch Store',
  },
  selectQuantity: {
    id: `${scope}.selectQuantity`,
    defaultMessage: 'select quantity',
  },
  checkoutFromFlagshipStore: {
    id: `${scope}.checkoutFromFlagshipStore`,
    defaultMessage: 'Checkout from flagship store',
  },
  recommendedProducts: {
    id: `${scope}.recommendedProducts`,
    defaultMessage: 'Recommended Products',
  },
  datePickerFormat: {
    id: `${scope}.datePickerFormat`,
    defaultMessage: 'MM YYYY Statistic',
  },
  paymentTime: {
    id: `${scope}.paymentTime`,
    defaultMessage: 'Payment time',
  },
  shipDate: {
    id: `${scope}.shipDate`,
    defaultMessage: 'Ship date',
  },
  receivedDate: {
    id: `${scope}.receivedDate`,
    defaultMessage: 'Received date',
  },
  beforeDateRefund: {
    id: `${scope}.beforeDateRefund`,
    defaultMessage: 'Before {time} you can choose a refund',
  },
  FEIDEKAI: {
    id: `${scope}.FEIDEKAI`,
    defaultMessage: 'FEIDEKAI',
  },
  findStore: {
    id: `${scope}.findStore`,
    defaultMessage: 'Find Store',
  },
});
