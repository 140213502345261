/**
 * @author zhengji.su
 * @description config file of Catalog
 */

export const BALL_MOVE_TIME = 0.3;
export const BALL_DELAY_TIME = 0.1;
export const BALL_SIZE = 15;
export const CardColumns = 2;

export const ProductCardRadius = '8px';
export const CardTitleSize = '14px';

export const CardMargin = 10;
export const CardImgWeight = 152;

export const CardTitleColor = '#090909';

export default {
  ProductCardRadius,
};
