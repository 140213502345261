import { useState, useEffect } from 'react';
import useScript from 'utils/Hooks/useScript';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import useStore from 'containers/StoreProvider/hooks/useStore';
import { useGetWechatJSSDKSign } from 'containers/App/queries';
// import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
const useWechatJSSDK = () => {
  const [scriptUrl, setScriptUrl] = useState();
  const status = useScript(scriptUrl);

  const { getNearbyStores } = useStore();
  const { mutateAsync: getWechatJSSDKSign } = useGetWechatJSSDKSign();

  const isWechatEnv = determineEnvironment();
  // const notificationSnackbar = useNotificationSnackbar();

  const registerJSSDK = (JSSDKSignResult = {}) => {
    wx?.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: JSSDKSignResult.appId, // 必填，公众号的唯一标识
      timestamp: JSSDKSignResult.timestamp, // 必填，生成签名的时间戳
      nonceStr: JSSDKSignResult.noncestr, // 必填，生成签名的随机串
      signature: JSSDKSignResult.signature, // 必填，签名
      jsApiList: ['getLocation', 'openAddress'], // 必填，需要使用的JS接口列表
    });

    // https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=APPID&secret=APPSECRET
    // https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=ACCESS_TOKEN&type=jsapi
    // https://mp.weixin.qq.com/debug/cgi-bin/sandbox?t=jsapisign

    wx?.ready(res => {
      // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      console.log('wechat ready', res);
      // notificationSnackbar(`wechat ready:${JSON.stringify(res)}`)
      getNearbyStores(true);
    });

    wx?.error(err => {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      // notificationSnackbar(`wechat ready:${JSON.stringify(err)}`)
      console.log('wechat error', err);
    });
  };

  useEffect(() => {
    if (isWechatEnv && !scriptUrl) {
      setScriptUrl('https://res.wx.qq.com/open/js/jweixin-1.6.0.js');
    } else {
      getNearbyStores();
    }
  }, [isWechatEnv]);

  const url = window.location.href.split('#')[0];

  useEffect(() => {
    if (status === 'ready') {
      getWechatJSSDKSign(url, {
        onSuccess: result => {
          registerJSSDK(result[0]);
        },
      });
    }
  }, [status, url]);
};

export default useWechatJSSDK;
