/* eslint-disable */
function Icon12Times(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const strokewidth = props.strokewidth || 1;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg height={height} width={width} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill={fill}>
        <path d="M10.72 1.293a1 1 0 0 0-1.415 0L6.012 4.586 2.72 1.293a1 1 0 0 0-1.414 1.414L4.598 6 1.305 9.293a1 1 0 1 0 1.414 1.414l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414L7.426 6l3.293-3.293a1 1 0 0 0 0-1.414z" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  );
}

export default Icon12Times;
