/**
 * @api {QUERY} category Fetch products from category
 * @apiGroup Catalog
 */
export default function (id, sourceCode) {
  return `
    {
      category(id: ${id}) {
      id
      name
      description
      image
      products(sourceCode: "${sourceCode}") {
        items {
          id
          sku
          name
          url_key
          type_id
          stock_status
          media_gallery_entries {
            file
          }
          calculated_price
          special_price
          # Set Product as New From
          new_from_date
          new_to_date
          point_value
          price {
            minimalPrice {
              amount {
                value
                currency
              }
              adjustments {
                amount {
                  value
                  currency
                }
                code
                description
              }
            }
            maximalPrice {
              amount {
                value
                currency
              }
              adjustments {
                amount {
                  value
                  currency
                }
                code
                description
              }
            }
            regularPrice {
              amount {
                value
                currency
              }
              adjustments {
                amount {
                  value
                  currency
                }
                code
                description
              }
            }
          }
          ... on BundleProduct {
            items {
              option_id
              options {
                id
                qty
              }
            }
          }
        }
      }
    }
  }
  `;
}
