import { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import { formatDistance, parseMediaSourcesImage } from 'utils/helpers';
import { transitionTime } from 'muiTheme';
import ItemCell from 'containers/StoreProvider/components/ItemCell';
import Buttons from 'components/Buttons/Buttons';
import { StoreFlagshipIcon, NavigateIcon, AngleDownIcon, AngleUpIcon } from 'components/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  shopLogo: {
    width: 24,
    height: 24,
    [theme.breakpoints.up('lg')]: {
      width: 49,
      height: 49,
    },
  },
  outOfStock: {
    color: theme.palette.error.main,
  },
  someItemsOutOfStock: {
    color: theme.palette.primary.pending,
  },
  inStock: {
    color: theme.palette.success.main,
  },
  flex: {
    display: 'flex',
  },
  right: {
    marginLeft: 'auto',
    display: 'flex',
  },
  rightIcon: {
    cursor: 'pointer',
  },
  items: {
    padding: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.background.peachSupport,
      transition: `all ${transitionTime}s`,
    },
  },
  button: {
    padding: 0,
    minWidth: 'auto',
    minHeight: 'auto',
  },
  title: {
    fontWeight: 550,
    marginRight: theme.spacing(1),
  },
}));

function StoreItemDesktop({ item, selectedItem, storeNotice, onClickIcon, ...other }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  if (isEmpty(item)) return null;

  const isFlagStore = item?.extension_attributes?.flagship;
  const distance = item?.extension_attributes?.distance;

  return (
    <Box {...other}>
      <Box width={1} display="flex" className={classes.items}>
        <Grid item xs={2}>
          <Grid item>
            <Avatar
              alt={item.name}
              src={parseMediaSourcesImage(item?.extension_attributes?.logo, 'source')}
              className={classes.shopLogo}
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                {item.name}
              </Typography>
            </Grid>

            {isFlagStore && (
              <Grid item className={classes.flex}>
                <SvgIcon
                  component={StoreFlagshipIcon}
                  color="primary"
                  viewBox="0 0 24 24"
                  fontSize="large"
                />
              </Grid>
            )}

            <Grid item className={clsx(classes.rightIcon, classes.right)}>
              <SvgIcon
                component={NavigateIcon}
                viewBox="0 0 24 24"
                size="large"
                style={{ fontSize: '2rem' }}
                color="primary"
                onClick={onClickIcon}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={1} nowrap>
            <Grid item xs={8}>
              <Typography variant="body1" color="textSecondary">
                {item.street}
              </Typography>
            </Grid>
            <Grid item className={classes.right}>
              <Typography variant="body1">{formatDistance(distance)}</Typography>
            </Grid>
          </Grid>
          <Grid>
            <Box color="text.secondary" ml={-0.5} mt={1}>
              <Buttons
                size="small"
                color="inherit"
                messageId={
                  <Typography variant="caption" color="textSecondary">
                    {expanded ? '收起' : '店铺详细信息'}
                  </Typography>
                }
                endIcon={
                  <SvgIcon
                    component={expanded ? AngleUpIcon : AngleDownIcon}
                    viewBox="0 0 12 12"
                    size="small"
                    color="inherit"
                    style={{ fontSize: '0.75rem' }}
                  />
                }
                onClick={() => setExpanded(!expanded)}
              />
            </Box>

            <Collapse in={expanded}>
              <Box mt={1}>
                <ItemCell spacing={0.5} title="联系电话" value={item.phone} copy />
                <ItemCell spacing={0.5} title="店主微信" value="" copy />
                <ItemCell spacing={0.5} title="营业时间" value="" />
              </Box>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

StoreItemDesktop.propTypes = {
  item: PropTypes.object.isRequired,
  selectedItem: PropTypes.object,
  storeNotice: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClickIcon: PropTypes.func,
};

export default StoreItemDesktop;
