import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Buttons from 'components/Buttons/Buttons';
import SvgIcon from '@material-ui/core/SvgIcon';
import { AngleLeftIcon16 } from 'components/Icons';
import clsx from 'clsx';
import FormatMessage from 'components/FormatMessage';

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.mixins.toolbar.minHeight,
    top: ({ position }) =>
      ['fixed', 'sticky'].includes(position) ? 0 : theme.mixins.toolbar.minHeight,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: (props) => !!props.border && `0 0 0 2px ${theme.palette.background.peach}`,
    borderBottom: props => !!props.border && `2px solid ${theme.palette.background.peach}`,
    zIndex: theme.zIndex.appBar - 1,
    padding: theme.spacing(0, 2),
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backItem: {
    marginLeft: theme.spacing(-1.5),
    minHeight: 40,
    minWidth: 40,
    borderRadius: '50%',
  },
  topItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  rightItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  goBack: {
    [theme.breakpoints.up('lg')]: {
      fontWeight: 700,
    },
  },
}));

const onBack = () => window.history.back();

const NavigationBar = props => {
  const {
    className,
    classes: propsClasses,
    route,
    onClick,
    title,
    renderBackItem,
    renderTitle,
    rightItem,
    buttonColor,
    buttonText,
    iconColor,
    border,
    ...other
  } = props;
  const classes = useStyles(props);
  return (
    <AppBar position="static" color="default" className={clsx(classes.root, className)} {...other}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid item xs>
          {renderBackItem ? (
            renderBackItem()
          ) : (
            <Buttons
              route={route}
              messageId={
                <>
                  <SvgIcon
                    component={AngleLeftIcon16}
                    viewBox="5 0 16 16"
                    className="go-back-icon"
                    color={iconColor || 'primary'}
                  />
                  <FormatMessage
                    message={buttonText}
                    color={buttonColor}
                    className={classes.goBack}
                  />
                </>
              }
              className={classes.backItem}
              {...(onClick || !route ? { onClick: onClick || onBack } : {})}
            />
          )}
        </Grid>
        <Grid item className={classes.topItem}>
          {renderTitle ? (
            renderTitle()
          ) : (
            <Typography color="textSecondary" variant="h3" className={classes.title}>
              {title}
            </Typography>
          )}
        </Grid>
        <Grid item xs className={classes.rightItem}>
          {rightItem}
        </Grid>
      </Grid>
    </AppBar>
  );
};

NavigationBar.propTypes = {
  title: PropTypes.node,
  route: PropTypes.string,
  renderBackItem: PropTypes.func,
  renderTitle: PropTypes.func,
  rightItem: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonColor: PropTypes.string,
  iconColor: PropTypes.string,
  border: PropTypes.bool,
};
export default NavigationBar;
