/*
 * Authorization Messages
 *
 * This contains all the text for the Authorization container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Authorization';

export default defineMessages({
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Welcome',
  },
  welcomeTo: {
    id: `${scope}.welcomeTo`,
    defaultMessage: 'Welcome to FEIDEKAI',
  },
  assistant: {
    id: `${scope}.assistant`,
    defaultMessage: 'Just enter the invitation code and password to complete the registration',
  },
  newUserText: {
    id: `${scope}.newUserText`,
    defaultMessage: 'New User？',
  },
  weChatLogin: {
    id: `${scope}.weChatLogin`,
    defaultMessage: 'WeChat Login',
  },
  sendCodeText: {
    id: `${scope}.sendCodeText`,
    defaultMessage: 'SEND',
  },
  invitationCodeHelp: {
    id: `${scope}.invitationCodeHelp`,
    defaultMessage: 'What is it?',
  },
  toggleVisibility: {
    id: `${scope}.toggleVisibility`,
    defaultMessage: 'Toggle password visibility',
  },
  clickToReturn: {
    id: `${scope}.clickToReturn`,
    defaultMessage: 'Click here to return',
  },
  clickHere: {
    id: `${scope}.clickHere`,
    defaultMessage: 'Click Here',
  },
  cantReceiveSmsCode: {
    id: `${scope}.cantReceiveSmsCode`,
    defaultMessage: 'Could not receive verification code?',
  },
  logIn: {
    id: `${scope}.logIn`,
    defaultMessage: 'Log In',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign In',
  },
  createAccount: {
    id: `${scope}.createAccount`,
    defaultMessage: 'Create Account',
  },
  createAnAccount: {
    id: `${scope}.createAnAccount`,
    defaultMessage: 'Create an Account',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'SIGN UP',
  },
  signUpNow: {
    id: `${scope}.signUpNow`,
    defaultMessage: 'Sign Up Now!',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account',
  },
  id: {
    id: `${scope}.id`,
    defaultMessage: 'ID:',
  },
  group: {
    id: `${scope}.group`,
    defaultMessage: 'Group:',
  },
  enrollerID: {
    id: `${scope}.enrollerID`,
    defaultMessage: 'Enroller ID',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  passwordRules: {
    id: `${scope}.passwordRules`,
    defaultMessage:
      'Passwords must be a minimum of eight (8) characters long and contain at least one (1) character from each of the following three (3) categories: uppercase letter (A-Z), lowercase letter (a-z), and digit (0-9).',
  },
  youNeedToBe18: {
    id: `${scope}.youNeedToBe18`,
    defaultMessage: 'You need to be 18 years of age to sign up for this website',
  },
  authCode: {
    id: `${scope}.authCode`,
    defaultMessage: 'Authorization Code',
  },
  sendCode: {
    id: `${scope}.sendCode`,
    defaultMessage: 'Send Code',
  },
  waitForCode: {
    id: `${scope}.waitForCode`,
    defaultMessage:
      'Wait {seconds, plural, =0 {{seconds} seconds.} one {{seconds} second.} other {{seconds} seconds.}}',
  },
  codeSentCounter: {
    id: `${scope}.codeSentCounter`,
    defaultMessage: 'Code Sent ({seconds})',
  },
  weSentYouACode: {
    id: `${scope}.weSentYouACode`,
    defaultMessage: 'We sent you a code',
  },
  enterCode: {
    id: `${scope}.enterCode`,
    defaultMessage: 'Enter the verification code',
  },
  enterCodeNote: {
    id: `${scope}.enterCodeNote`,
    defaultMessage: 'A 4-digit code has been sent to {destination}. Please enter it below.',
  },
  noAccountSignUp: {
    id: `${scope}.noAccountSignUp`,
    defaultMessage: "Don't have an account? Sign Up",
  },
  noAccountYet: {
    id: `${scope}.noAccountYet`,
    defaultMessage: "Don't have an account yet?",
  },
  haveAccountSignIn: {
    id: `${scope}.haveAccountSignIn`,
    defaultMessage: 'Have an account? Sign In',
  },
  alreadyHaveAnAccount: {
    id: `${scope}.alreadyHaveAnAccount`,
    defaultMessage: 'I already have an account',
  },
  codeSent: {
    id: `${scope}.codeSent`,
    defaultMessage: 'Verification code has been sent to your phone',
  },
  possibleSolutions: {
    id: `${scope}.possibleSolutions`,
    defaultMessage: 'Possible Solutions',
  },
  wrongPhoneNumber: {
    id: `${scope}.wrongPhoneNumber`,
    defaultMessage: 'Wrong phone number?',
  },
  clickHereToGoBackAndCheck: {
    id: `${scope}.clickHereToGoBackAndCheck`,
    defaultMessage: 'Click here to go back and double check.',
  },
  pleaseWaitForAMinute: {
    id: `${scope}.pleaseWaitForAMinute`,
    defaultMessage: 'Please wait for a minute or two.',
  },
  takesTimeForSMSToArrive: {
    id: `${scope}.takesTimeForSMSToArrive`,
    defaultMessage: 'Sometimes it takes some time for the SMS to arrive.',
  },
  stillCanNotSignIn: {
    id: `${scope}.stillCanNotSignIn`,
    defaultMessage: 'Still cannot sign in?',
  },
  contactCustomerSupport: {
    id: `${scope}.contactCustomerSupport`,
    defaultMessage: 'Try contacting our customer support service',
  },
  whyNotReceivingCode: {
    id: `${scope}.whyNotReceivingCode`,
    defaultMessage: 'Why am I not receiving a code?',
  },
  logInWithOTP: {
    id: `${scope}.logInWithOTP`,
    defaultMessage: 'Log in with dynamic password instead',
  },
  logInWithPassword: {
    id: `${scope}.logInWithPassword`,
    defaultMessage: 'Log in with password instead',
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Forgot password?',
  },
  socialLogin: {
    id: `${scope}.socialLogin`,
    defaultMessage: 'SOCIAL LOG IN',
  },
  resetPassword: {
    id: `${scope}.resetPassword`,
    defaultMessage: 'Reset Password',
  },
  findYourWayBack: {
    id: `${scope}.findYourWayBack`,
    defaultMessage: 'Find your way back',
  },
  validate: {
    id: `${scope}.validate`,
    defaultMessage: 'Validate',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm Password',
  },
  backToLogin: {
    id: `${scope}.backToLogin`,
    defaultMessage: 'Back to Login',
  },
  verificationIsNecessary: {
    id: `${scope}.verificationIsNecessary`,
    defaultMessage:
      'A mobile verification is necessary before we proceed you to password reset process.',
  },
  referralCode: {
    id: `${scope}.referralCode`,
    defaultMessage: 'FitLine Referral Code',
  },
  whatIsReferral: {
    id: `${scope}.whatIsReferral`,
    defaultMessage: 'What is referral?',
  },
  enrollingExplanation: {
    id: `${scope}.enrollingExplanation`,
    defaultMessage:
      'FitLine is a referral system. Before you can register for an account you need a referrer ID.',
  },
  updateAddress: {
    id: `${scope}.updateAddress`,
    defaultMessage: 'Update Address',
  },
  useAddress: {
    id: `${scope}.useAddress`,
    defaultMessage: 'Save and Use',
  },
  addAddress: {
    id: `${scope}.addAddress`,
    defaultMessage: 'Add Address',
  },
  saveAddress: {
    id: `${scope}.saveAddress`,
    defaultMessage: 'Save Address',
  },
  updateFapiao: {
    id: `${scope}.updateFapiao`,
    defaultMessage: 'Update Invoice',
  },
  useFapiao: {
    id: `${scope}.useFapiao`,
    defaultMessage: 'Save and Use',
  },
  addFapiao: {
    id: `${scope}.addFapiao`,
    defaultMessage: 'Add Invoice',
  },
  saveFapiao: {
    id: `${scope}.saveFapiao`,
    defaultMessage: 'Save Invoice',
  },
  importAddressFromWechat: {
    id: `${scope}.importAddressFromWechat`,
    defaultMessage: 'Import address from wechat',
  },
  modalConfirmPay: {
    id: `${scope}.modalConfirmPay`,
    defaultMessage: 'Confirm that the information is consistent and pay',
  },
  payNowWithTotal: {
    id: `${scope}.payNowWithTotal`,
    defaultMessage: 'Pay{total, plural, =0 {} one { {total}} other { {total}}}',
  },
  checkLegal: {
    id: `${scope}.checkLegal`,
    defaultMessage: '请同意我们的条款以及法律',
  },
  changePassword: {
    id: `${scope}.changePassword`,
    defaultMessage: 'Change Password',
  },
  submitAndReturn: {
    id: `${scope}.submitAndReturn`,
    defaultMessage: 'Submit And Return',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  checkOut: {
    id: `${scope}.checkOut`,
    defaultMessage: 'Check Out{quantity, plural, =0 {} one { ({quantity})} other { ({quantity})}}',
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: 'Change',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Add to Cart',
  },
  applyForSM: {
    id: `${scope}.applyForSM`,
    defaultMessage: 'Apply for SM',
  },
  addingToCart: {
    id: `${scope}.addingToCart`,
    defaultMessage: 'Adding...',
  },
  viewMore: {
    id: `${scope}.viewMore`,
    defaultMessage: 'View More',
  },
  buyNow: {
    id: `${scope}.buyNow`,
    defaultMessage: 'Buy Now',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'View All',
  },
  myStatistics: {
    id: `${scope}.myStatistics`,
    defaultMessage: 'My Statistics',
  },
  startShopping: {
    id: `${scope}.startShopping`,
    defaultMessage: 'Start Shopping',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  chooseCoupon: {
    id: `${scope}.chooseCoupon`,
    defaultMessage: 'No coupons selected, {quantity} available',
  },
  modifyCoupon: {
    id: `${scope}.modifyCoupon`,
    defaultMessage: 'Modify coupon',
  },
  couponCanBeUsed: {
    id: `${scope}.couponCanBeUsed`,
    defaultMessage: 'A coupon can be used for this order',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  deleteAddress: {
    id: `${scope}.deleteAddress`,
    defaultMessage: 'Delete Address',
  },
  deleteFapiao: {
    id: `${scope}.deleteFapiao`,
    defaultMessage: 'Delete Invoice',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  editAddress: {
    id: `${scope}.editAddress`,
    defaultMessage: 'Edit Address',
  },
  selectAddress: {
    id: `${scope}.selectAddress`,
    defaultMessage: 'Select',
  },
  selectFapiao: {
    id: `${scope}.selectFapiao`,
    defaultMessage: 'Select',
  },
  modalNotSure: {
    id: `${scope}.modalNotSure`,
    defaultMessage: 'Not sure, check back',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms and Conditions',
  },
  more: {
    id: `${scope}.more`,
    defaultMessage: 'View More',
  },
  validation: {
    id: `${scope}.validation`,
    defaultMessage: 'Validation',
  },
  confirmation: {
    id: `${scope}.confirmation`,
    defaultMessage: 'Confirmation',
  },
  passwordMust: {
    id: `${scope}.passwordMust`,
    defaultMessage: 'Password must:',
  },
  beAtLeastLong: {
    id: `${scope}.beAtLeastLong`,
    defaultMessage: 'Be at least {length} characters long',
  },
  containUpperAndLower: {
    id: `${scope}.containUpperAndLower`,
    defaultMessage: 'Contain both upper and lower case letters',
  },
  containNumberOrSpecial: {
    id: `${scope}.containNumberOrSpecial`,
    defaultMessage: 'Contain numbers or special characters',
  },
  updateEmail: {
    id: `${scope}.updateEmail`,
    defaultMessage: 'Update Information',
  },
  resetPasswordAssistant: {
    id: `${scope}.resetPasswordAssistant`,
    defaultMessage: 'Request to reset password',
  },
  resetPasswordTepOne: {
    id: `${scope}.resetPasswordTepOne`,
    defaultMessage: 'Verify mobile phone number',
  },
  inputNewPassword: {
    id: `${scope}.inputNewPassword`,
    defaultMessage: 'Go to enter the new password',
  },
  resetComplete: {
    id: `${scope}.resetComplete`,
    defaultMessage: 'Reset Complete',
  },
  firstClauseStatement: {
    id: `${scope}.firstClauseStatement`,
    defaultMessage: 'I agree to the ',
  },
  lastClauseStatement: {
    id: `${scope}.lastClauseStatement`,
    defaultMessage: 'Terms and statements',
  },
  registeredPartner: {
    id: `${scope}.registeredPartner`,
    defaultMessage: 'Register partner account',
  },
  joinUs: {
    id: `${scope}.joinUs`,
    defaultMessage: 'Want to join us？',
  },
  newPartner: {
    id: `${scope}.newPartner`,
    defaultMessage: 'New partner. Hello!',
  },
  inputInvitationCode: {
    id: `${scope}.inputInvitationCode`,
    defaultMessage: 'Invitation code',
  },
  inputPhoneNumber: {
    id: `${scope}.inputPhoneNumber`,
    defaultMessage: 'Please enter your mobile phone number',
  },
  inputCorrectPhoneNumber: {
    id: `${scope}.inputCorrectPhoneNumber`,
    defaultMessage: '11 digit mobile phone number',
  },
  inputPassword: {
    id: `${scope}.inputPassword`,
    defaultMessage: 'Please input a password',
  },
  selectPackageGoods: {
    id: `${scope}.selectPackageGoods`,
    defaultMessage: 'Select a package goods',
  },
  selectOnePackageGoods: {
    id: `${scope}.selectOnePackageGoods`,
    defaultMessage: 'Choose a suit',
  },
  usePasswordLogin: {
    id: `${scope}.usePasswordLogin`,
    defaultMessage: 'Login with franchisee ID and password',
  },
  // socialAccountLogin: {
  //   id: `${scope}.socialAccountLogin`,
  //   defaultMessage: 'Social account login:',
  // },
  welcomeFC: {
    id: `${scope}.welcomeFC`,
    defaultMessage: 'Welcome Back, Franchisee',
  },
  FCLoginId: {
    id: `${scope}.FCLoginId`,
    defaultMessage: 'Franchisee ID',
  },
  inputFCLoginId: {
    id: `${scope}.inputFCLoginId`,
    defaultMessage: 'Please enter an eight digit your Franchisee ID',
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Go Back',
  },
  fourVerificationCode: {
    id: `${scope}.fourVerificationCode`,
    defaultMessage: 'Verification code',
  },
  formPageHeadTop: {
    id: `${scope}.formPageHeadTop`,
    defaultMessage: "Don't worry",
  },
  formPageHeadBottom: {
    id: `${scope}.formPageHeadBottom`,
    defaultMessage: 'You can set a new password',
  },
  clickLogin: {
    id: `${scope}.clickLogin`,
    defaultMessage: 'Click here to log in',
  },
  inputNowNewPassword: {
    id: `${scope}.inputNowNewPassword`,
    defaultMessage: 'Now enter your new password',
  },
  goToBuy: {
    id: `${scope}.goToBuy`,
    defaultMessage: 'PLACE AN ORDER',
  },
  aboutInvitationCode: {
    id: `${scope}.aboutInvitationCode`,
    defaultMessage: 'About Invitation Code',
  },
  timeAfter: {
    id: `${scope}.timeAfter`,
    defaultMessage: 'Resend in {time} seconds',
  },
  passwordNotice: {
    id: `${scope}.passwordNotice`,
    defaultMessage: 'Please use a combination of letters and numbers for your password',
  },
  editShippingAddress: {
    id: `${scope}.editShippingAddress`,
    defaultMessage: 'Edit shipping address',
  },
  dialogTitle: {
    id: `${scope}.dialogTitle`,
    defaultMessage: 'FEIDEKAI Invitation',
  },
  dialogCaption: {
    id: `${scope}.dialogCaption`,
    defaultMessage:
      'Feidekai is a recommendation system. You need a recommender. After entering the ID of the recommender, you can register your account',
  },
  dialogAssistantMsg: {
    id: `${scope}.dialogAssistantMsg`,
    defaultMessage: 'Enter Recommender ID',
  },
  dialogConfirmMsg: {
    id: `${scope}.dialogConfirmMsg`,
    defaultMessage: 'No Recommender',
  },
  min18Age: {
    id: `${scope}.min18Age`,
    defaultMessage: 'I confirm that I am over 18 years old',
  },
  registrationAgreement: {
    id: `${scope}.registrationAgreement`,
    defaultMessage: 'User Registration Agreement',
  },
  firstClauseStatementTwo: {
    id: `${scope}.firstClauseStatementTwo`,
    defaultMessage: 'I have read and agree',
  },
  passwordVerificationPrompt: {
    id: `${scope}.passwordVerificationPrompt`,
    defaultMessage: 'Inconsistent password entered twice',
  },
  passwordValidate: {
    id: `${scope}.passwordValidate`,
    defaultMessage: 'Password strength does not meet requirements',
  },
});
