/**
 *
 * StoreList
 *
 */

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import useStore from 'containers/StoreProvider/hooks/useStore';
import { ListItemAvatar } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import StoreItemDesktop from 'containers/StoreProvider/components/StoreItemDesktop';
import EmptyState from 'components/EmptyState/Loadable';
import { ResearchIcon } from 'components/Icons';
import { useIntl } from 'react-intl';
import StoreItem from './StoreItem';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      height: 1,
      backgroundColor: theme.palette.background.peach,
      margin: theme.spacing(0, 3),
    },
  },
}));

function StoreList({ list, onSelect, onReset }) {
  const classes = useStyles();
  const intl = useIntl();
  const { storeSelected } = useStore();

  const emptyStateRender = () => (
    <EmptyState
      icon={
        <Box className={classes.icon}>
          <ResearchIcon />
        </Box>
      }
      title={intl.formatMessage(messages.notFoundStore)}
      action={{
        label: intl.formatMessage(messages.resetFilter),
        event: () => onReset(),
      }}
      buttonStyles={{ minHeight: 'auto', height: 36, marginLeft: 'auto' }}
      className={classes.empty}
    />
  );

  return (
    <Box>
      {list.map(item => (
        <Fragment key={item.source_code}>
          <Hidden lgUp>
            <StoreItem
              selectedItem={storeSelected}
              item={item}
              onClick={() => onSelect(item)}
              storeNotice={ListItemAvatar.storeNotice}
            />
          </Hidden>
          <Hidden mdDown>
            <StoreItemDesktop
              item={item}
              onClickIcon={() => onSelect(item)}
              storeNotice={ListItemAvatar.storeNotice}
            />
          </Hidden>
          <Divider className={classes.divider} />
        </Fragment>
      ))}
      {list.length <= 0 && (
        <Box p={3} align="center">
          {emptyStateRender()}
        </Box>
      )}
    </Box>
  );
}

StoreList.propTypes = {
  list: PropTypes.array,
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
};

export default StoreList;
