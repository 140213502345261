import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FtRadio from './FtRadio';

const FormRadio = ({ name, rules, label, defaultValue, ...rest }) => {
  const { control } = useFormContext();
  const {
    field: { ref, ...fieldProps },
    fieldState,
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || false,
  });

  return useMemo(() => (
    <FormControl error={!!fieldState.error}>
      <FormControlLabel
        control={<FtRadio color="primary" />}
        label={label}
        checked={fieldProps.value}
        {...fieldProps}
        {...rest}
        inputRef={ref}
      />
      {!!fieldState.error && <FormHelperText>{fieldState?.error?.message}</FormHelperText>}
    </FormControl>
  ));
};

FormRadio.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
};

export default FormRadio;
