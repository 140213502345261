import { useMutation, useQuery } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';

export const getSliderQuery = id => request('GET', `/rbslider/banner/${id}`);

/**
 * @api {POST} /social/bind/customer/:type Bind mobile phone number 绑定手机号码
 * @apiGroup HomePage
 */
export const bindPhoneNumber = ({ type, ...other }) =>
  request('POST', `/social/bind/customer/${type}`, other);

export const useGetHomeSlider = id =>
  useQuery('homePage.slider', () => getSliderQuery(id), querySetting);

export const useBindPhoneNumber = () => useMutation(payload => bindPhoneNumber(payload));
