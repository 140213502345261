import store from 'store';
import { useEffect, useState } from 'react';
import { useGetCartQuote } from 'containers/CartProvider/queries';
import { useGetStoreConfig, useGetStoreViewsQuery } from 'containers/App/queries';
import { useChangeStore } from 'utils/Hooks/useChangeLanguage';

const useInitApplication = () => {
  const { data: configs } = useGetStoreConfig();
  const { data: views } = useGetStoreViewsQuery();
  const { refetch: refetchCartQuote } = useGetCartQuote();

  const changeStore = useChangeStore();
  const [storeConfigs, setStoreConfigs] = useState(() => store.get('store_configs'));
  const [storeViews, setStoreViews] = useState(() => store.get('store_views'));

  useEffect(() => {
    // if (!storeConfigs) {
    store.set('store_configs', configs, new Date().getTime() + 86400000);
    setStoreConfigs(configs);
    // }
  }, [configs]);

  useEffect(() => {
    // if (!storeViews) {
    store.set('store_views', views, new Date().getTime() + 86400000);
    setStoreViews(configs);
    // }
  }, [views]);

  useEffect(() => {
    if (!store.get('store_code')) {
      if (storeViews && storeConfigs) {
        changeStore();
      }
    }
  }, [storeConfigs, storeViews]);

  const initApp = async (options = {}) => {
    await refetchCartQuote();
    options?.complete?.();
  };

  return initApp;
};

export default useInitApplication;
