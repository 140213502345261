import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import { IconsIcon, AngleLeftIcon16, NavigateIcon } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';
import placeholder from 'images/placeholder.png';
import ItemCell from 'containers/StoreProvider/components/ItemCell';
import { formatDistance } from 'utils/helpers';
import { useGetQrCode } from 'containers/SharingProvider/queries';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  mp: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  scrollView: {
    maxHeight: 'calc(100vh - 180px)',
    overflowY: 'auto',
  },
  divider: {
    backgroundColor: theme.palette.background.darkPeach,
  },
  textItem: {
    marginBottom: theme.spacing(1),
  },
  storeCover: {
    borderRadius: theme.spacing(1),
    width: '100%',
    height: 200,
    background: `url(${placeholder}) center center no-repeat`,
    backgroundSize: '100%',
  },
  navigateIcon: {
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.primary.white,
    marginLeft: '100%',
    transform: 'translate(-150%, 0)',
    marginTop: -28,
    '&:hover': {
      backgroundColor: `${theme.palette.background.primary} !important`,
    },
  },
  copy: {
    minWidth: 20,
    marginLeft: theme.spacing(0.5),
    fontSize: '0.75rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

const StoreDetail = ({ item = {}, onBack, onMoveToLocation }) => {
  const classes = useStyles();
  const distance = item?.extension_attributes?.distance;
  const { data: qrcode } = useGetQrCode();

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'FitLine.png';
    link.href = qrcode;
    link.click();
  };

  const handleMoveToLocation = () => {
    onMoveToLocation(item);
  };

  useEffect(() => {
    const timer = setTimeout(handleMoveToLocation, 500);
    return () => clearTimeout(timer);
  }, [handleMoveToLocation]);

  return (
    <Box pt={1.5} px={3}>
      <Buttons
        size="medium"
        messageId={<FormattedMessage {...messages.backToStoreList} />}
        startIcon={
          <SvgIcon
            component={AngleLeftIcon16}
            fontSize="small"
            style={{ fontSize: '1rem' }}
            viewBox="0 0 16 16"
            color="primary"
          />
        }
        onClick={onBack}
        className={classes.backButton}
      />
      <Box className={classes.scrollView}>
        <Box display="flex" flexDirection="column" mt={1.5}>
          <Box className={classes.storeCover} style={{ backgroundImage: `url(${placeholder})` }} />
          {/* <img
            src={placeholder}
            width="100%"
            height="200"
            alt="店铺"
            className={classes.storeCover}
          /> */}
          <IconButton className={classes.navigateIcon} onClick={handleMoveToLocation}>
            <SvgIcon
              component={NavigateIcon}
              color="inherit"
              viewBox="0 0 24 24"
              style={{ fontSize: '1.5rem' }}
            />
          </IconButton>
        </Box>
        <Typography variant="h1">{item.name}</Typography>
        <Typography variant="body1"> {item.street}</Typography>
        <Typography variant="body1" color="textSecondary">
          距离 {formatDistance(distance)}
        </Typography>

        <Box mt={3}>
          <ItemCell title="联系电话" value={item.phone} copy />
          <ItemCell title="店主微信" value="" copy />
          <ItemCell title="营业时间" value="" />
        </Box>

        <Box py={2}>
          <Divider className={classes.divider} />
        </Box>
        <Box className={classes.mp}>
          <Box mr={2}>
            <Typography variant="h3" className={classes.textItem}>
              店铺小程序
            </Typography>
            <Typography variant="body1" className={classes.textItem}>
              你可以直接扫码进入该店铺的小程序进行购买
            </Typography>
            <Buttons
              messageId="下载二维码"
              startIcon={
                <SvgIcon
                  component={IconsIcon}
                  fontSize="small"
                  style={{ fontSize: '1rem' }}
                  viewBox="0 0 16 16"
                  color="primary"
                />
              }
              onClick={handleDownload}
            />
          </Box>
          <img src={qrcode || placeholder} width="200" height="200" alt="店铺小程序" />
        </Box>
      </Box>
    </Box>
  );
};

StoreDetail.propTypes = {
  onBack: PropTypes.func,
  item: PropTypes.object,
  onMoveToLocation: PropTypes.func,
};

export default StoreDetail;
