import { memo } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@material-ui/core/styles';

import isNull from 'lodash/isNull';
import currency from 'currency.js';
import Box from '@material-ui/core/Box';

export const formatPrice = (input, symbol = '¥') => {
  const decimals = input % 1 === 0 ? 0 : 2;
  const output = currency(input, {
    symbol,
    separator: '',
    precision: decimals,
    formatWithSymbol: true,
  });
  return output.format();
};

const FinalPrice = styled(({ ...other }) => <span {...other} />)({
  display: 'inline-flex',
  marginRight: 8,
  color: props => props.color,
  fontSize: props => (Number.isInteger(props.size) ? `${props.size}px` : props.size),
  fontFamily: props => props.fontFamily,
  fontWeight: props => props.fontWeight,
});

const OriginalPrice = styled(({ textDecorationColor, ...other }) => <span {...other} />)({
  display: 'inline-flex',
  color: props => props.color,
  fontSize: props => (Number.isInteger(props.size) ? `${props.size * 0.75}px` : props.size),
  fontWeight: 400,
  textDecoration: 'line-through',
  fontFamily: props => props.fontFamily,
  textDecorationColor: props => props.textDecorationColor,
});

export function Price({
  price,
  originalPrice,
  size,
  secondarySize,
  color,
  secondaryColor,
  fontFamily,
  fontWeight,
  secondaryFontFamily,
  textDecorationColor,
  count,
  ...other
}) {
  const theme = useTheme();
  const ps = size || theme.typography.h5.fontSize;
  let pc = theme.palette.error.main;
  if (color) {
    if (color === 'primary' || color === 'secondary') pc = theme.palette[color].main;
    if (color === 'textPrimary' || color === 'textSecondary') pc = '#006dba';
    if (color === 'initial' || color === 'inherit') pc = color;
    if (color === 'grey' || color === 'grey') pc = theme.palette.text.secondary;
  }
  const ss = secondarySize || theme.typography.subtitle1.fontSize;
  let sc = theme.palette.primary.silver;
  if (secondaryColor) {
    if (secondaryColor === 'primary' || secondaryColor === 'secondary')
      sc = theme.palette[color].main;
    if (secondaryColor === 'textPrimary' || secondaryColor === 'textSecondary')
      sc = theme.palette.text[color];
    if (secondaryColor === 'initial' || secondaryColor === 'inherit') sc = color;
  }

  const ff = fontFamily || theme.typography.h1.fontFamily;
  const fw = fontWeight || theme.typography.h1.fontWeight;

  const sff = secondaryFontFamily || theme.typography.h3.fontFamily;
  const stdc = textDecorationColor || theme.palette.primary.main;

  const fp = parseFloat(price * count, 10);
  const op = parseFloat(originalPrice * count, 10);
  return (
    <Box {...other} component="span">
      <FinalPrice size={ps} color={pc} fontFamily={ff} fontWeight={fw}>
        {formatPrice(fp === 0 ? op : fp)}
      </FinalPrice>
      {!isNull(originalPrice) && fp !== op && fp !== 0 && (
        <OriginalPrice size={ss} color={sc} fontFamily={sff} textDecorationColor={stdc}>
          {formatPrice(parseFloat(originalPrice, 10))}
        </OriginalPrice>
      )}
    </Box>
  );
}

Price.defaultProps = {
  originalPrice: null,
  count: 1,
};

Price.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondarySize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
  textDecorationColor: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryFontFamily: PropTypes.string,
  count: PropTypes.number,
};

export default memo(Price);
