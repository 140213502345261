/**
 *
 * CategoryEntry
 *
 */

import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { useGetAllCategories } from 'containers/CatalogPage/queries';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { parseCategoryImage } from 'utils/helpers';
import routes from 'utils/routes';
import Picture from 'components/Picture';
import placeholder from 'images/placeholder.png';
import { makeStyles } from '@material-ui/core/styles';
import Buttons from 'components/Buttons/Buttons';
import Divider from '@material-ui/core/Divider';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/HomePage/messages';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SvgIcon from '@material-ui/core/SvgIcon';
import { AngleRightIcon16 } from 'components/Icons';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
// eslint-disable-next-line import/named
import { transitionTime } from 'muiTheme';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
  },
  center: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  desc: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.75rem',
    },
  },
  picture: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.background.darkPeach}`,
    [theme.breakpoints.up('lg')]: {
      borderWidth: 2,
      borderColor: theme.palette.background.peach,
    },
    borderRadius: 8,
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  moreButton: {
    height: 32,
  },
  items: {
    '&:hover': {
      '& .items-img': {
        borderRadius: 8,
        boxShadow: '0 0 8px rgb(0 0 0 / 8%)',
        transition: `background-color ${transitionTime}s, box-shadow ${transitionTime}s`,
      },
      '& .items-title': {
        color: theme.palette.primary.main,
        transition: `color ${transitionTime}s`,
      },
    },
  },
}));
export function CategoryEntryContent(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const { onClick, center, onClickAll } = props;
  const matchLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const { data, isLoading, error } = useGetAllCategories();

  if (error) return null;

  const handleAllClick = () => (onClickAll ? onClickAll() : history.push(routes.catalogEmpty));

  return (
    <Box className={classes.root}>
      <Box display="flex" overflow="auto" className={clsx('content', { [classes.center]: center })}>
        <Box
          display="flex"
          overflow="visible"
          alignItems="center"
          className="itemsContainer"
          my={1}
          pl={2}
        >
          {isLoading ? (
            <Box display="flex">
              <Box width={{ xs: 120, lg: 192 }} height={{ xs: 120, lg: 192 }} mr={2}>
                <Skeleton height="100%" variant="rect" />
              </Box>
              <Box width={{ xs: 120, lg: 192 }} height={{ xs: 120, lg: 192 }} mr={2}>
                <Skeleton height="100%" variant="rect" />
              </Box>
              <Box width={{ xs: 120, lg: 192 }} height={{ xs: 120, lg: 192 }} mr={2}>
                <Skeleton height="100%" variant="rect" />
              </Box>
              <Box width={{ xs: 120, lg: 192 }} height={{ xs: 120, lg: 192 }} mr={2}>
                <Skeleton height="100%" variant="rect" />
              </Box>
              <Box width={{ xs: 120, lg: 192 }} height={{ xs: 120, lg: 192 }} mr={2}>
                <Skeleton height="100%" variant="rect" />
              </Box>
            </Box>
          ) : (
            <>
              {data
                .filter(cat => cat.include_in_menu === 1)
                .slice(0, matchLg ? 5 : undefined)
                .map(cat => (
                  <Box
                    minWidth="118px"
                    display="inline-flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    mr={{ xs: 2, lg: 3 }}
                    style={{ textDecoration: 'none' }}
                    key={cat.id}
                    component={Link}
                    to={routes.catalog(cat.url_key)}
                    className={classes.items}
                    onClick={() => onClick && onClick(cat)}
                  >
                    <Box
                      height={{ xs: 120, lg: 192 }}
                      width={{ xs: 120, lg: 192 }}
                      mb={{ xs: 1, lg: 2 }}
                      align="center"
                      bgcolor="background.default"
                      className={clsx(classes.picture, 'items-img')}
                    >
                      {cat.image === null ? (
                        <Box height={{ xs: 88, lg: 160 }} width={{ xs: 88, lg: 160 }}>
                          <img
                            src={placeholder}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                            }}
                            alt="placeholder"
                          />
                        </Box>
                      ) : (
                        <Picture
                          src={parseCategoryImage(cat.image)}
                          alt={cat.name}
                          title=""
                          op="fit"
                          params={{
                            width: 176,
                            height: 176,
                          }}
                        />
                      )}
                    </Box>
                    <Typography
                      variant="h4"
                      color="textPrimary"
                      style={{ fontWeight: 400 }}
                      className="items-title"
                    >
                      {cat.name}
                    </Typography>
                  </Box>
                ))}
              <Hidden mdDown>
                <Buttons
                  messageId={
                    <>
                      <FormattedMessage {...messages.allProductCatalogs} />
                      <SvgIcon
                        component={AngleRightIcon16}
                        viewBox="0 0 16 16"
                        color="primary"
                        style={{ fontSize: '1rem', marginLeft: 5 }}
                      />
                    </>
                  }
                  className={classes.moreButton}
                  onClick={handleAllClick}
                />
              </Hidden>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
CategoryEntryContent.defaultProps = {
  center: false,
};
CategoryEntryContent.propTypes = {
  onClick: PropTypes.func,
  center: PropTypes.bool,
  onClickAll: PropTypes.func,
};

const CategoryEntry = props => {
  const classes = useStyles();
  const matchLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

  return (
    <>
      <Box className={classes.top}>
        <Typography variant={matchLg ? 'h1' : 'h2'}>
          <FormattedMessage {...messages.productCategories} />
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p" className={classes.desc}>
          <FormattedMessage {...messages.allYouNeedIsHere} />
        </Typography>
        <Box mt={{ xs: 1, lg: 3 }}>
          <Buttons
            messageId={
              <Box
                display="flex"
                flexDirection="column"
                fontSize={{ xs: '0.75rem', lg: '0.875rem' }}
              >
                <Typography variant="inherit">
                  <FormattedMessage {...messages.viewAll} />
                </Typography>
                <Divider className={classes.divider} />
              </Box>
            }
            color="primary"
            size="small"
            fullWidth
            route={routes.catalogEmpty}
          />
        </Box>
      </Box>
      <CategoryEntryContent {...props} center />
    </>
  );
};

export default CategoryEntry;
