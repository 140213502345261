import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormItem = ({ name, rules, defaultValue, children, ...rest }) => {
  const { control } = useFormContext();
  const {
    field: { ref, ...fieldProps },
    fieldState,
  } = useController({
    name,
    control,
    rules: { ...rules },
    defaultValue: defaultValue || false,
  });
  return (
    <FormControl variant="outlined" fullWidth error={!!fieldState.error} {...rest}>
      {children?.(fieldProps, fieldState)}
      {!!fieldState.error && <FormHelperText>{fieldState?.error?.message}</FormHelperText>}
    </FormControl>
  );
};

FormItem.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  children: PropTypes.func,
};

export default FormItem;
