import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import processWeChatPayParameters from 'utils/WeChat/processWeChatPayParameters';
import routes from 'utils/routes';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetCart } from 'containers/CartProvider/slices';
import { toggleMiniCart } from 'containers/App/slices';
import { useIntl } from 'react-intl';
import { getOrder } from 'containers/OrderDetailPage/queries';
import appMessages from 'containers/App/messages';
import { AVAILABLE_PAYMENT_METHODS } from './constants';

export const createCheckoutQuote = () => request('POST', '/carts/mine');
export const getCheckoutQuote = () => request('GET', '/carts/mine');
export const addToCheckoutQuote = payload => request('POST', '/carts/mine/items', payload);
export const fetchWeChatQrPayParamaters = (id, vendorCode, isOrder = true, payload = {}) =>
  request(
    'GET',
    `/vendor/wechatpay/getqrcode/${id}/?quoteIdIsOrderId=${isOrder.toString()}&vendorCode=${vendorCode}`,
    payload,
  );
export const fetchAlipayQrPayParamaters = (quoteId, payload) =>
  request('GET', `/alipay/qrcode/quote/${quoteId}`, payload);
export const fetchChinapayFormParamaters = (quoteId, payload) =>
  request('GET', `/chinapay/payment_params/quote_id/${quoteId}/`, payload);
export const fetchChinapayFormActionUrl = payload =>
  request('GET', '/chinapay/front/payment', payload);
export const moveToCheckoutQuote = payload =>
  request('POST', '/partial-checkout/checkout/add', payload);
export const clearCheckoutQuote = () => request('PUT', '/partial-checkout/checkout/clear');

export const estimateShippingCosts = payload =>
  request('POST', '/carts/mine/estimate-shipping-methods-by-address-id', payload);

export const addShippingInformation = payload =>
  request('POST', '/carts/mine/shipping-information', payload);
export const addBillingInformation = payload =>
  request('POST', '/carts/mine/billing-address', payload);
export const getCheckoutTotals = () => request('GET', '/carts/mine/totals');
export const placeOrder = payload => request('PUT', '/carts/mine/order', payload);
export const fetchWeChatPayParamaters = (id, vendorCode, isOrder = true, payload = {}) =>
  request(
    'GET',
    `/vendor/wechatpay/geturl/${id}/?quoteIdIsOrderId=${isOrder.toString()}&vendorCode=${vendorCode}`,
    payload,
  );
export const checkPoints = () => request('GET', '/loyalty/use-points');
export const deployPoints = () => request('PUT', '/loyalty/use-points');
export const removePoints = () => request('DELETE', '/loyalty/use-points');
export const getPaymentMethods = cartId => request('GET', `/carts/${cartId}/payment-methods`);
export const getMinePaymentMethods = () => request('GET', '/carts/mine/payment-methods');
export const getMineShippingMethods = () => request('GET', '/carts/mine/shipping-methods');
export const getSelectedPaymentMethod = () => request('GET', '/carts/mine/selected-payment-method');

export const getLoyaltyPoints = () => request('GET', '/loyalty/points-payout');

// eslint-disable-next-line import/prefer-default-export
export const useGetLoyaltyPoints = () =>
  useQuery('loyalty.pointsPayout', () => getLoyaltyPoints(), querySetting);
export const useDeployPoints = () => useMutation(() => deployPoints());
export const useRemovePoints = () => useMutation(() => removePoints());
export const useToggleUserCredit = () =>
  useMutation(toggle => (toggle ? deployPoints() : removePoints()));
export const useMoveToCheckoutQuote = () => useMutation(payload => moveToCheckoutQuote(payload));
export const useClearCheckoutQuote = () => useMutation(() => clearCheckoutQuote());
export const useAddToCheckoutQuote = () => useMutation(payload => addToCheckoutQuote(payload));

export const useGetCheckoutQuote = () => {
  const history = useHistory();
  return useMutation(() => getCheckoutQuote(), {
    onSuccess: quote => {
      if (isEmpty(quote.items) && window.location.pathname === routes.checkout()) {
        history.push(routes.dashboard);
      }
    },
  });
};

export const useCheckPoints = () => useMutation(() => checkPoints());

export const useGetCheckoutQuoteQuery = () => {
  const history = useHistory();
  return useQuery('checkout.quote', () => getCheckoutQuote(), {
    ...querySetting,
    cacheTime: 0,
    onSuccess: quote => {
      if (isEmpty(quote.items) && window.location.pathname === routes.checkout()) {
        history.push(routes.dashboard);
      }
    },
  });
};

export const useGetCheckoutTotals = () =>
  useQuery(['checkout.totals'], () => getCheckoutTotals(), querySetting);

export const useGetPaymentMethods = cartId =>
  useQuery(
    cartId && ['checkout.paymentMethods', cartId],
    () => getPaymentMethods(cartId),
    querySetting,
  );

export const useGetMinePaymentMethods = () =>
  useQuery('checkout.minePaymentMethods', () => getMinePaymentMethods(), querySetting);

export const useGetAvailableMinePaymentMethods = () => {
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState();
  const { data: methods, ...other } = useGetMinePaymentMethods();
  useEffect(() => {
    if (methods) {
      const availableMethods = methods?.filter(m => AVAILABLE_PAYMENT_METHODS.includes(m.code));
      setAvailablePaymentMethods(availableMethods);
    }
  }, [methods]);
  return { data: availablePaymentMethods, ...other };
};

export const useSelectShippingAddress = () =>
  useMutation(payload => estimateShippingCosts(payload), {
    mutationKey: 'checkout.shippingMethod',
  });
export const useAddShippingInformation = () =>
  useMutation(payload => addShippingInformation(payload), {
    mutationKey: 'checkout.addShippingInformationQuery',
  });
export const useProcessWeChatPayParameters = () =>
  useMutation(({ wechatpayParameters, orderId }) =>
    processWeChatPayParameters(wechatpayParameters, orderId),
  );

export const useGetSelectedPaymentMethod = () =>
  useQuery('checkout.selectedPaymentMethod', () => getSelectedPaymentMethod(), querySetting);

export const usePlaceOrder = () => {
  const { data: availablePaymentMethods } = useGetAvailableMinePaymentMethods();
  const { data: totals } = useGetCheckoutTotals();

  const dispatch = useDispatch();

  return useMutation(
    async () => {
      const hasWCP = availablePaymentMethods.some(m => m.code === 'wechatpay');
      const hasFree = availablePaymentMethods.some(m => m.code === 'free');
      let method = 'wechatpay';
      if (totals.grand_total === 0) {
        method = hasFree ? 'free' : 'cashondelivery';
      } else if (totals.grand_total) {
        method = hasWCP ? 'wechatpay' : 'cashondelivery';
      }
      return placeOrder({ paymentMethod: { method } });
    },
    {
      onSuccess: () => {
        dispatch(resetCart());
      },
    },
  );
};

export const usePayment = () => {
  const notificationSnackbar = useNotificationSnackbar();
  const intl = useIntl();

  return useMutation(async ({ orderId, vendorCode, paymentMethod, quoteId }) => {
    if (paymentMethod === 'wechatpay') {
      if (!vendorCode) {
        const order = await getOrder(orderId);
        // eslint-disable-next-line no-param-reassign
        vendorCode = order?.extension_attributes?.vendor_code;
      }
      if (determineEnvironment()) {
        const wechatpayParameters = await fetchWeChatPayParamaters(orderId, vendorCode);
        return processWeChatPayParameters(wechatpayParameters, orderId)
          .then(() => {})
          .catch(() => {
            // Notification - Something went wrong
            const message = 'Unknown exception error';
            notificationSnackbar(
              `${message}: ${JSON.stringify(wechatpayParameters)}, ${orderId}`,
              'error',
            );
            throw new Error(message);
          });
      }
      const wechatpayParameters = await fetchWeChatQrPayParamaters(orderId, vendorCode).then(
        res => {
          if (res?.[0] === 'Ok') {
            return res;
          }
          notificationSnackbar(res?.[1], 'error');

          throw new Error(res?.[1]);
        },
      );
      return wechatpayParameters;
    }
    if (paymentMethod === 'alipay') {
      const alipayParameters = await fetchAlipayQrPayParamaters(quoteId);
      return alipayParameters;
    }
    if (paymentMethod === 'chinapay') {
      const chinapayParameters = await fetchChinapayFormParamaters(quoteId);
      const ChinapayFormActionUrl = await fetchChinapayFormActionUrl();
      return [chinapayParameters, ChinapayFormActionUrl];
    }

    const message = intl.formatMessage(appMessages.noPaymentMethods);
    notificationSnackbar(message, 'error');
    throw message;
  });
};

export const useOthersBuy = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return useMutation(async payload => {
    const cartItems = payload.items ? payload.items : [];
    const itemIds = cartItems.map(item => item.item_id);
    if (isEmpty(cartItems)) {
      return;
    }
    if (!isEmpty(itemIds)) {
      await moveToCheckoutQuote({ itemIds });
      history.push(routes.checkout());
      dispatch(toggleMiniCart(false));
    }
  });
};
