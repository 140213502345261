import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { getUrlParams } from 'utils/helpers';
import isEmpty from 'lodash/isEmpty';
import { setAuthorization } from 'utils/Auth';

if (determineEnvironment()) {
  const { token, source } = getUrlParams();

  if (source === 'weapp' && !isEmpty(token)) {
    setAuthorization(token);
    window.location.href = `/checkout?source=${source}`;
  }
}
