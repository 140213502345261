/**
 * @author zhengji.su
 * @description PageContainer
 */

import NavigationBar from 'components/NavigationBar/index';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backItem: {
    marginLeft: 0,
  },
}));

const PageContainer = ({ onGoBack, icon, children, ...other }) => {
  const classes = useStyles();

  return (
    <>
      <NavigationBar onClick={onGoBack} classes={{ backItem: classes.backItem }} {...other} />
      {children}
    </>
  );
};

PageContainer.propTypes = {
  onGoBack: PropTypes.func,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default PageContainer;
