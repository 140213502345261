/**
 *
 * CenteredDialog
 * @param {bool} open (open dialog)
 * @param {node} children - include content of the modal
 *
 * @return {node} Returns CenteredDialog, all styling managed by Material UI Theme and classes (root and paper)
 *
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
function CenteredDialog({ children, open, ...other }) {
  return (
    <Dialog maxWidth="md" fullWidth open={open} TransitionComponent={Transition} {...other}>
      {children}
    </Dialog>
  );
}

CenteredDialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
};

export default CenteredDialog;
