/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { forwardRef, useEffect, useRef } from 'react';
import cx from 'clsx';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
// import styles from './ParallaxElement.module.scss';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const ParallaxElement = forwardRef(
  ({ className, percentage, start, end, children, ...rest }, ref) => {
    const mergedClasses = cx(
      //   styles.ParallaxElement,
      'u-parallax-element',
      className,
    );

    const parallaxElements = useRef(null);

    useEffect(() => {
      gsap.to(parallaxElements.current, {
        yPercent: percentage,
        duration: 1,
        scrollTrigger: {
          trigger: parallaxElements.current || '',
          scrub: 0.6,
          start: start || 'top bottom',
          end: end || '+=200%',
        },
      });
    }, [start, end, percentage, parallaxElements]);

    return (
      <>
        {children ? (
          <div ref={parallaxElements} className={mergedClasses} {...rest}>
            {children}
          </div>
        ) : (
          <div ref={parallaxElements} className={mergedClasses} {...rest} />
        )}
      </>
    );
  },
);
export default ParallaxElement;
