/**
 * @author zhengji.su
 * @description Catalog
 */

import { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
// import { AnimationShowElement } from 'components/Parallax';
// eslint-disable-next-line import/named
import { CategoryEntryContent } from 'containers/HomePage/components/CategoryEntry';
import { useHistory } from 'react-router-dom';
import routes from 'utils/routes';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    overflow: 'hidden',
    padding: theme.spacing(3.25),
    borderRadius: 8,
    boxShadow: '0 0 16px 0 rgba(0,0,0,0.08)',
    cursor: 'pointer',

    '& .content': {
      display: 'block !important',
      overflow: 'initial',
    },
  },
  image: {
    overflow: 'hidden',
    borderRadius: 8,
    border: `2px solid ${theme.palette.primary.peach}`,
  },
  itemsRoot: {
    '& .itemsContainer': {
      alignItems: 'flex-end',
    },
  },
  moreButton: {
    padding: 0,
    marginLeft: theme.spacing(7.5),
    minHeight: 'auto',
    height: 'auto',
  },
}));

const Catalog = forwardRef(({ className, setVisible, ...other }, ref) => {
  const classes = useStyles();
  const history = useHistory();

  const onBackdropClose = useCallback(() => setVisible(false), []);
  const handleClick = () => {
    history.push(routes.catalogEmpty);
    onBackdropClose();
  };

  return (
    <Box
      className={clsx(classes.container, className)}
      bgcolor="background.white"
      ref={ref}
      {...other}
    >
      <CategoryEntryContent
        classes={{ moreButton: classes.moreButton, root: classes.itemsRoot }}
        onClick={() => setVisible(false)}
        onClickAll={handleClick}
      />
    </Box>
  );
});

Catalog.propTypes = {
  setVisible: PropTypes.func,
  className: PropTypes.string,
};

export default Catalog;
