/**
 *
 * EnvRenderer
 *
 */

import { memo } from 'react';
import PropTypes from 'prop-types';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { detectBrowser } from 'utils/helpers';

function EnvRenderer({ hide, env, children }) {
  if (env === 'wechat-mp') {
    if (determineEnvironment() && hide) return <></>;
    if (!determineEnvironment() && !hide) return <></>;
  }

  if (env === 'wechat-web') {
    const isWeChat = detectBrowser('wechat');
    if (isWeChat && hide) return <></>;
    if (!isWeChat && !hide) return <></>;
  }

  if (env === 'web') {
    const isWeChat = detectBrowser('wechat');
    if (!isWeChat && !determineEnvironment() && hide) return <></>;
    if ((isWeChat || determineEnvironment()) && !hide) return <></>;
  }

  // TODO - Add remaining environments
  // case alipay-mp
  // case alipay-web

  return children;
}

EnvRenderer.defaultProps = {
  hide: false,
};

EnvRenderer.propTypes = {
  hide: PropTypes.bool,
  env: PropTypes.oneOf(['wechat-mp', 'wechat-web', 'alipay-mp', 'alipay-web', 'web']).isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(EnvRenderer);
