import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Buttons from 'components/Buttons/Buttons';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { headNavs } from 'components/Header/config';
import useShouldRenderMenu from 'utils/Hooks/useRenderContent';
import { useHistory } from 'react-router-dom';
import { setHeaderChildren, selectHeaderChildrenNode } from 'containers/App/slices';
import { getPathname, setPathname } from 'utils/Auth';
import Transition from 'components/Animation/Transition';
import useStore from 'containers/StoreProvider/hooks/useStore';
import Catalog from './components/Catalog';
import HeaderTools from './components/HeaderTools';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    top: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    maxWidth: 1408,
    width: 'auto',
    margin: '0 auto',
    borderRadius: 8,
    boxShadow: '0 0 16px rgba(0,0,0,0.08)',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    bottom: 0,
    height: 72,
  },
  logo: {
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(0, 2),
    cursor: 'pointer',
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
  },
  divider: {
    height: 24,
    backgroundColor: theme.palette.background.darkPeach,
  },
  rightContent: {
    width: 'auto',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { toggleStoreVisible } = useStore();
  const headerChildrenNode = useSelector(selectHeaderChildrenNode);
  const history = useHistory();
  const currentPathname = getPathname();
  const shouldRenderMenu = useShouldRenderMenu();
  const { location: { pathname } = {} } = history;

  const [visible, setVisible] = useState(false);

  const cataRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (cataRef.current) {
      const io = new MutationObserver(res => {
        console.log(res, 66666);
      });
      io.observe(cataRef.current, { attributes: true });
    }
  }, []);

  useEffect(() => {
    if (pathname !== currentPathname) {
      if (headerChildrenNode) dispatch(setHeaderChildren({ children: null }));
      setPathname(pathname);
    }
  }, [pathname]);

  const handleMouse = type => setVisible(type === 'catalog');

  const handleClick = options => {
    if (options.id === 'catalog') {
      setVisible(false);
      history.push(options.url);
    } else {
      history.push(options.url);
    }
  };

  const handleToggleStore = e => {
    if (e) {
      return;
    }
    if (!e) {
      toggleStoreVisible(false);
      return;
    }
    const domRect = headerRef.current.getBoundingClientRect();
    const popoverProps = {
      position: {
        y: domRect.bottom + 16,
      },
      // anchorEl: event.currentTarget,
      anchorReference: 'anchorPosition',
      anchorPosition: { left: domRect.right - 420, top: domRect.bottom + 16 },
      anchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    };
    toggleStoreVisible(true, {}, popoverProps);
  };

  const itemsButton = options => (
    <Box key={options.id} onMouseEnter={() => handleMouse(options.id)}>
      <Buttons
        title={options.title}
        messageId={options.messageId}
        className={classes[options.className]}
        onClick={() => handleClick(options)}
      />
    </Box>
  );

  return (
    <AppBar position="fixed" color="default" className={classes.root} ref={headerRef}>
      <Toolbar className={classes.toolbar}>
        {headerChildrenNode ? (
          <Box width="100%" className={classes.centerContainer}>
            {itemsButton(headNavs[0])}
            {headerChildrenNode}
          </Box>
        ) : (
          <Box width="100%" className={classes.centerContainer}>
            <Box className={classes.centerContainer}>
              {headNavs.slice(0, shouldRenderMenu ? undefined : 1).map(item => itemsButton(item))}
            </Box>
            {shouldRenderMenu && (
              <Grid container justifyContent="flex-end" className={classes.rightContent}>
                <HeaderTools onClickStore={handleToggleStore} />
              </Grid>
            )}
          </Box>
        )}
      </Toolbar>
      <Transition visible={visible} onBackdropClose={() => setVisible(false)}>
        <Catalog setVisible={setVisible} />
      </Transition>
    </AppBar>
  );
};

export default Header;
