/* eslint-disable */
/*
* Copyright © TMO Group. All rights reserved.
* @package: {PACKAGE NAME}
* @subpackage: {SUBPACKAGE NAME}
* @description:
* @author: tmogroup.asia
*/
import * as React from 'react';
import clsx from 'clsx';
import RcCascader from 'rc-cascader';
import omit from 'lodash/omit';
import './index.css';
import SvgIcon from '@material-ui/core/SvgIcon';
import { AngleLeftIcon16, AngleRightIcon16, ProcessingIcon} from 'components/Icons';
const LeftOutlined = () => <SvgIcon component={AngleLeftIcon16} viewBox="0 0 16 16" className="fiticon" />;
const RightOutlined = () => <SvgIcon component={AngleRightIcon16} viewBox="0 0 16 16" className="fiticon" />;
const RedoOutlined = () => <SvgIcon component={ProcessingIcon} viewBox="0 0 16 16" className="fiticon"/>;

const getTransitionName = (rootPrefixCls, motion, transitionName) => {
  if (transitionName !== undefined) {
    return transitionName;
  }
  return `${rootPrefixCls}-${motion}`;
};
export { getTransitionName };

const defaultGetPrefixCls = (suffixCls, customizePrefixCls) => {
  if (customizePrefixCls) { return customizePrefixCls; }
  return suffixCls ? `fdk-${suffixCls}` : 'fdk';
};
export const ConfigContext = React.createContext({
  // We provide a default function for Context without provider
  getPrefixCls: defaultGetPrefixCls,
  renderEmpty: () => <p>loading...</p>,
});
export const ConfigConsumer = ConfigContext.Consumer;

function highlightKeyword(str, lowerKeyword, prefixCls) {
  const cells = str
    .toLowerCase()
    .split(lowerKeyword)
    .reduce((list, cur, index) => (index === 0 ? [cur] : [...list, lowerKeyword, cur]), []);
  const fillCells = [];
  let start = 0;
  cells.forEach((cell, index) => {
    const end = start + cell.length;
    let originWorld = str.slice(start, end);
    start = end;
    if (index % 2 === 1) {
      originWorld = (React.createElement('span', { className: `${prefixCls}-menu-item-keyword`, key: 'seperator' }, originWorld));
    }
    fillCells.push(originWorld);
  });
  return fillCells;
}
const defaultSearchRender = (inputValue, path, prefixCls, fieldNames) => {
  const optionList = [];
  // We do lower here to save perf
  const lower = inputValue.toLowerCase();
  path.forEach((node, index) => {
    if (index !== 0) {
      optionList.push(' / ');
    }
    let label = node[fieldNames.label];
    const type = typeof label;
    if (type === 'string' || type === 'number') {
      label = highlightKeyword(String(label), lower, prefixCls);
    }
    optionList.push(label);
  });
  return optionList;
};
const Cascader = React.forwardRef((props, ref) => {
  const {
    prefixCls: customizePrefixCls,
    size: customizeSize,
    className,
    multiple,
    bordered = true,
    transitionName,
    choiceTransitionName = '',
    dropdownClassName,
    expandIcon,
    showSearch,
    allowClear = true,
    notFoundContent,
    direction,
    getPopupContainer,
    ...rest
  } = props;

  const restProps = omit(rest, ['suffixIcon']);
  const {
    getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction: rootDirection,
    // virtual,
    // dropdownMatchSelectWidth,
  } = React.useContext(ConfigContext);
  const mergedDirection = direction || rootDirection;
  const isRtl = mergedDirection === 'rtl';
  // =================== No Found ====================
  const mergedNotFoundContent = notFoundContent || renderEmpty('Cascader');
  // ==================== Prefix =====================
  const rootPrefixCls = getPrefixCls();
  const prefixCls = getPrefixCls('select', customizePrefixCls);
  const cascaderPrefixCls = getPrefixCls('cascader', customizePrefixCls);
  // =================== Dropdown ====================

  const mergedDropdownClassName = clsx(dropdownClassName, `${cascaderPrefixCls}-dropdown`, {
    [`${cascaderPrefixCls}-dropdown-rtl`]: mergedDirection === 'rtl',
  });

  // ==================== Search =====================
  const mergedShowSearch = React.useMemo(() => {
    if (!showSearch) {
      return showSearch;
    }
    let searchConfig = {
      render: defaultSearchRender,
    };
    if (typeof showSearch === 'object') {
      searchConfig = { ...searchConfig, ...showSearch };
    }
    return searchConfig;
  }, [showSearch]);
    // ===================== Size ======================
  const size = 'large';
  const mergedSize = customizeSize || size;
  // ===================== Icon ======================
  let mergedExpandIcon = expandIcon;
  if (!expandIcon) {
    mergedExpandIcon = isRtl ? React.createElement(LeftOutlined, null) : React.createElement(RightOutlined, null);
  }
  const loadingIcon = (React.createElement('span', { className: `${prefixCls}-menu-item-loading-icon` },
    React.createElement(RedoOutlined, { spin: true })));
    // =================== Multiple ====================
  const checkable = React.useMemo(() => (multiple ? React.createElement('span', { className: `${cascaderPrefixCls}-checkbox-inner` }) : false), [multiple]);
  // ===================== Icons =====================
  const { suffixIcon = null, removeIcon = null, clearIcon = null } = {};
  // ==================== Render =====================
  return (React.createElement(RcCascader, {
    prefixCls,
    className: clsx(!customizePrefixCls && cascaderPrefixCls, {
      [`${prefixCls}-lg`]: mergedSize === 'large',
      [`${prefixCls}-sm`]: mergedSize === 'small',
      [`${prefixCls}-rtl`]: isRtl,
      [`${prefixCls}-borderless`]: !bordered,
    }, className),
    ...restProps,
    direction: mergedDirection,
    notFoundContent: mergedNotFoundContent,
    allowClear,
    // showSearch: mergedShowSearch,
    expandIcon: mergedExpandIcon,
    inputIcon: suffixIcon,
    removeIcon,
    clearIcon,
    loadingIcon,
    checkable,
    dropdownClassName: mergedDropdownClassName,
    dropdownPrefixCls: customizePrefixCls || cascaderPrefixCls,
    // choiceTransitionName: getTransitionName(rootPrefixCls, '', choiceTransitionName),
    // transitionName: getTransitionName(rootPrefixCls, 'slide-up', transitionName),
    getPopupContainer: getPopupContainer || getContextPopupContainer,
    ref,
  }));
});
Cascader.displayName = 'Cascader';
export default Cascader;
