/**
 * @author zhengji.su
 * @description Search
 */

import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SearchInput from 'components/FormFields/Input/SearchInput';
import Box from '@material-ui/core/Box';
import { useGetAllProducts } from 'containers/CatalogPage/queries';
import isEmpty from 'lodash/isEmpty';
import FormatMessage from 'components/FormatMessage';
import AnimationShowElement from 'components/Animation/AnimationShowElement';
import { useIntl } from 'react-intl';
import { parseMediaGalleryEntries } from 'utils/helpers';
import Picture from 'components/Picture';
import { useHistory } from 'react-router-dom';
import routes from 'utils/routes';
import cataMessages from 'containers/CatalogPage/messages';
import { transitionTime } from 'muiTheme';
import get from 'lodash/get';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.background.white,
    '& .Mui-focused': {
      borderColor: theme.palette.primary.peach,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.peach,
      },
    },
  },
  search: {
    borderRadius: 8,
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    height: 56,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.peach,
      transition: `background ${transitionTime}s`,
      '& .items-title': {
        color: theme.palette.primary.main,
        transition: `color ${transitionTime}s`,
      },
    },
  },
  image: {
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    width: 32,
    height: 32,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.peach,
  },
  menus: {
    marginTop: theme.spacing(-1),
    paddingTop: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.peach}`,
    borderRadius: '0 0 8px 8px',
    borderTop: 'none',
  },
}));

const picture = product =>
  !isEmpty(product.media_gallery_entries)
    ? parseMediaGalleryEntries(product.media_gallery_entries, product.name).file
    : '';
const nameSlice = (name = '') => (name.length > 18 ? `${name.slice(0, 18)} ...` : name);

const Search = ({ visible, setVisible }) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const { data: allProducts } = useGetAllProducts();

  const [value, setValue] = useState('');
  const [products, setProducts] = useState([]);

  const from = useRef({ y: 0, width: 100, duration: 0.2 });
  const to = useRef({ width: 360 });

  const handleChange = event => {
    const val = get(event, 'target.value', '');
    setValue(val);
    if (val) {
      const list = allProducts.filter(item => item.name.includes(val)).slice(0, 3);
      setProducts([...list]);
    } else {
      setProducts([]);
    }
  };

  const onBackdropClose = useCallback(() => {
    setVisible({ ...visible, search: false });
  }, [visible]);

  const handleClear = () => setValue('');
  const handleSearch = (e, values) => {
    if (e.keyCode === 13) {
      onBackdropClose();
      history.replace(routes.search(values));
    }
  };

  const handleJumpToDetails = option => {
    history.push(routes.product(option.url_key));
  };

  const handleJumpToAll = () => {
    onBackdropClose();
    history.push(routes.search(value));
  };

  return (
    <AnimationShowElement
      from={from.current}
      to={to.current}
      visible={visible.search}
      onBackdropClose={onBackdropClose}
      position="absolute"
      duration={0.5}
      scale={1}
    >
      <>
        <SearchInput
          pd={0}
          value={value}
          classes={{ root: classes.inputRoot }}
          onClear={handleClear}
          onChange={handleChange}
          onKeyDown={handleSearch}
          className={classes.search}
          placeholder={intl.formatMessage(cataMessages.searchPlaceholder)}
        />
        {!isEmpty(products) && (
          <Box className={classes.menus} bgcolor={theme.palette.background.white}>
            {products.map(item => (
              <Box
                key={item.id}
                className={classes.items}
                borderBottom={`1px solid ${theme.palette.disabled.background}`}
                onClick={() => handleJumpToDetails(item)}
              >
                <Box className={classes.image}>
                  <Picture
                    src={picture(item)}
                    alt={item.name}
                    title=""
                    op="fit"
                    width={32}
                    height={32}
                    params={{ width: 32, height: 32 }}
                  />
                </Box>
                <FormatMessage
                  className="items-title"
                  message={nameSlice(item.name)}
                  variant="h4"
                  color="textSecondary"
                  style={{ flex: 1 }}
                />
              </Box>
            ))}
            <Box className={classes.items} justifyContent="center" onClick={handleJumpToAll}>
              <FormatMessage
                message={messages.viewAll}
                variant="h4"
                color="primary"
                style={{ fontWeight: 700 }}
              />
            </Box>
          </Box>
        )}
      </>
    </AnimationShowElement>
  );
};

Search.propTypes = {
  visible: PropTypes.object,
  setVisible: PropTypes.func,
};

export default Search;
