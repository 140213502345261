/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const storeState = createSlice({
  name: 'store',
  initialState: {
    storeShow: false,
    storeList: [],
    filterValues: { skus: [] },
    popoverProps: {},
    mapView: null,
  },
  reducers: {
    toggleStoreShow: (state, action) => {
      state.storeShow = action.payload;
    },
    setStoreList: (state, action) => {
      state.storeList = action.payload;
    },
    setFilterValues: (state, action) => {
      state.filterValues = { ...state.filterValues, ...action.payload };
    },
    setPopoverProps: (state, action) => {
      state.popoverProps = { ...state.popoverProps, ...action.payload };
    },
    setMapView: (state, action) => {
      state.mapView = action.payload;
    },
  },
});

export const { toggleStoreShow, setStoreList, setFilterValues, setPopoverProps, setMapView } =
  storeState.actions;

export const selectStoreShow = state => state.store.storeShow;
export const selectStoreList = state => state.store.storeList;
export const selectFilterValues = state => state.store.filterValues;
export const selectPopoverProps = state => state.store.popoverProps;
export const selectMapView = state => state.store.mapView;

export default storeState.reducer;
