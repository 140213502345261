import axios from 'axios';
import store from 'store';
import * as Sentry from '@sentry/browser';
import { getAuthorization } from 'utils/Auth';

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    // Return only the data
    return response.data;
  }

  const error = new Error(response.statusText);
  error.response = response;

  Sentry.withScope(scope => {
    scope.setUser({ id: 'user' });
    scope.setTag('api.status', response.status);
    scope.setTag('api.method', response.config.method);
    scope.setTag('api.url', response.config.method);
    scope.setExtra('payload', response.config.data);
    Sentry.captureException(response.data);
  });

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} method    The method of the request, i.e. GET or POST
 * @param  {string} url       The URL we want to request
 * @param  {object} [data]    The options we want to pass to the request
 *
 * @return {object}           The response data
 */
export default function request(method, url, data) {
  const auth = getAuthorization();
  const base = process.env.API_URL;
  const version = process.env.API_VERSION;
  const storeCode = store.get('store_code');
  const apiUrl = `${base}${storeCode || 'chn_zh'}/${version}${url}`;

  return axios({
    method,
    url: apiUrl,
    data,
    withCredentials: true,
    ...(auth.type === 'user' || auth.type === 'socialmarketer'
      ? {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        }
      : { headers: { 'Content-Type': 'application/json' } }),
  }).then(checkStatus);
}
