import { useHistory } from 'react-router-dom';

const useShouldRenderMenu = () => {
  const history = useHistory();
  if (
    history.location.pathname !== '/smlogin' &&
    history.location.pathname !== '/smcustomer' &&
    history.location.pathname !== '/sminvoice' &&
    history.location.pathname !== '/checkout' &&
    history.location.pathname !== '/pay'
  ) {
    return true;
  }
  return false;
};

export default useShouldRenderMenu;
