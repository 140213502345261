/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.components.Header';

export default defineMessages({
  productCatalog: {
    id: `${scope}.productCatalog`,
    defaultMessage: 'Product',
  },
  allProduct: {
    id: `${scope}.allProduct`,
    defaultMessage: 'All Product',
  },
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'No results found',
  },
  tryAdjustingYourSearch: {
    id: `${scope}.tryAdjustingYourSearch`,
    defaultMessage: "Try adjusting your search to find what you're looking for.",
  },
  showAllResults: {
    id: `${scope}.showAllResults`,
    defaultMessage: 'Show all results for "{input}"',
  },
  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'View all',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Product name',
  },
});
