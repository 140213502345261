/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
/**
 *
 * SharingShoppingContent
 *
 */

import { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Buttons from 'components/Buttons/Buttons';
import placeholder from 'images/placeholder.png';
import { wrapText } from 'utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import { DownloadCloudIcon, LinkIcon } from 'components/Icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import swooshPng from 'images/share/swoosh.png';
import Hidden from '@material-ui/core/Hidden';
import useCopyMeToClipBoard from 'utils/Hooks/useCopyMeToClipBoard';
import { useGetQrCode } from 'containers/SharingProvider/queries';
import Picture from 'components/Picture';
import { useGetCustomer } from 'containers/UserProfile/queries';
import parseCustomerName from 'utils/User/parseCustomerName';
import PageLoader from 'components/PageLoader';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
      minHeight: 'calc(100vh - 64px)',
      paddingBottom: 'calc(102px + env(safe-area-inset-bottom))',
    },
  },
  imageWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
  },
  card: {
    backgroundColor: theme.palette.background.white,
    borderRadius: 8,
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 0 16px rgba(0,0,0,.08)',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(4),
      width: 343,
      flexShrink: 0,
    },
  },
  wave: {
    width: '100%',
    background: `linear-gradient(0deg, ${theme.palette.background.peach} 1px, transparent 0%)`,
  },
  recommend: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  actionButton: {
    textAlign: 'center',
    padding: theme.spacing(2, 0, 0),
    paddingBottom: 'calc(16px + env(safe-area-inset-bottom))',
    width: '100%',
    left: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      boxShadow: '0 0 16px rgba(0,0,0,0.08)',
      backgroundColor: theme.palette.background.white,
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
      paddingBottom: theme.spacing(1),
    },
  },
  buttonWrapper: {
    width: '33.33%',
    fontSize: '0.625rem',
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      fontSize: '0.875rem',
      color: theme.palette.primary.main,
    },
  },
  button: {
    width: 48,
    borderRadius: '50%',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
      width: 64,
      height: 64,
      marginBottom: theme.spacing(1),
    },
  },
  singleImage: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: 220,
    position: 'absolute',
    zIndex: 5,
    margin: '0 auto',
  },
}));

function SharingShoppingContent({ qrcodeId, subtitle, customerId }) {
  const intl = useIntl();
  const classes = useStyles();
  const cardRef = useRef();

  const { data: customer } = useGetCustomer();

  const copyToClipBoard = useCopyMeToClipBoard();
  const { data, isLoading, error } = useGetQrCode(`type=st&value=${qrcodeId}-${customerId}`);

  const fullName = parseCustomerName(
    customer?.firstname?.substr(0, 3),
    customer?.lastname?.substr(0, 3),
  );

  const getImageSize = image => {
    const dpr = window.devicePixelRatio;
    const aspectRatio = image.width / image.height;
    let w;
    let h;
    if (aspectRatio > 1) {
      w = Math.min(image.width / dpr, 220);
      h = w / aspectRatio;
    } else {
      h = Math.min(image.height / dpr, 220);
      w = h * aspectRatio;
    }

    return { w, h };
  };

  const createImage = (url, resize) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';

    return new Promise((resolve, reject) => {
      image.onload = () => {
        if (resize) {
          const { w, h } = getImageSize(image);
          return resolve({ image, w, h });
        }
        resolve({ image });
        return null;
      };
      image.onerror = err => {
        reject(err);
      };
      image.src = url;
    });
  };

  const drawProduct = async () => {
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');

    const dpr = window.devicePixelRatio;
    const width = cardRef.current.offsetWidth;
    const height = cardRef.current.offsetHeight;
    canvas.width = width * dpr;
    canvas.height = height * dpr;
    ctx.scale(dpr, dpr);

    // card
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);

    // card bottom
    ctx.rect(0, 254, width, height);
    ctx.fillStyle = '#FFF3E0';
    ctx.fillRect(0, 254, width, height);
    ctx.restore();

    try {
      // card wave
      ctx.restore();
      const { image: swoosh } = await createImage(swooshPng);
      ctx.drawImage(swoosh, 0, 216, width, (86 * width) / 750 + 1);

      // qrcode
      ctx.restore();
      const { image: qrcode } = await createImage(data);
      ctx.drawImage(qrcode, (width - 200) / 2, 16, 200, 200);

      // title
      ctx.font = 'bold 16px Roboto';
      ctx.fillStyle = '#090909';

      const { y } = wrapText(
        canvas,
        ctx,
        `${intl.formatMessage(messages.somebodySharedAList, { name: fullName })}`,
        16,
        276,
        width - 32,
        24,
      );

      // sub title
      ctx.textAlign = 'left';
      ctx.fillStyle = '#808080';
      ctx.font = 'normal 16px Roboto';

      wrapText(canvas, ctx, subtitle, 16, y + 32, width - 32, 24);

      ctx.restore();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const drawCanvas = async () => {
    await drawProduct();
  };

  const handleDownload = async () => {
    await drawCanvas();
    const canvas = document.getElementById('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'FitLine.png';
    link.href = url;
    link.click();
  };

  return (
    <>
      <PageLoader loading={isLoading} />
      <canvas
        id="canvas"
        style={{
          position: 'fixed',
          top: '100%',
          left: '100%',
          overflow: 'hidden',
          borderRadius: '8px',
        }}
      />
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.card} ref={cardRef}>
            <Box
              position="relative"
              width={1}
              pt={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {isLoading || error ? (
                <Picture
                  src={`${process.env.BASE_URL_APP}${placeholder}`}
                  title=""
                  alt="placeholder"
                  op="fit"
                  id="qrcode"
                  width={200}
                  height={200}
                  params={{
                    width: 200,
                    height: 200,
                  }}
                />
              ) : (
                <img
                  id="qrcode"
                  crossOrigin="anonymous"
                  src={data}
                  style={{ height: 200, width: 200 }}
                  alt="placeholder"
                />
              )}
              <img
                alt="wave"
                className={classes.wave}
                src="data:image/svg+xml,%3Csvg width='375' height='43' viewBox='0 0 375 43' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fillRule='nonzero' fill='none'%3E%3Cpath d='M375 3.515S332 0 300.67 0C143.262 0 0 35.132 0 35.132V43c7.585-1.652 130.402-25.989 253.042-25.989C318.376 16.647 375 23.2 375 23.2V3.515' fill='%23CD0039'/%3E%3Cpath d='M375 23.203v19.8H0c0-5.975 0-1.144 0 0 7.585-1.651 130.402-25.988 253.042-25.988C318.376 16.65 375 23.203 375 23.203' fill='%23FFF3E0'/%3E%3C/g%3E%3C/svg%3E"
              />
            </Box>
            <Box p={2} pt={1} bgcolor="background.peach" textAlign="left">
              <Typography variant="body1" component="p">
                <b>
                  <FormattedMessage {...messages.somebodySharedAList} values={{ name: fullName }} />
                </b>
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p">
                {subtitle}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.inviteDesc}>
            <Box mt={2}>
              <Typography variant="body1" color="textPrimary">
                <FormattedMessage {...messages.sharedListDesc} />
              </Typography>
            </Box>

            <Hidden lgUp>
              <Box className={classes.actionButton}>
                <Box className={classes.buttonWrapper}>
                  <Buttons
                    variant="contained"
                    color="primary"
                    onClick={() => handleDownload()}
                    messageId={
                      <SvgIcon
                        component={DownloadCloudIcon}
                        viewBox="0 0 24 24"
                        size="large"
                        style={{ fontSize: '1.5rem' }}
                      />
                    }
                    className={classes.button}
                  />
                  <Typography variant="h5" component="p">
                    <FormattedMessage {...messages.savePicture} />
                  </Typography>
                </Box>
                <Box className={classes.buttonWrapper}>
                  <Buttons
                    variant="contained"
                    color="primary"
                    onClick={() => copyToClipBoard(window.location.href)}
                    messageId={
                      <SvgIcon component={LinkIcon} size="large" style={{ fontSize: '1.5rem' }} />
                    }
                    className={classes.button}
                  />
                  <Typography variant="h5" component="p">
                    <FormattedMessage {...messages.copyLink} />
                  </Typography>
                </Box>
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Box>
    </>
  );
}

SharingShoppingContent.propTypes = {
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  qrcodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SharingShoppingContent;
