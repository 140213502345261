/**
 *
 * CartListItem
 *
 */

import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import appMessages from 'containers/App/messages';
import { selectLockCart } from 'containers/CheckoutPage/slices';
import cartMessages from 'containers/CartProvider/messages';
import Picture from 'components/Picture';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from '@material-ui/core/styles';
import HandleButton from 'containers/CartProvider/components/HandleButton';
import { CheckboxCheckedIcon, CheckboxIcon, MedalIcon } from 'components/Icons';
import Price from 'components/Price';
import ConfirmDiaLog from 'components/ConfirmDiaLog';
import FormatMessage from 'components/FormatMessage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Access, { useAccess } from 'components/Access';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import clsx from 'clsx';
import Buttons from 'components/Buttons/Buttons';
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon12AngleRight from 'components/Icons/icon/12-angle-right';
import useStore from 'containers/StoreProvider/hooks/useStore';
import useMount from 'utils/Hooks/updateEffect/useMount';
import useOperateCartItem from 'containers/CartProvider/hooks/useOperateCartItem';
import useStyles from '../styles/miniCartListItem';

function MiniCartListItem(props) {
  const {
    cartItem,
    // proceedingToCheckout,
    isPCDetails,
    imgSize = 62,
    border = true,
  } = props;
  const classes = useStyles(props);
  const {
    palette: { primary, text },
  } = useTheme();
  const isCartLock = useSelector(selectLockCart);
  const matchLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const { authorization } = useAccess();

  const { toggleStoreVisible } = useStore();
  const { toggleItem, selectedItems, isLoading } = useShoppingCart();
  const {
    loading,
    quantity,
    showConfirm,
    wishlistItems,
    closeConfirm,
    joinFavorites,
    removeCartItem,
    quantityAdjustment,
  } = useOperateCartItem(cartItem);

  const [PV, setPV] = useState(0);

  const isChecked = selectedItems.includes(cartItem.item_id);
  const calculatePrice = get(cartItem, 'extension_attributes.calculated_price', 0);

  useMount(() => {
    setPV(get(cartItem, 'extension_attributes.point_value', 0) / cartItem.qty);
  });

  const isFav = useMemo(() => {
    const id = cartItem?.extension_attributes?.product_id;
    return wishlistItems?.some(item => item.product_id === id);
  }, [cartItem, wishlistItems]);

  const handleToggle = () => toggleItem({ itemId: cartItem.item_id });

  const handleRemoval = async () => {
    await removeCartItem({
      complete: () => handleToggle(),
    });
  };

  const handleCheckStore = row => {
    toggleStoreVisible(true, { skus: [{ sku: row.sku, qty: row.qty }] });
  };

  const buttonRender = () => (
    <HandleButton
      initialValue={quantity}
      onChange={quantityAdjustment}
      disabled={isCartLock || isLoading}
      allowZero
      height="1.875rem"
      className={classes.buttonStyle}
      minusButtonStyle={{ color: text.primary }}
      plusButtonStyle={{ color: text.primary }}
      applyValue
    />
  );

  const stockNode = () => {
    const onlyXLeft = +(cartItem?.extension_attributes?.qty_left || 0);
    const outOfStock =
      cartItem?.extension_attributes?.stock_status !== 'IN_STOCK' || onlyXLeft < quantity;

    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        {outOfStock && (
          <FormatMessage
            className={classes.smallSize}
            message={cartMessages.stockInsufficient}
            variant="caption"
            color="primary"
            style={{ marginRight: matchLg && 8 }}
          />
        )}
        {onlyXLeft ? (
          <FormatMessage
            className={classes.smallSize}
            message={{ ...cartMessages.stock, values: { total: onlyXLeft } }}
            variant="caption"
            color="textSecondary"
          />
        ) : null}
        {isPCDetails && outOfStock && (
          <Buttons
            type="text"
            messageId={
              <FormatMessage
                className={classes.smallSize}
                message="切换商店"
                variant="caption"
                color="textPrimary"
              />
            }
            className={classes.checkStore}
            icon={
              <SvgIcon
                component={Icon12AngleRight}
                color="primary"
                viewBox="0 -2 16 16"
                style={{ marginLeft: 8 }}
              />
            }
            onClick={() => handleCheckStore(cartItem)}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <Box
        className={clsx(classes.container, {
          [classes.pcContainer]: isPCDetails,
        })}
        borderBottom={isPCDetails || !border ? null : `1px solid ${primary.peach}`}
      >
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={isChecked}
            onChange={handleToggle}
            classes={{ root: classes.checkRoot }}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
          />
          <Box
            width={imgSize}
            height={imgSize}
            minHeight={imgSize}
            minWidth={imgSize}
            bgcolor="background.default"
            mx={1}
            className={clsx(classes.comImg, {
              [classes.PCDetailsComImg]: isPCDetails,
            })}
          >
            <Picture
              src={cartItem.extension_attributes.main_image_url}
              alt={cartItem.name}
              title=""
              op="fit"
              params={{
                width: imgSize,
                height: imgSize,
              }}
            />
          </Box>
        </Box>
        <Box width="100%" className={isPCDetails ? classes.pcNotice : classes.notice}>
          <Box className="notice-content">
            <FormatMessage message={cartItem.name} variant="h5" className={classes.cartItemName} />
            <Box display="flex" alignItems="center">
              <Access accessible={false && authorization([3, 4])}>
                {PV > 0 && (
                  <Box fontSize="10px" mr={{ xm: 0.5, lg: 1 }}>
                    <Box display="flex" alignItems="center" color={primary.main}>
                      <MedalIcon
                        fill={primary.silver}
                        secondaryFill={primary.main}
                        width={13}
                        height={13}
                      />
                      <FormatMessage
                        className={classes.smallSize}
                        variant="caption"
                        color="primary"
                        message={{ ...appMessages.pvc, values: { total: PV } }}
                      />
                    </Box>
                  </Box>
                )}
              </Access>
              {isPCDetails ? null : stockNode()}
            </Box>
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                <Box display="flex" alignItems="flex-end">
                  <Price
                    originalPrice={cartItem.price}
                    price={calculatePrice}
                    color="primary"
                    size={matchLg ? 16 : 12}
                    secondarySize="0.625rem"
                    style={{ lineHeight: 1 }}
                  />
                </Box>
                <Box>{isPCDetails ? null : buttonRender()}</Box>
              </Box>
            </Box>
          </Box>
          {isPCDetails && (
            <Box className={classes.PCButtons}>
              <Box display="flex" justifyContent="center">
                {buttonRender()}
              </Box>
              <Box mt={0.5}>{stockNode()}</Box>
            </Box>
          )}
        </Box>
      </Box>

      <ConfirmDiaLog
        width={343}
        cartName={cartItem.name}
        open={showConfirm}
        title={
          <FormatMessage
            message={appMessages.confirmRemovalOfItem}
            className={classes.dialogTitle}
          />
        }
        onClose={closeConfirm}
        onConfirm={handleRemoval}
        loading={loading}
        maxWidth={matchLg ? 'xs' : 'sm'}
        onAssistant={isFav ? null : () => joinFavorites(cartItem)}
      />
    </>
  );
}

MiniCartListItem.propTypes = {
  cartItem: PropTypes.object.isRequired,
  // proceedingToCheckout: PropTypes.func,
  isPCDetails: PropTypes.bool,
  imgSize: PropTypes.number,
  border: PropTypes.bool,
};

export default MiniCartListItem;
