import { useMemo, useState } from 'react';
import useStore from 'containers/StoreProvider/hooks/useStore';

const useFindStore = () => {
  const { storeList = [], cityList = [] } = useStore();
  const [filterValues, setFilterValues] = useState({});

  const onSearch = ({ keyword, ...other }) => {
    if (keyword !== undefined) {
      setFilterValues({ keyword });
    } else {
      setFilterValues(state => ({ ...state, ...other }));
    }
  };

  const onReset = () => {
    setFilterValues({});
  };

  const cityOptions = useMemo(() => ['全国', ...cityList], [cityList]);
  const filteredStoreList = useMemo(
    () =>
      storeList
        .filter(s => s.name.includes(filterValues.keyword || ''))
        .filter(s => s.city.includes(filterValues.city || ''))
        .filter(s => {
          if (filterValues.storeType === '旗舰店') {
            return s.extension_attributes?.flagship;
          }
          if (filterValues.storeType === 'DSC') {
            return !s.extension_attributes?.flagship;
          }
          return true;
        }),
    [storeList, filterValues],
  );

  return {
    storeList,
    filteredStoreList,
    filterValues,
    cityOptions,
    onSearch,
    onReset,
  };
};

export default useFindStore;
