/**
 * Buttons component bit.dev
 *
 * @param {bool} loading loading (trigger disabled and loading progress icon)
 * @param {object|string} messageId button name (pass the string or <FormatedMessage ...>)
 * @param {string} color control the color of the Button
 * @param {...} other pass the necessary event (Link or onSubmit)
 *
 * @return {node} Returns Buttons, all styling managed by Material UI Theme
 */

import { useState, memo, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

function Buttons(props) {
  const {
    variant,
    loading,
    messageId,
    icon,
    route,
    className,
    onClick,
    effect = true,
    hover,
    disabled,
    children,
    ...others
  } = props;

  const [customClassName, setCustomClassName] = useState([]);
  const isIconButton = messageId?.props?.viewBox;

  const handleClick = useCallback(
    event => {
      if (onClick) onClick(event);
      // if (effect) setCustomClassName([...customClassName, 'active']);
    },
    [onClick],
  );

  useEffect(() => {
    if (isIconButton && effect) setCustomClassName([...customClassName, 'iconButton']);
  }, [isIconButton]);

  // useEffect(() => {
  //   if (hover) setCustomClassName([...customClassName, 'hover']);
  // }, [hover]);

  // useEffect(() => {
  //   if (disabled) {
  //     const filterClassName = customClassName.filter(item => item !== 'active');
  //     setCustomClassName([...filterClassName]);
  //   }
  // }, [disabled]);

  return (
    <Button
      to={route}
      disabled={loading || disabled}
      component={route && Link}
      endIcon={
        icon ||
        (loading && <CircularProgress size={18} color="inherit" style={{ marginLeft: 8 }} />)
      }
      variant={variant}
      type={variant === 'contained' ? 'submit' : 'button'}
      fullWidth={variant === 'contained'}
      className={clsx([className, ...new Set(customClassName)])}
      onClick={event => handleClick(event)}
      {...others}
    >
      {messageId ?? children}
    </Button>
  );
}

Buttons.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.object,
  messageId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.any,
  onClick: PropTypes.func,
  effect: PropTypes.bool,
  hover: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(Buttons);
