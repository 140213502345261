import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AngleLeftIcon16, StoreStarIcon, SearchIcon } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';
import { useIntl, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import routes from 'utils/routes';
import SearchInput from './SearchInput';
import SelectPicker from './SelectPicker';
import messages from '../messages';
import { STORE_TYPES } from '../constants';

const useStyles = makeStyles(theme => ({
  locationButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
  backButton: {
    // marginLeft: theme.spacing(-1),
  },
  join: {
    minHeight: 'auto',
    height: 36,
    marginLeft: 'auto',
  },
  optionSelect: {
    marginRight: theme.spacing(3),
  },
  divider: {
    backgroundColor: theme.palette.background.darkPeach,
  },
  searchButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(-1.5),
    color: theme.palette.text.primary,
  },
}));

const StoreFilter = ({ list, filterValues, cityList, onSearch, onReset, onBack }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [openSearch, setOpenSearch] = useState(false);

  const handleChangeCity = city => {
    if (city === '全国') {
      onSearch({ city: '' });
    } else {
      onSearch({ city });
    }
  };

  const handleChangeStoreType = storeType => {
    if (storeType === '全国') {
      onSearch({ storeType: '' });
    } else {
      onSearch({ storeType });
    }
  };

  const handleClear = () => {
    onSearch({ keyword: '' });
  };

  const handleSearch = keyword => {
    onSearch({ keyword });
  };

  const toggleSearch = visible => {
    setOpenSearch(visible);
    if (visible) {
      onReset();
    }
  };

  const storeTypes = STORE_TYPES;

  const renderFilterContent = () => (
    <Box position="absolute" width="100%" display="flex" alignItems="center" mb={-1}>
      <SelectPicker
        value={filterValues.city || ''}
        renderValue={selected => (
          <Typography variant="body2">
            <b>
              {!selected || selected === '全国' ? intl.formatMessage(messages.allCity) : selected}
            </b>
          </Typography>
        )}
        placeholder={intl.formatMessage(messages.allCity)}
        onChange={handleChangeCity}
        options={cityList}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        className={classes.optionSelect}
      />
      <SelectPicker
        value={filterValues.storeType || ''}
        renderValue={selected => (
          <Typography variant="body2">
            <b>
              {!selected || selected === '所有店铺'
                ? intl.formatMessage(messages.allStore)
                : selected}
            </b>
          </Typography>
        )}
        placeholder={<FormattedMessage {...messages.allStore} />}
        onChange={handleChangeStoreType}
        options={storeTypes}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        className={classes.optionSelect}
      />
      <IconButton onClick={() => toggleSearch(true)} className={classes.searchButton}>
        <SvgIcon component={SearchIcon} viewBox="0 0 16 16" style={{ fontSize: '1rem' }} />
      </IconButton>
    </Box>
  );

  return (
    <Box pt={1.5} px={3}>
      <Buttons
        size="medium"
        messageId={<FormattedMessage {...messages.backToHome} />}
        startIcon={
          <SvgIcon
            component={AngleLeftIcon16}
            fontSize="small"
            style={{ fontSize: '1rem' }}
            viewBox="0 0 16 16"
            color="primary"
          />
        }
        className={classes.backButton}
        onClick={onBack}
      />
      <Box display="flex" alignItems="center" pt={1} pb={3}>
        <Typography variant="h1">
          <FormattedMessage {...messages.stores} values={{ count: list.length }} />
        </Typography>
        <Buttons
          color="primary"
          variant="contained"
          messageId={<FormattedMessage {...messages.joinUs} />}
          fullWidth={false}
          startIcon={
            <SvgIcon
              component={StoreStarIcon}
              fontSize="small"
              style={{ fontSize: '1rem' }}
              viewBox="0 0 24 24"
            />
          }
          route={routes.joinUs}
          className={classes.join}
        />
      </Box>
      <Box position="relative" height={56}>
        <Fade in={!openSearch} mountOnEnter unmountOnExit>
          {renderFilterContent()}
        </Fade>
        <Slide in={openSearch} direction="left" mountOnEnter unmountOnExit>
          <Box position="absolute" width="100%">
            <SearchInput
              name="search"
              value={filterValues.keyword || ''}
              placeholder={intl.formatMessage(messages.searchKeyword)}
              onChange={handleSearch}
              onClear={handleClear}
              autoFocus={false}
              onBlur={() => setOpenSearch(false)}
            />
          </Box>
        </Slide>
      </Box>
      <Box pt={3}>
        <Divider className={classes.divider} />
      </Box>
    </Box>
  );
};

StoreFilter.propTypes = {
  list: PropTypes.array,
  filterValues: PropTypes.object,
  cityList: PropTypes.array,
  onBack: PropTypes.func,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
};

export default StoreFilter;
