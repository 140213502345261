/**
 * @author zhengji.su
 * @description PopoverElement
 */

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import Popover from '@material-ui/core/Popover';

const PopoverElement = ({
  children,
  anchorOrigin,
  transformOrigin,
  onClose,
  anchorEl,
  ...other
}) => {
  const handlePopoverClose = () => onClose?.();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 72,
        horizontal: 'center',
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
        ...transformOrigin,
      }}
      {...other}
    >
      {children}
    </Popover>
  );
};

PopoverElement.propTypes = {
  children: PropTypes.node,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(PopoverElement);
