/**
 * @author zhengji.su
 * @description Total
 */

import PropTypes from 'prop-types';
import FormatMessage from 'components/FormatMessage';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const Total = ({ total, title, variant = 'caption', ...other }) => (
  <Box>
    <FormatMessage
      message={title}
      component="span"
      variant={variant}
      color="textSecondary"
      {...other}
    />
    <Typography variant={variant} component="span" color="primary" style={{ fontWeight: 700 }}>
      {total}
    </Typography>
  </Box>
);

Total.propTypes = {
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.string,
};

export default Total;
