import { createSlice } from '@reduxjs/toolkit';

export const catalogState = createSlice({
  name: 'catalog',
  initialState: {
    cartBallLocation: {},
  },
  reducers: {
    changeCartBallLocation: (state, action) => {
      state.cartBallLocation = action.payload;
    },
  },
});

export const { changeCartBallLocation } = catalogState.actions;

export const getCartBallLocation = state => state.catalog.cartBallLocation;

export default catalogState.reducer;
