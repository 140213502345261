import { useQuery, useMutation } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';

export const getOrder = orderId => request('GET', `/customer/orders/${orderId}`);
export const cancelOrder = orderId => request('POST', `/order/${orderId}/cancel`);
export const getShipments = orderId => request('GET', `/customer/orders/${orderId}/shipments`);
export const getPaymentMethods = orderId => request('GET', `/order/${orderId}/payment-methods`);
export const getTrackingInfo = (logisticCode, trackingNumber) =>
  request('GET', `/kuaidi/track/${logisticCode}/${trackingNumber}`);
export const reorder = payload => request('POST', '/customer/orders/reorder', payload);

export const getPickupQrCode = (orderId, payload) =>
  request('GET', `/pick_up/${orderId}/qrcode`, payload);

export const useGetOrder = (id, options = {}) =>
  useQuery(['orderPage.order', id], () => getOrder(id), {
    ...querySetting,
    enabled: !!id,
    ...options,
  });

export const useGetShipments = id =>
  useQuery(id && ['orderPage.shipments', id], () => getShipments(id), querySetting);

export const useGetPaymentMethods = id =>
  useQuery(id && ['orderPage.paymentMethods', id], () => getPaymentMethods(id), querySetting);

export const useGetTrackingInfo = ({ logisticCode, trackingNumber }) =>
  useQuery(
    logisticCode && trackingNumber && ['orderPage.trackingInfo', logisticCode + trackingNumber],
    () => getTrackingInfo(logisticCode, trackingNumber).then(res => JSON.parse(res)),
    querySetting,
  );

export const useGetTrackingInfoMutaion = () =>
  useMutation(({ logisticCode, trackingNumber }) =>
    getTrackingInfo(logisticCode, trackingNumber).then(res => JSON.parse(res)),
  );
export const useReorder = () => useMutation(payload => reorder(payload));
export const useCancelOrder = () => useMutation(id => cancelOrder(id));
// export const useRemoveMineCoupons = () => useMutation(() => removeMineCoupons());
export const useGetPickupQrCodeQuery = orderId =>
  useQuery(['orderPage.getPickupQrCode', orderId], () => getPickupQrCode(orderId), {
    ...querySetting,
    enabled: !!orderId,
  });
