/**
 *
 * SharingShoppingDialog
 *
 */

import PropTypes from 'prop-types';
import CenteredDialog from 'components/Dialogs/CenteredDialog';
import Box from '@material-ui/core/Box';
import Buttons from 'components/Buttons/Buttons';
import { makeStyles } from '@material-ui/core/styles';
import { TimesIcon, AngleLeftIcon16 } from 'components/Icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import Hidden from '@material-ui/core/Hidden';
import WholePageDialog from 'components/Dialogs/WholePageDialog';
import FormatMessage from 'components/FormatMessage';
import NavigationBar from 'components/NavigationBar';
import appMessages from 'containers/App/messages';
import SharingShoppingContent from './SharingShoppingContent';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  closeButton: {
    marginLeft: 'auto',
    minWidth: 'auto',
    marginRight: theme.spacing(-0.5),
  },
  singleImage: {
    display: 'block',
    maxWidth: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 5,
    margin: '0 auto',
  },
  wave: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    paddingBottom: theme.spacing(3),
    background: `linear-gradient(0deg, ${theme.palette.background.peach} 25px, transparent 0%)`,
  },
  logo: {
    width: 50,
    height: 38,
    position: 'absolute',
    left: 16,
    top: 16,
  },
  backItem: {
    marginLeft: theme.spacing(-1.5),
    minHeight: 40,
    minWidth: 40,
    borderRadius: '50%',
  },
}));

function SharingShoppingDialog({ open, onClose, dialogTitle, ...other }) {
  const classes = useStyles();
  return (
    <>
      <Hidden lgUp>
        <WholePageDialog open={open} setOpen={onClose}>
          <Box position="absolute" left={0} top={0} width={1}>
            <NavigationBar
              position="sticky"
              renderBackItem={() => (
                <Buttons
                  messageId={
                    <>
                      <SvgIcon component={AngleLeftIcon16} viewBox="0 0 16 16" color="inherit" />
                      <FormatMessage message={appMessages.back} />
                    </>
                  }
                  color="textSecondary"
                  className={classes.backItem}
                  onClick={onClose}
                />
              )}
              onClick={onClose}
            />
            <SharingShoppingContent {...other} />
          </Box>
        </WholePageDialog>
      </Hidden>
      <Hidden mdDown>
        <CenteredDialog open={open}>
          <Box py={5} position="relative">
            <Box display="flex" alignItems="center" mb={4} px={5}>
              <FormatMessage variant="h2" message={dialogTitle ?? messages.shareProduct} />
              <Buttons
                size="small"
                messageId={
                  <SvgIcon
                    component={TimesIcon}
                    fontSize="large"
                    style={{ fontSize: '1.5rem' }}
                    viewBox="0 0 16 16"
                  />
                }
                className={classes.closeButton}
                onClick={onClose}
              />
            </Box>
            <Box width={1} mb={2} px={8.5}>
              <SharingShoppingContent {...other} />
            </Box>
          </Box>
        </CenteredDialog>
      </Hidden>
    </>
  );
}

SharingShoppingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string,
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SharingShoppingDialog;
