import PropTypes from 'prop-types';
import { useRef, useEffect, forwardRef, useImperativeHandle, memo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMap from 'utils/Hooks/useMap';
import isEmpty from 'lodash/isEmpty';
import useScript from 'utils/Hooks/useScript';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.background.peach,
  },
}));

const StoreMap = forwardRef(({ list, onView }, ref) => {
  const classes = useStyles();

  const status = useScript(
    'https://map.qq.com/api/gljs?v=2.exp&key=7BABZ-WEVCX-ZFB4T-7F6ZB-3QROQ-STFYA',
  );

  const mapViewRef = useRef();
  const { initMap, map, setMarkers } = useMap({ onView });

  useImperativeHandle(ref, () => map);

  useEffect(() => {
    if (status === 'ready') {
      initMap(mapViewRef.current);
    }
  }, [status]);

  useEffect(() => {
    if (!isEmpty(list)) {
      setMarkers(list);
    }
  }, [list]);

  return <Box className={classes.root} ref={mapViewRef} />;
});

StoreMap.propTypes = {
  list: PropTypes.array,
  onView: PropTypes.func,
};

export default memo(StoreMap);
