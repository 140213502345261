/*
 * AddressBook Messages
 *
 * This contains all the text for the AddressBook container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AddressBook';

export default defineMessages({
  addressBook: {
    id: `${scope}.addressBook`,
    defaultMessage: 'Address Book',
  },
  youDoNotHaveAddresses: {
    id: `${scope}.youDoNotHaveAddresses`,
    defaultMessage: 'You do not have any addresses yet',
  },
  tapButtonToAddFirstAddress: {
    id: `${scope}.tapButtonToAddFirstAddress`,
    defaultMessage: 'Tap the button below to add your first address',
  },
  noAddress: {
    id: `${scope}.noAddress`,
    defaultMessage: 'You have no address yet',
  },
  addYourFirstAddress: {
    id: `${scope}.addYourFirstAddress`,
    defaultMessage: 'Add your first address',
  },
  setAsDefault: {
    id: `${scope}.setAsDefault`,
    defaultMessage: 'Set as default',
  },
  setAsDefaultAddress: {
    id: `${scope}.setAsDefaultAddress`,
    defaultMessage: 'Set as default address',
  },
  tips: {
    id: `${scope}.tips`,
    defaultMessage:
      '*Note: Fill in the identity information must be the same as the WeChat payment real-name authentication information, otherwise you will be responsible for the consequences of unable to clear customs.',
  },
  editAddress: {
    id: `${scope}.editAddress`,
    defaultMessage: 'Edit Address',
  },
  selectAddress: {
    id: `${scope}.selectAddress`,
    defaultMessage: 'Select Address',
  },
  locationPlaceholder: {
    id: `${scope}.locationPlaceholder`,
    defaultMessage: 'Region, City, District',
  },
  confirmDeleteAddress: {
    id: `${scope}.confirmDeleteAddress`,
    defaultMessage: 'Are you sure you want to delete the address?',
  },
  confirmDelete: {
    id: `${scope}.confirmDelete`,
    defaultMessage: 'Confirm Delete',
  },
  cancelDelete: {
    id: `${scope}.cancelDelete`,
    defaultMessage: 'Cancel',
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: 'Company',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  parentsHome: {
    id: `${scope}.parentsHome`,
    defaultMessage: "Parent's Home",
  },
  addAddress: {
    id: `${scope}.addAddress`,
    defaultMessage: 'Add Address',
  },
  importAddressFromWechat: {
    id: `${scope}.importAddressFromWechat`,
    defaultMessage: 'Import address from wechat',
  },
  selectTag: {
    id: `${scope}.selectTag`,
    defaultMessage: 'Select Tag',
  },
  createNewAddress: {
    id: `${scope}.createNewAddress`,
    defaultMessage: 'Create new address',
  },
  returnToSelectAddress: {
    id: `${scope}.returnToSelectAddress`,
    defaultMessage: 'Return to select address',
  },
  chooseAnotherAddress: {
    id: `${scope}.chooseAnotherAddress`,
    defaultMessage: 'Choose Address',
  },
  addNewAddress: {
    id: `${scope}.addNewAddress`,
    defaultMessage: 'Add New Address',
  },
  returnAllAddresses: {
    id: `${scope}.returnAllAddresses`,
    defaultMessage: 'Return all addresses',
  },
});
