import { useState } from 'react';
import { useRemoveFromCart, useUpdateCartItem } from 'containers/CartProvider/queries';
import useDeepCompareEffect from 'utils/Hooks/updateEffect/useDeepCompareEffect';
import { useGetAllWishlist, useToggleFav } from 'containers/WishlistPage/queries';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import { sprintf } from 'utils/helpers';
import { useIntl } from 'react-intl';
import cartMessages from '../messages';

const useOperateCartItem = cartItem => {
  const notificationSnackbar = useNotificationSnackbar();
  const intl = useIntl();

  const { mutateAsync: remove, isLoading: removeLoading } = useRemoveFromCart();
  const { mutateAsync: update, isLoading: updateLoading } = useUpdateCartItem();
  const { mutateAsync: toggleFavorite, isLoading: favLoading } = useToggleFav();
  const { data: wishlistItems, refetch: refetchWishList } = useGetAllWishlist();

  const [quantity, setQuantity] = useState(cartItem.qty);
  const [showConfirm, setShowConfirm] = useState(false);
  const [timer, setTimer] = useState(null);

  useDeepCompareEffect(() => {
    setQuantity(cartItem.qty);
  }, [cartItem]);

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const removeCartItem = async ({ complete, error } = {}) => {
    try {
      const result = await remove({
        itemId: cartItem.item_id,
      });

      closeConfirm(false);
      complete?.(result);
      notificationSnackbar(intl.formatMessage(cartMessages.successfullyDeleted), 'success');
    } catch (err) {
      error(err);
      notificationSnackbar(
        sprintf(err.response.data.message, err.response.data.parameters),
        'default',
      );
    }
  };

  const updateCart = async qty => {
    try {
      if (qty === 0 || cartItem.qty === qty) return;

      // proceedingToCheckout(true);
      setQuantity(qty);

      await update({
        data: { cartItem: { ...cartItem, qty } },
      });
      // proceedingToCheckout(false);
    } catch (err) {
      console.log(err);
      // proceedingToCheckout(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const quantityAdjustment = async event => {
    const qty = parseInt(event, 10);

    if (qty === 0) {
      return setShowConfirm(true);
    }

    setQuantity(qty);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      updateCart(qty);
    }, 1000);
    setTimer(timeoutId);
  };

  const joinFavorites = async option => {
    try {
      const id = option?.extension_attributes?.product_id;

      await toggleFavorite({
        toggle: 'no',
        id,
        productId: id,
      });

      await refetchWishList();

      notificationSnackbar(intl.formatMessage(cartMessages.joinFavSuccess), 'success');
    } catch (err) {
      notificationSnackbar(
        sprintf(err.response.data.message, err.response.data.parameters),
        'default',
      );
    }

    await removeCartItem();
  };

  return {
    quantity,
    showConfirm,
    wishlistItems,
    loading: removeLoading || updateLoading || favLoading,
    updateCart,
    closeConfirm,
    joinFavorites,
    removeCartItem,
    quantityAdjustment,
  };
};

export default useOperateCartItem;
