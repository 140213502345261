/**
 * @author zhengji.su
 * @description FormatMessage
 */

import { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: props => props.bold,
  },
}));

const FormatMessage = props => {
  const { message, variant, component, color, children, bold, ...other } = props;

  const classes = useStyles(props);

  return (
    <Typography
      variant={variant}
      component={component}
      color={color}
      classes={{ root: classes.root }}
      {...other}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {!isUndefined(message) &&
        (isString(message) || isNumber(message) ? message : <FormattedMessage {...message} />)}
      {children && children}
    </Typography>
  );
};

FormatMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.string,
  component: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  bold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(FormatMessage);
