import { forwardRef } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    'align-items': 'center',
    'flex-direction': 'column',
    'justify-content': 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
}));

const getSources = ({ op, src, params }) => {
  const baseAppUrl =
    process.env.BASE_URL_APP && process.env.BASE_URL_APP.endsWith('/')
      ? process.env.BASE_URL_APP
      : `${process.env.BASE_URL_APP}/`;
  const baseUrl = `${baseAppUrl}imaginary`;
  const extension = src.split('.').pop().toLowerCase();
  const paramsDefault = {
    ...params,
    type: extension === 'jpg' ? 'jpeg' : extension,
    url: src,
    stripmeta: 1,
    noprofile: 1,
  };
  const paramsWebp = {
    ...paramsDefault,
    type: 'webp',
    stripmeta: 1,
    noprofile: 1,
  };
  const defaultType = `image/${extension}`;

  const imaginaryUrlDefault = `${baseUrl}/${params.op || op}?${qs.stringify(paramsDefault)}`;
  const imaginaryUrlWebp = `${baseUrl}/${params.op || op}?${qs.stringify(paramsWebp)}`;
  return [
    {
      srcSet: imaginaryUrlWebp,
      type: 'image/webp',
    },
    {
      srcSet: imaginaryUrlDefault,
      type: defaultType,
    },
  ];
};

const Picture = forwardRef((props, ref) => {
  const {
    op,
    params,
    src,
    alt,
    title,
    className,
    loading,
    width,
    height,
    component: Component,
    imgStyle,
  } = props;
  const classes = useStyles(props);

  if (src === undefined) {
    return null;
  }

  const paramsXs = params.xs || params;
  const sourcesXs = getSources({ op, src, params: paramsXs });
  const sourcesMd = params.md ? getSources({ op, src, params: params.md }) : [];
  return (
    <Component className={clsx(classes.root, className)} ref={ref}>
      {sourcesMd.map((source, i) => (
        <source
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          srcSet={source.srcSet}
          type={source.type}
          media="(min-width:960px)"
        />
      ))}
      {sourcesXs.map((source, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <source key={i} srcSet={source.srcSet} type={source.type} />
      ))}
      <img
        className={classes.img}
        src={src}
        alt={alt}
        title={title}
        loading={loading}
        width={width}
        height={height}
        style={imgStyle}
      />
    </Component>
  );
});

Picture.defaultProps = {
  op: 'convert',
  params: {},
  className: '',
  loading: 'auto',
  width: undefined,
  height: undefined,
  component: 'picture',
  imgStyle: {},
};

const paramsShape = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
  quality: PropTypes.number,
  compression: PropTypes.number,
});

Picture.propTypes = {
  op: PropTypes.oneOf(['crop', 'convert', 'smartcrop', 'resize', 'thumbnail', 'fit']),
  params: PropTypes.oneOfType([
    paramsShape,
    PropTypes.shape({
      xs: paramsShape,
      md: paramsShape,
    }),
  ]),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.oneOf(['lazy', 'auto']),
  width: PropTypes.number,
  height: PropTypes.number,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  imgStyle: PropTypes.object,
};

export default Picture;
