import { useEffect, useState, useCallback } from 'react';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
// import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';

const useMPBridge = (api, callback, { path = '/pages/hybrid/hybrid' } = {}) => {
  const [currentReq, setCurrentReq] = useState(() => `H5Req_${Date.now()}`);
  const loc = useLocation();
  // const notificationSnackbar = useNotificationSnackbar();

  const callMiniProgram = useCallback(
    ({ params = {}, ...other } = {}) => {
      if (!determineEnvironment()) return;

      const req = `H5Req_${Date.now()}`;
      setCurrentReq(req);

      // notificationSnackbar(`navigateTo:${loc.pathname} - ${req}`, 'success');
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: `${path}?${qs.stringify({
          cmd: JSON.stringify({
            req /* The unique code of this message, used for WeChat applet to feed back the processing result to H5 */,
            api /* API name */,
            source: loc.pathname,
            ...other,
            params,
          }),
        })}`,
      });
    },
    [currentReq, loc],
  );

  useEffect(() => {
    if (!determineEnvironment()) return undefined;
    const handleHashChange = () => {
      // notificationSnackbar(`hashChange:${window.location.hash} - ${currentReq}`);
      /* If the instruction has not been issued, or hash is not the operation feedback given by the WeChat applet, then hashchange may be triggered internally by the webpage, just ignore it. */
      if (currentReq == null || !window.location.hash.startsWith(`#${currentReq}=`)) {
        return;
      }

      /* Take out the operation feedback body and trigger possible callback methods */
      const res = qs.parse(window.location.hash.substring(1));
      const result = res[currentReq];

      let json = {};

      try {
        json = JSON.parse(result);
      } catch (error) {
        json = {};
      }

      if (callback) callback(json);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, [currentReq]);

  return [callMiniProgram];
};

export default useMPBridge;
