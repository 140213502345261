import { useEffect, useRef, useContext } from 'react';
import { renderToString } from 'react-dom/server';

import isEmpty from 'lodash/isEmpty';
import InfoWindowContent from 'containers/StoreProvider/components/InfoWindowContent';
import { getInputWidth } from 'utils/helpers';
import MapContext from 'containers/StoreProvider/components/MapContext';

const markerIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABMCAMAAAAGACSBAAAAkFBMVEUAAADhADPzAEjhADPjADXsADj/AFvkADvhADThADTjADTgADPhADPhADThADThADTiADXnADbsAD7iADXhADThADPhADThADPhADPiADXjADXkADXhADPhADPhADThADThADPhADThADPhADPiADPhADThADThADPhADPhADPhADTiADXkADbnAD7lADTgADOvEfp/AAAAL3RSTlMA+AfxPAwDG5JuSvzqz4loQx4TTeG4o5uXXjUs49/ZzMaxqqCNYtTRv4F8eiYXFu12a/QAAAIaSURBVFjDtdbpcqpAEAXgM8CAUZTNNS5JNGZPzvu/3Y11NUG7gRmr8v0Epqjp7jk1+BOfeTaaT6fzUZZ/wkfcn21Ys5n1Y7hJeiGFsJc4LTVUmc7l5WmpvrxEi9sxW41v0ejGsIO5gc5u6WBroQju6eQ+gBA/0NGD7Pmczua48EYPbzjTp5c+auyYXsYWvx7p6bE2z4aeTKJU2r/ipaE3U55Gmlc4DfmQVxgey0VduH3f79+3IXUJDhbUrPbxcej3K2oWOJiq02/xw6pnZtq45SnOTBs3bSjcBTgT3FEw+FZQinAholQcQo/CC4QXCocwzLUBEJRBygFUFJYQlhQqPQdKCCWFvr7nAEKg77nQJk9I9GoHRutUd69MgG8y7EcQRjL8G56vRazHa+0PerkzXMia4jcI5VH+wJkP5ZNjS3YUNgVqig2FHf7LKaU5fuQppdP7eEzFpB/gW1BNqBjHaI9PM5xMhqYpPE9sSE+hFSPgQo7RV+j54y/UvNLLK+psSg+pFTHjSsZU/Exnz7E8sM4iCDs62kEqUzpJSygqOqmgmtHBDDq7Zqe1RYNowA6DCI0e3S9vUvDEVk8BWiwNW5glWi3YYoEOM/8uyeuHelHpVKRUpQUcRAP3DksZFRkc9Sj04Cqe8MIkhjM75JmhhYdkxZpVAi/LkD/CJTxFhkcmgrdqcGxwhSssxGnwkQ3IQYYrRb1ehL/yD4ePzxtjTlFNAAAAAElFTkSuQmCC';
const useMap = ({ onView } = {}) => {
  const { map, setMap, layers, setLayers, markers, setMarkers } = useContext(MapContext);

  const mapRef = useRef();
  mapRef.current = map;
  const infoWindowRef = useRef();
  infoWindowRef.current = layers?.infoWindow;
  const markerRef = useRef();
  markerRef.current = layers?.marker;
  const timerRef = useRef();
  const infoWindowTimer = useRef();

  const initInfoWindow = (options = {}) => {
    const infoWindow = new TMap.InfoWindow({
      map: mapRef.current,
      enableCustom: true,
      zIndex: 10,
      offset: { x: 105, y: 6 },
      ...options,
    });
    infoWindowRef.current = infoWindow;
    setLayers(state => ({ ...state, infoWindow }));
    return infoWindow;
  };

  const hideWindowInfo = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      // infoWindowRef.current?.destroy?.();
    }, 500);
  };

  const openWindowInfo = (id, position) => {
    clearTimeout(timerRef.current);
    if (infoWindowRef.current) {
      infoWindowRef.current.setMap(null);
    }

    const currentMarker = markers.find(item => item.source_code === id);
    const charWidth = getInputWidth(currentMarker.name);
    const offsetX = (charWidth + 121) / 2 + 20;
    const infoWindow = initInfoWindow({
      offset: { x: offsetX, y: 17 },
      position,
      zIndex: 1,
      content: renderToString(
        <InfoWindowContent title={currentMarker.name} id={currentMarker.source_code} />,
      ),
    });
    infoWindow.open(); // 打开信息窗

    const windowInfoNode = document.querySelector('.infowindow-root');
    const windowInfoNavigateNode = document.querySelector('.infowindow-navigate-button');
    windowInfoNode.onmouseenter = () => {
      clearTimeout(infoWindowTimer.current);
      infoWindowTimer.current = setTimeout(() => {
        // console.log('windowInfoNode onmouseenter clearTimeout');
        clearTimeout(timerRef.current);
      }, 200);
    };
    windowInfoNode.onmouseleave = () => hideWindowInfo();
    windowInfoNavigateNode.onclick = () => onView?.(currentMarker, null);
  };

  const handleOpenWindowInfo = evt => {
    openWindowInfo(evt.geometry.id, evt.geometry.position);
  };

  const renderMarker = () => {
    if (markerRef.current) {
      markerRef.current.off('mousemove', handleOpenWindowInfo);
      markerRef.current.off('mouseleave', hideWindowInfo);
      markerRef.current.setMap(null);
    }

    if (isEmpty(markers)) {
      return undefined;
    }
    // 创建并初始化MultiMarker
    const geometries = markers.map(item => ({
      styleId: 'marker',
      position: new TMap.LatLng(item.latitude, item.longitude),
      id: item.source_code,
    }));
    const marker = new TMap.MultiMarker({
      id: 'marker-layer',
      map: mapRef.current,
      styles: {
        // 点标记样式
        marker: new TMap.MarkerStyle({
          width: 30, // 样式宽
          height: 38, // 样式高
          anchor: { x: 15, y: 38 }, // 描点位置
          src: markerIcon,
        }),
      },
      geometries,
    });
    markerRef.current = marker;
    setLayers(state => ({ ...state, marker }));
    marker.on('mousemove', handleOpenWindowInfo);
    marker.on('mouseout', hideWindowInfo);
    return marker;
  };

  const initMap = el => {
    // 定义地图中心点坐标
    const center = new TMap.LatLng(39.98210863924864, 116.31310899739151);
    // 定义map变量，调用 TMap.Map() 构造函数创建地图
    const mapIns = new TMap.Map(el, {
      center, // 设置地图中心点坐标
      zoom: 5, // 设置地图缩放级别
      // pitch: 43.5, // 设置俯仰角
      // rotation: 45, // 设置地图旋转角度
      mapStyleId: 'style1',
    });
    mapRef.current = mapIns;
    renderMarker();
    setMap(mapIns);
    return mapIns;
  };

  const zoomTo = (zoom = 5) => {
    map?.easeTo?.({ zoom }, { duration: 1000 }); // 平滑缩放,旋转到指定级别
  };

  const panTo = (mapStatus, easeOptions) => {
    map?.easeTo?.({ ...mapStatus }, { duration: 2000, ...easeOptions }); // 平滑缩放,旋转到指定级别
  };

  useEffect(() => {
    if (mapRef.current) {
      renderMarker();
    }
  }, [markers]);

  useEffect(
    () => () => {
      mapRef.current?.destroy?.();
    },
    [],
  );

  return { map, initMap, setMap, setLayers, markers, setMarkers, zoomTo, panTo, openWindowInfo };
};

export default useMap;
