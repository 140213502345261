/**
 *
 * BottomDialog component bit.dev
 * @param {bool} open (open dialog)
 * @param {func} setOpen (open and close dialog)
 * @param {object|string} messageId button close name (pass the string or <FormatedMessage …>)
 * @param {string} title Title of the modal
 * @param {node} children - include content of the modal
 * @param {node} icon icon with FontAwesome for instance
 *
 * @return {node} Returns BottomDialog, all styling managed by Material UI Theme and classes (root and paper)
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Buttons from 'components/Buttons/Buttons';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { CloseIcon } from 'components/Icons';
import appMessages from 'containers/App/messages';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'flex-end',
  },
  paper: {
    width: '100%',
    margin: 0,
    borderRadius: 0,
    minHeight: 200,
    boxShadow: 'none',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    paddingTop: 50,
    overflowY: 'auto',
  },
  closeButton: {
    margin: '-4px -5px -4px auto',
  },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function BottomDialog(props) {
  const classes = useStyles(props);
  const { children, open, title, setOpen, messageId, icon, renderTitle, onClose } = props;

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      TransitionProps={{ role: 'presentation' }}
      classes={{ container: classes.root }}
      PaperProps={{ classes: { root: classes.paper } }}
      aria-label={title && title}
      onClose={onClose}
    >
      <Box p={2} className={classes.header}>
        {renderTitle ? renderTitle() : <Typography variant="h5">{title && title}</Typography>}
        <Buttons
          onClick={setOpen}
          icon={icon && icon}
          size="small"
          messageId={messageId && messageId}
          className={classes.closeButton}
        />
      </Box>
      <Box className={classes.content}>{children}</Box>
    </Dialog>
  );
}

BottomDialog.defaultProps = {
  icon: <SvgIcon component={CloseIcon} color="primary" viewBox="0 0 12 12" fontSize="small" />,
  messageId: (
    <Typography variant="h5" component="span" color="textPrimary">
      <FormattedMessage {...appMessages.close} />
    </Typography>
  ),
  onClose: () => {},
};

BottomDialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
  renderTitle: PropTypes.func,
  setOpen: PropTypes.func,
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.node,
  onClose: PropTypes.func,
};

export default BottomDialog;
