import { createContext, useState, useMemo } from 'react';

const MapContext = createContext({});

if (process.env.NODE_ENV !== 'production') {
  MapContext.displayName = 'MapContext';
}

export const MapProvider = ({ ...other }) => {
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState();
  const [layers, setLayers] = useState();

  const contextValue = useMemo(
    () => ({
      map,
      setMap,
      layers,
      setLayers,
      markers,
      setMarkers,
    }),
    [map, markers, layers],
  );

  return <MapContext.Provider value={contextValue} {...other} />;
};

export default MapContext;
