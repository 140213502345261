import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import { RadioIcon, RadioCheckedIcon } from 'components/Icons';

const useStyles = makeStyles(() => ({
  root: {},
}));

const FtRadio = forwardRef((props, ref) => {
  const classes = useStyles(props);
  const { classes: propsClasses, ...other } = props;
  return (
    <Radio
      ref={ref}
      classes={classes}
      icon={<RadioIcon />}
      checkedIcon={<RadioCheckedIcon />}
      {...other}
    />
  );
});

FtRadio.propTypes = {
  classes: PropTypes.object,
};

export default FtRadio;
