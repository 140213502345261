import { createSlice } from '@reduxjs/toolkit';
import { setAuthorization, deleteAuthorization, isSignedIn } from 'utils/Auth';
import { resetCheckout } from 'containers/CheckoutPage/slices';
import { push } from 'connected-react-router/immutable';
import routes from 'utils/routes';
import { logOut as logOutApi } from 'containers/AuthorizationProvider/api';
// import {
//   deleteBuyNow, deleteProceedToCheckout, getBuyNow, getProceedToCheckout,
// } from 'utils/Checkout';
import { createGuestCart } from 'containers/CartProvider/queries';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { resetCart } from 'containers/CartProvider/slices';
import { toggleMiniCart } from 'containers/App/slices';

export const DEFAULT_TIMER_DURATION = process.env.PROJECT_ENV === 'development' ? 5 : 30;

export const authorizationState = createSlice({
  name: 'authorization',
  initialState: {
    type: 'guest',
    token: '',
    country: {},
    accountIdentifier: '',
    verificationCodeTimer: null,
    logInUrl: '',
    logInId: null,
    verificationCode: '',
  },
  reducers: {
    setStartTimer: (state, action) => {
      state.verificationCodeTimer =
        (action.payload && action.payload.seconds) || DEFAULT_TIMER_DURATION;
    },
    setTimer: (state, action) => {
      state.verificationCodeTimer = action.payload && action.payload.seconds;
    },
    setStopTimer: state => {
      state.verificationCodeTimer = null;
    },
    setAuth: (state, action) => {
      state.type = action.payload.type;
      state.token = action.payload.token;
    },
    setLoginUrl: (state, action) => {
      state.logInUrl = action.payload;
    },
    setLogInId: (state, action) => {
      state.logInId = action.payload;
    },
    clearLogInInfo: state => {
      state.verificationCode = '';
    },
    setLogInCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});

export const { setStartTimer, setTimer, setStopTimer, setAuth, setLogInId, clearLogInInfo } =
  authorizationState.actions;

export const selectCodeTimer = state => state.authorization.verificationCodeTimer;
export const selectLogInUrl = state => state.authorization.logInUrl;

export const countDown =
  (secs = DEFAULT_TIMER_DURATION) =>
  dispatch => {
    let seconds = secs;
    dispatch(setStartTimer({ seconds }));
    const counter = setInterval(() => {
      seconds -= 1;
      if (seconds >= 0) {
        dispatch(setTimer({ seconds }));
      } else {
        dispatch(setStopTimer());
        clearInterval(counter);
      }
    }, 1000);
  };

export const postLogin =
  (token, toDashboard = true) =>
  dispatch => {
    deleteAuthorization();
    setAuthorization(token);

    if (toDashboard) {
      dispatch(push(routes.dashboard));
    } else {
      dispatch(push(routes.home));
    }
    dispatch(resetCheckout());
  };

export const questLogOut =
  ({ complete } = {}) =>
  async dispatch => {
    const { location } = window;
    const protectedRoutes = [
      routes.checkout(),
      routes.dashboard,
      routes.ordersAll,
      routes.orders(),
      routes.orderDetail(),
      routes.profile,
      routes.notifications,
    ];

    if (isSignedIn()) {
      try {
        await logOutApi();
      } catch (err) {
        console.log('Log out call failed: ', err);
      }
    }

    try {
      // Remove logged in user and their token from local storage before proceeding
      deleteAuthorization();

      // Reset store state - TODO ADD MORE
      // dispatch(toggleAllItems(false));
      dispatch(toggleMiniCart(false));
      dispatch(resetCart());
      dispatch(resetCheckout());

      // Create a new guest cart and send new token to store and local storage
      const token = await createGuestCart();
      setAuthorization(token, 'guest');
      dispatch(setAuth({ type: 'guest', token }));

      const currentRoute = location?.pathname.match(/^((?!\/\d+).)*/);
      if (currentRoute && protectedRoutes.includes(currentRoute[0])) {
        if (determineEnvironment()) {
          dispatch(push(routes.home));
        } else {
          dispatch(push(routes.logIn));
        }
      }

      // Fetch the guest's cart
      // dispatch({ type: cartConstants.GET_CART_QUOTE_REQUEST });
      if (complete) complete();
    } catch (err) {
      console.log('Error: ', err);
    }
  };

export default authorizationState.reducer;
