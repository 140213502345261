/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */

// eslint-disable-next-line import/prefer-default-export
export const STORE_TYPES = ['所有店铺', '旗舰店', 'DSC'];
