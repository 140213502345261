import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useController, useFormContext } from 'react-hook-form';
import FormatMessage from 'components/FormatMessage';
import isString from 'lodash/isString';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';

const FormText = forwardRef(
  (
    {
      name,
      rules,
      defaultValue = '',
      InputProps = {},
      inputLabel,
      label,
      variant = 'filled',
      ...rest
    },
    ref,
  ) => {
    const { control, setValue } = useFormContext();
    const {
      field: { ref: fieldRef, ...fieldProps },
      fieldState,
    } = useController({
      name,
      control,
      rules,
      defaultValue,
    });
    const matchMd = useMediaQuery(theme => theme.breakpoints.down('md'));

    useImperativeHandle(ref, () => ({
      ...fieldProps,
      setValue,
    }));

    return (
      <Box textAlign="left">
        {inputLabel && (
          <Hidden mdDown>
            {isString(inputLabel) ? (
              <FormatMessage message={inputLabel} variant="caption" />
            ) : (
              inputLabel
            )}
          </Hidden>
        )}
        <TextField
          InputProps={{
            ...InputProps,
            disableUnderline: true,
          }}
          {...fieldProps}
          error={!!fieldState.error}
          helperText={fieldState?.error?.message}
          label={matchMd ? label : ''}
          variant={variant}
          {...rest}
          inputRef={fieldRef}
        />
      </Box>
    );
  },
);

FormText.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  InputProps: PropTypes.object,
  inputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  variant: PropTypes.string,
};

export default FormText;
