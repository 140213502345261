import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Buttons from 'components/Buttons/Buttons';
import { makeStyles } from '@material-ui/core/styles';
import { PlusIcon, MinusIcon } from 'components/Icons';
import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(theme => ({
  quantityAdjuster: {
    display: 'inline-flex',
    maxWidth: 160,
    width: 134,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    borderRadius: 2,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
  },
  iconButton: {
    display: 'inline-flex',
    minHeight: 'auto',
    width: 25,
    minWidth: 'auto',
    color: theme.palette.text.primary,
  },
}));

function HandleButton({
  className,
  minusButtonStyle,
  plusButtonStyle,
  maximum,
  initialValue,
  onChange,
  disabled,
  allowZero,
  small,
  applyValue,
  big,
  ...other
}) {
  const classes = useStyles();

  const [value, setValue] = useState(initialValue);

  const minimum = allowZero ? 0 : 1;

  const increment = () => {
    let newValue = parseInt(value + 1, 10);
    newValue = newValue > maximum ? maximum : newValue;
    if (newValue !== value) {
      onChange(newValue);
      setValue(newValue);
    }
  };

  const decrement = () => {
    const newValue = value - 1;
    onChange(newValue);
    if (newValue !== 0) {
      setValue(newValue);
    }
  };

  const smallHeight = small ? 32 : 36;
  const height = big ? 48 : smallHeight;

  return (
    <Box
      className={`${classes.quantityAdjuster} ${className}`}
      style={{ maxWidth: `${small && '90px'}`, width: `${small && '90px'}` }}
      height={height}
      {...other}
    >
      <Buttons
        onClick={decrement}
        messageId={<SvgIcon component={MinusIcon} viewBox="0 0 16 16" />}
        disabled={disabled || value === minimum}
        className={classes.iconButton}
        style={minusButtonStyle}
        effect={false}
      />
      <Typography variant="body2">{applyValue ? value : initialValue}</Typography>
      <Buttons
        onClick={increment}
        messageId={<SvgIcon component={PlusIcon} viewBox="0 0 16 16" />}
        className={classes.iconButton}
        style={plusButtonStyle}
        effect={false}
      />
    </Box>
  );
}

HandleButton.defaultProps = {
  maximum: 99,
  initialValue: 1,
  disabled: false,
  allowZero: false,
  small: false,
};

HandleButton.propTypes = {
  className: PropTypes.string,
  minusButtonStyle: PropTypes.object,
  plusButtonStyle: PropTypes.object,
  maximum: PropTypes.number,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  allowZero: PropTypes.bool,
  small: PropTypes.bool,
  applyValue: PropTypes.bool,
  big: PropTypes.bool,
};

export default memo(HandleButton);
