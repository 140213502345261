import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { CheckboxIcon, CheckboxCheckedIcon } from 'components/Icons';

const useStyles = makeStyles(() => ({
  root: {},
}));

const FtCheckbox = forwardRef((props, ref) => {
  const classes = useStyles(props);
  const { classes: propsClasses, ...other } = props;
  return (
    <Checkbox
      ref={ref}
      // inputRef={ref}
      classes={classes}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxCheckedIcon />}
      {...other}
    />
  );
});

FtCheckbox.propTypes = {
  classes: PropTypes.object,
};

export default FtCheckbox;
