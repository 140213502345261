/**
 * @author zhengji.su
 * @description LanguageMenu
 */

import { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AnimationShowElement } from 'components/Parallax';
import { transitionTime } from 'muiTheme';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    width: 120,
    boxShadow: '0 0 16px 0 rgb(0 0 0 / 8%)',
    borderRadius: 8,
    backgroundColor: theme.palette.background.white,
  },
  items: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.peach,
      color: theme.palette.primary.main,
      transition: `background ${transitionTime}s, color ${transitionTime}s`,
    },
  },
}));

const from = { x: -195, y: 20 };
const LanguageMenu = ({ visible, setVisible, onCheckLanguage, options }) => {
  const classes = useStyles();

  const handleClick = option => onCheckLanguage?.(option);

  const onBackdropClose = useCallback(() => {
    setVisible({ ...visible, language: false });
  }, []);

  return (
    <AnimationShowElement
      from={from}
      visible={visible.language}
      onBackdropClose={onBackdropClose}
      top={90}
      position="absolute"
      duration={0.5}
      scale={1}
    >
      <Box className={classes.container}>
        {options.map(item => (
          <Box
            key={item.id}
            px={1.75}
            py={2.25}
            className={classes.items}
            onClick={() => handleClick(item)}
          >
            {item.title}
          </Box>
        ))}
      </Box>
    </AnimationShowElement>
  );
};

LanguageMenu.propTypes = {
  onCheckLanguage: PropTypes.func.isRequired,
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(LanguageMenu);
