/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
import { useState, useEffect, useMemo } from 'react';
import { useGetCartQuote } from 'containers/CartProvider/queries';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCartItemsSelected,
  selectCartItems,
  toggleCartItem,
  toggleLock1000 as toggleLock1000Action,
  lockCheckout as lockCheckoutAction,
  lock1000,
} from 'containers/CartProvider/slices';
import { calcSubTotal, calcPvTotal } from 'utils/helpers';
import { useIsMutating, useIsFetching } from 'react-query';
import { selectShowMiniCart } from 'containers/App/slices';

const useShoppingCart = () => {
  const { data: quote } = useGetCartQuote();
  const [cartItems, setCartItems] = useState([]);

  const isMutatingUpdateCartItem = useIsMutating(['cart.updateCartItem']);
  const isMutatingRemoveFromCart = useIsMutating(['cart.removeFromCart']);
  const isMutatingAddToCartQuote = useIsMutating(['cart.addToCartQuote']);

  const isFetching = useIsFetching(['cart.quote']);

  const isLoading = !!(
    isMutatingUpdateCartItem ||
    isMutatingRemoveFromCart ||
    isMutatingAddToCartQuote ||
    isFetching
  );

  const dispatch = useDispatch();
  const selectedItems = useSelector(selectCartItemsSelected);
  const lockCheckout = useSelector(lockCheckoutAction);
  const isLock1000 = useSelector(lock1000);
  const showMiniCart = useSelector(selectShowMiniCart);

  const isAllChecked = useMemo(
    () => cartItems.length === selectedItems.length,
    [cartItems, selectedItems],
  );
  const cartQuantity = useMemo(
    () => (cartItems.length > 0 ? cartItems.reduce((acc, curr) => acc + curr.qty, 0) : 0),
    [cartItems],
  );
  const cartSelectedQuantity = useMemo(() => {
    if (quote?.items && selectedItems && selectedItems.length > 0) {
      const selectedCartItems = quote.items.filter(i => selectedItems.indexOf(i.item_id) !== -1);
      return selectedCartItems.reduce((acc, curr) => acc + curr.qty, 0);
    }
    return 0;
  }, [cartItems, selectedItems]);
  const subTotal = useMemo(
    () => calcSubTotal(selectedItems, cartItems),
    [selectedItems, cartItems],
  );
  const pvTotal = useMemo(() => calcPvTotal(selectedItems, cartItems), [selectedItems, cartItems]);

  useEffect(() => {
    if (quote) {
      const items = quote.items ?? [];
      // clean selectedItems
      setCartItems(items);
      const nextSelectedItems = selectedItems.filter(selectedItem =>
        items.find(i => i.item_id === selectedItem),
      );
      dispatch(selectCartItems(nextSelectedItems));
    }
  }, [quote]);

  const toggleAllItems = checked => {
    const nextSelectedItems = [];
    if (checked === true) {
      quote.items.forEach(i => {
        nextSelectedItems.push(i.item_id);
      });
    }
    dispatch(selectCartItems(nextSelectedItems));
  };

  const toggleLock1000 = payload => {
    dispatch(toggleLock1000Action(payload));
  };

  const toggleItem = payload => dispatch(toggleCartItem(payload));

  return {
    quote,
    cartItems,
    selectedItems,
    toggleAllItems,
    toggleLock1000,
    toggleItem,
    isAllChecked,
    cartQuantity,
    cartSelectedQuantity,
    subTotal,
    pvTotal,
    lockCheckout,
    isLock1000,
    isLoading,
    showMiniCart,
  };
};

export default useShoppingCart;
