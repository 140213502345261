/*
 * SiteFooter Messages
 *
 * This contains all the text for the SiteFooter container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CMS';

export default defineMessages({
  termsConditions: {
    id: `${scope}.termsConditions`,
    defaultMessage: 'Terms & Conditions',
  },
  returnAndRefundPolicy: {
    id: `${scope}.returnAndRefundPolicy`,
    defaultMessage: 'Return and Refund Policy',
  },
  joinUs: {
    id: `${scope}.joinUs`,
    defaultMessage: 'join us',
  },
  companyDescription: {
    id: `${scope}.companyDescription`,
    defineMessages: 'Company Description',
  },
  paymentMethods: {
    id: `${scope}.paymentMethods`,
    defaultMessage: 'Payment methods',
  },
  paymentProcess: {
    id: `${scope}.paymentProcess`,
    defaultMessage: 'Payment process',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Contact us',
  },
  statement: {
    id: `${scope}.statement`,
    defaultMessage: 'Genuine statement',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy policy',
  },
  afterSaleRules: {
    id: `${scope}.afterSaleRules`,
    defaultMessage: 'After sales rules',
  },
  afterSaleProcess: {
    id: `${scope}.afterSaleProcess`,
    defaultMessage: 'After sales process',
  },
  useWechat: {
    id: `${scope}.useWechat`,
    defaultMessage: 'Using wechat miniProgram',
  },
  clauseStatement: {
    id: `${scope}.clauseStatement`,
    defaultMessage: 'Clause statement',
  },
  helper: {
    id: `${scope}.helper`,
    defaultMessage: 'Help',
  },
  allQuestions: {
    id: `${scope}.allQuestions`,
    defaultMessage: 'All questions',
  },
  questionItems: {
    id: `${scope}.questionItems`,
    defaultMessage: '{total} items',
  },
  agreement: {
    id: `${scope}.agreement`,
    defaultMessage: 'Registration Agreement',
  },
});
