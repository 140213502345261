import { useMutation, useQuery } from 'react-query';
import GET_CUSTOMER from 'containers/UserProfile/gql/queryGetCustomer';
import requestGql, { querySetting } from 'utils/requestGql';
import { isSignedIn } from 'utils/Auth';
import get from 'lodash/get';
import { changePassword, updateProfile, updateSM, userUpgradeDC } from 'containers/UserProfile/api';

export const getCustomerQuery = () => requestGql(GET_CUSTOMER);

export const useGetCustomer = (option = {}) =>
  useQuery(
    'userProvider.customer',
    () => getCustomerQuery().then(response => get(response, 'customer', [])),
    {
      ...querySetting,
      enabled: isSignedIn(),
      ...option,
    },
  );

/**
 * Update personal information 更新个人信息
 * @apiGroup User
 * */
export const useUpdateProfileMutation = () => useMutation(payload => updateProfile(payload));

/**
 * Change Password 修改密码
 * @apiGroup User
 * */
export const useChangePasswordMutation = () =>
  useMutation(({ oldPassword, newPassword }) => changePassword({ oldPassword, newPassword }));

/**
 * @apiGroup User
 * */
export const useUpdateSMMutation = () => useMutation(payload => updateSM(payload));

/**
 * Update DC 升级DC
 * @apiGroup User
 * */
export const useUpgradeDCMutation = () => useMutation(() => userUpgradeDC());
