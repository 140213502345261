/*
 * CatalogPage Messages
 *
 * This contains all the text for the CatalogPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CatalogPage';

export default defineMessages({
  productCatalogue: {
    id: `${scope}.productCatalogue`,
    defaultMessage: 'Product Catalogue',
  },
  searchCatalogue: {
    id: `${scope}.searchCatalogue`,
    defaultMessage: 'Search Catalogue',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: 'Products',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Categories',
  },
  line: {
    id: `${scope}.line`,
    defaultMessage: 'Product Lines',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  allLines: {
    id: `${scope}.allLines`,
    defaultMessage: 'All Lines',
  },
  flashSale: {
    id: `${scope}.title`,
    defaultMessage: 'Flash Sales',
  },
  subTitle: {
    id: `${scope}.subTitle`,
    defaultMessage: 'Don’t miss the chance and come again, hurry up and buy something',
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the FlashSale container!',
  },
  searchResults: {
    id: `${scope}.searchResults`,
    defaultMessage: 'Search Results',
  },
  loadingResults: {
    id: `${scope}.loadingResults`,
    defaultMessage: 'Loading Results',
  },
  searchResultsCount: {
    id: `${scope}.searchResultsCount`,
    defaultMessage: '{count} Items',
  },
  searchResultsCommodity: {
    id: `${scope}.searchResultsCommodity`,
    defaultMessage: 'Show search results for {commodity}',
  },
  searchAllCommodity: {
    id: `${scope}.searchAllCommodity`,
    defaultMessage: 'All Commodity',
  },
  noResults: {
    id: `${scope}.noResults`,
    defaultMessage: 'No "{value}" found',
  },
  noResultsFor: {
    id: `${scope}.noResultsFor`,
    defaultMessage: "There are no results for ' {value} '",
  },
  baseSupply: {
    id: `${scope}.baseSupply`,
    defaultMessage: 'baseSupply',
  },
  goodsTotal: {
    id: `${scope}.goodsTotal`,
    defaultMessage: 'A total of {total} items',
  },
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search keywords',
  },
  sort: {
    id: `${scope}.sort`,
    defaultMessage: 'Sort',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Price',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'New',
  },
  noFountNotice: {
    id: `${scope}.noFountNotice`,
    defaultMessage: 'You can try to search for other keywords',
  },
  noGoods: {
    id: `${scope}.noGoods`,
    defaultMessages: 'No Goods',
  },
});
