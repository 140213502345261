import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CopyIcon } from 'components/Icons';
import Buttons from 'components/Buttons/Buttons';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import useCopyMeToClipBoard from 'utils/Hooks/useCopyMeToClipBoard';

const useStyles = makeStyles(theme => ({
  copy: {
    minWidth: 20,
    marginLeft: theme.spacing(0.5),
    fontSize: '0.75rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));
const ItemCell = ({ title, value, spacing = 1, copy = false }) => {
  const classes = useStyles();
  const copyToClipBoard = useCopyMeToClipBoard();
  return (
    <Box display="flex" mb={spacing}>
      <Typography variant="subtitle2" color="textSecondary">
        {title}：
      </Typography>
      <Typography variant="subtitle2">{value}</Typography>
      {copy && value && (
        <Buttons
          onClick={() => copyToClipBoard(value)}
          size="small"
          messageId="复制"
          startIcon={<SvgIcon component={CopyIcon} viewBox="0 0 12 12" fontSize="small" />}
          className={classes.copy}
        />
      )}
    </Box>
  );
};
ItemCell.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
  copy: PropTypes.bool,
  spacing: PropTypes.number,
};
export default ItemCell;
