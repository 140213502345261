import { useQuery, useMutation } from 'react-query';
import { querySetting } from 'utils/requestGql';
import request from 'utils/request';

export const getCities = id => request('GET', `/directory/geoinfo/of/city/${id}?responseType=JSON`);
export const getDistricts = id =>
  request('GET', `/directory/geoinfo/of/district/${id}?responseType=JSON`);
export const addAddress = payload => request('POST', '/customer/address', payload);
export const editAddress = payload => request('PUT', '/customer/address', payload);
export const removeAddress = id => request('DELETE', `/customer/address/${id}`);

export const useGetCities = id =>
  useQuery(id !== '-1' && ['location.cities', id], () => getCities(id), querySetting);
export const useDistricts = id =>
  useQuery(id !== '-1' && ['location.districts', id], () => getDistricts(id), querySetting);
export const useAddressAdd = () => useMutation(address => addAddress(address));
export const useAddressEdit = () => useMutation(address => editAddress(address));
export const useAddressRemove = () => useMutation(id => removeAddress(id));
