// import { WC_ENV_MP } from 'containers/WeChatProvider/constants';
/* eslint-disable */
/**
 * Determine if current environment is WeChat MiniProgram
 *
 * @returns {bool}  True if the cross-env type as well as the WeChat environment return miniprogram.
 *                  WeChat environment is obtained via the WeChat JSSDK (see index.mp.html).
 */

function determineEnvironment() {
  // const ua = navigator.userAgent.toLowerCase();
  // let mp = false;
  // if (ua.includes('miniprogram')) {
  //   mp = true;
  // }
  // return mp;
  return navigator.userAgent.toLowerCase().includes('miniprogram') || window.__wxjs_environment === 'miniprogram';
};

export default determineEnvironment;