/**
 * @author zhengji.su
 * @description FormatSvgIcon
 */

import { memo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    height: props => +props.viewBox.substr(-2),
    lineHeight: props => `${parseFloat(props.height)}px` || 'normal',
  },
  svg: {},
}));

const FormatSvgIcon = props => {
  const {
    icon,
    viewBox,
    style,
    height,
    iconStyle,
    classes: propsClasses,
    component,
    ...other
  } = props;
  const classes = useStyles(props);

  return (
    <Typography
      variant="inherit"
      className={classes.root}
      style={{ height, ...style }}
      component={component}
      {...other}
    >
      {icon.type ? (
        icon
      ) : (
        <SvgIcon
          className={clsx(classes.svg, 'svg')}
          component={icon}
          viewBox={viewBox}
          style={iconStyle}
        />
      )}
    </Typography>
  );
};

FormatSvgIcon.defaultProps = {
  viewBox: '0 0 16 16',
  component: 'span',
};

FormatSvgIcon.propTypes = {
  icon: PropTypes.any,
  viewBox: PropTypes.string,
  classes: PropTypes.object,
  component: PropTypes.string,
  iconStyle: PropTypes.object,
  style: PropTypes.object,
  height: PropTypes.number,
};

export default memo(FormatSvgIcon);
