/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */

import { useSelector, useDispatch } from 'react-redux';
import { convertCartItemId } from 'utils/helpers';
import { getAuthorization } from 'utils/Auth';
import { useHistory } from 'react-router-dom';
import { selectCartItemsSelected } from 'containers/CartProvider/slices';
import isEmpty from 'lodash/isEmpty';
import { setProceedToCheckout } from 'utils/Checkout';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { toggleMPLoginVisible, toggleMiniCart } from 'containers/App/slices';
import routes from 'utils/routes';
import { useMoveToCheckoutQuote } from 'containers/CheckoutPage/queries';
import { useQueryClient } from 'react-query';

const useCheckout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { mutateAsync: moveToCheckoutQuote, ...other } = useMoveToCheckoutQuote();

  const itemIds = useSelector(selectCartItemsSelected);
  const queryClient = useQueryClient();

  const checkout = async (cartItems = []) => {
    const quote = queryClient.getQueryData(['cart.quote']);

    const auth = getAuthorization();

    if (auth.type !== 'user') {
      if (isEmpty(itemIds) && isEmpty(cartItems)) {
        throw new Error('没有可结算的购买项');
        // return;
      }
      let items = [];
      if (!isEmpty(cartItems)) {
        items = cartItems;
      } else if (!isEmpty(itemIds)) {
        quote?.items?.forEach(i => {
          if (itemIds.includes(i.item_id)) {
            items.push({ item_id: i.item_id, qty: i.qty, sku: i.sku });
          }
        });
      }
      console.log('items', items);
      setProceedToCheckout(items);
      if (determineEnvironment()) {
        dispatch(toggleMPLoginVisible(true));
      } else {
        history.push(routes.logIn);
        dispatch(toggleMiniCart(false));
      }
    } else {
      if (isEmpty(itemIds) && isEmpty(cartItems)) {
        // notificationSnackbar(`没有可结算的购买项:${JSON.stringify({ itemIds, cartItems })}`);
        throw new Error('没有可结算的购买项');
        // return;
      }

      // check item_id if not in cart  change item_id;
      if (!isEmpty(cartItems)) {
        // for guest user  change cart item_id
        const items = [];
        quote?.items?.forEach(i => {
          cartItems.forEach(i2 => {
            if (i.sku === i2.sku) {
              items.push({ item_id: i.item_id, qty: i2.qty });
            }
          });
        });

        await moveToCheckoutQuote({ itemIds: convertCartItemId(items) });
      } else if (!isEmpty(itemIds)) {
        await moveToCheckoutQuote({ itemIds });
      }
      dispatch(toggleMiniCart(false));
      history.push(routes.checkout());
    }
  };
  return { checkout, ...other };
};

export default useCheckout;
