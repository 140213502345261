export { default as AngleDownIcon } from 'components/Icons/icon/12-angle-down';
export { default as AngleLeftIcon } from 'components/Icons/icon/12-angle-left';
export { default as AngleLeftIcon16 } from 'components/Icons/icon/16-angle-left';
export { default as AngleRightIcon } from 'components/Icons/icon/12-angle-right';
export { default as ShareIcon } from 'components/Icons/icon/24-share';
export { default as FavIcon } from 'components/Icons/icon/24-favourite-idle';
export { default as FavouriteOnIcon } from 'components/Icons/icon/24-favourite-on';
export { default as ShoppingCartIcon } from 'components/Icons/icon/24-shopping-cart';
export { default as FlashIcon } from 'components/Icons/icon/flash_2';
export { default as EditIcon } from 'components/Icons/icon/12-down';
export { default as TimesIcon } from 'components/Icons/icon/16-times';
export { default as AngleUpIcon } from 'components/Icons/icon/12-angle-up';
export { default as CloseIcon } from 'components/Icons/icon/12-times';
export { default as PlusIcon } from 'components/Icons/icon/16-plus';
export { default as MinusIcon } from 'components/Icons/icon/16-minus';
export { default as WechatIcon } from 'components/Icons/icon/16-weixin';
export { default as CloseIcon24 } from 'components/Icons/icon/24-close';

// MyAccount icons
export { default as SettingIcon } from 'components/Icons/icon/24-setting';
export { default as TruckIcon24 } from 'components/Icons/icon/24-inTransit';
export { default as PendingReviewIcon24 } from 'components/Icons/icon/24-pending-review';
export { default as RmaIcon24 } from 'components/Icons/icon/24-rma';
export { default as UnpaidIcon24 } from 'components/Icons/icon/24-unpaid';
export { default as AngleRightIcon16 } from 'components/Icons/icon/16-angle-right';
export { default as CopyIcon } from 'components/Icons/icon/12-copy';
export { default as QRCodeIcon } from 'components/Icons/icon/QRCodeIcon';
export { default as QrCodeIcon16 } from 'components/Icons/icon/16-qr-code';
export { default as BusinessIcon } from 'components/Icons/icon/24-business';
export { default as BenefitsIcon } from 'components/Icons/icon/24-benefits';
export { default as AddressIcon } from 'components/Icons/icon/24-address';
export { default as AboutIcon } from 'components/Icons/icon/24-about';
export { default as FapiaoIcon } from 'components/Icons/icon/24-fapiao';
export { default as GiftIcon } from 'components/Icons/icon/24-gift';
export { default as NotificationIcon } from 'components/Icons/icon/24-notification';
export { default as MobilePhoneIcon } from 'components/Icons/icon/12-mobile-phone';
export { default as AngleLeftIcon12 } from 'components/Icons/icon/12-angle-left';
export { default as AngleLeft } from 'components/Icons/icon/12-angle-left';
export { default as DownTriangle } from 'components/Icons/icon/16-triangle-down';
export { default as AddToCart } from 'components/Icons/icon/24-add-to-cart';
export { default as addToCartIcon } from 'components/Icons/icon/24-add-to-cart';
export { default as AutoShipIcon } from 'components/Icons/icon/24-autoship';
export { default as DeleteIcon } from 'components/Icons/icon/16-trash-bin';
export { default as BankIcon } from 'components/Icons/icon/24-bank';
export { default as DCIcon } from 'components/Icons/icon/24-DC';
export { default as DownloadCloudIcon } from 'components/Icons/icon/24-download';
export { default as UploadIcon } from 'components/Icons/icon/16-upload';
export { default as LogoutIcon } from 'components/Icons/icon/16-sign-out';
export { default as DownloadIcon } from 'components/Icons/icon/download';
export { default as ContactIcon } from 'components/Icons/icon/contact-icon';

// login
export { default as EyeIcon } from 'components/Icons/icon/16-eye';
export { default as EyeSlashIcon } from 'components/Icons/icon/16-eye-slash';
export { default as ShieldIcon } from 'components/Icons/icon/12-shield';
export { default as StepDoneIcon } from 'components/Icons/icon/16-step-done';
export { default as StepDoneOn } from 'components/Icons/icon/16-step-on';
export { default as AlipayIcon } from 'components/Icons/icon/24-alipay';
export { default as SinaIcon } from 'components/Icons/icon/24-weibo';

/** **** MainNavigation item icons **** */
export { default as SearchIcon } from 'components/Icons/icon/16-search';
export { default as HomeIcon } from 'components/Icons/icon/24-home';
export { default as CatalogIcon } from 'components/Icons/icon/24-catalog';
export { default as BlogIcon } from 'components/Icons/icon/24-blog';
export { default as AccountIcon } from 'components/Icons/icon/24-account';

/** **** Order icons ******* */
export { default as AngleUpIcon16 } from 'components/Icons/icon/16-angle-up';
export { default as AngleDownIcon16 } from 'components/Icons/icon/16-angle-down';

/** *** snackbar icons **** */
export { default as InfoCircleIcon } from 'components/Icons/icon/16-info-circle';
export { default as CheckIcon } from 'components/Icons/icon/16-check';
export { default as ExclamationCircleIcon } from 'components/Icons/icon/16-exclamation-circle';
export { default as CheckCircleIcon } from 'components/Icons/icon/16-check-circle';
export { default as EditIcon16 } from 'components/Icons/icon/16-edit';
export { default as TriangleIcon } from 'components/Icons/icon/16-triangle-down';

// Search
export { default as TimesCircleIcon } from 'components/Icons/icon/16-times-circle';
export { default as StarIcon } from 'components/Icons/icon/16-star';
export { default as OpenLinkIcon } from 'components/Icons/icon/16-open-link-in-browser';
export { default as BellIcon } from 'components/Icons/icon/12-bell';
export { default as LanguageIcon } from 'components/Icons/icon/16-language';
export { default as ClockIcon } from 'components/Icons/icon/16-clock';
export { default as TruckIcon } from 'components/Icons/icon/16-truck';
export { default as CameraIcon } from 'components/Icons/icon/16-camera';
export { default as LLCIcon } from 'components/Icons/icon/24-LLC';
export { default as ContractIcon } from 'components/Icons/icon/contract';
export { default as Download12Icon } from 'components/Icons/icon/12-download';
export { default as PendingIcon } from 'components/Icons/icon/pending';
export { default as DeclineIcon } from 'components/Icons/icon/decline';
export { default as ArrowDownIcon } from 'components/Icons/icon/arrow-down';
export { default as TwoWayArrowIcon } from 'components/Icons/icon/two-way-arrow';
export { default as PCDeleteIcon } from 'components/Icons/icon/delete';
export { default as OrdersIcon } from 'components/Icons/icon/24-orders';
export { default as ProfileIcon } from 'components/Icons/icon/24-profile';
export { default as MoreIcon } from 'components/Icons/icon/16-more';
export { default as LinkIcon } from 'components/Icons/icon/24-link';
export { default as ReviewIcon } from 'components/Icons/icon/16-review';
export { default as ReturnIcon } from 'components/Icons/icon/16-return';
export { default as AddToCartIcon16 } from 'components/Icons/icon/16-add-to-cart';
export { default as WechatPay } from 'components/Icons/icon/24-weixin';
export { default as ListIcon } from 'components/Icons/icon/16-list';
export { default as CouponIcon } from 'components/Icons/icon/24-coupon';
export { default as IconsIcon } from 'components/Icons/icon/16-icons';
export { default as questionCircleIcon } from 'components/Icons/icon/12-question-circle';

// Avatar
export { default as AvatarDefaultIcon } from 'components/Icons/avatar/AvatarDefaultIcon';
export { default as AvatarCrownIcon } from 'components/Icons/avatar/AvatarCrownIcon';
export { default as AvatarHeadIcon } from 'components/Icons/avatar/AvatarHeadIcon';
export { default as LoadingIcon } from 'components/Icons/icon/LoadingIcon';

export { default as LocationIcon } from 'components/Icons/icon/location';
export { default as AimIcon } from 'components/Icons/icon/12-aim';
export { default as StoreFlagshipIcon } from 'components/Icons/icon/24-store-flagship';
export { default as CheckboxCheckedIcon } from 'components/Icons/icon/CheckboxCheckedIcon';
export { default as RadioIcon } from 'components/Icons/icon/RadioIcon';
export { default as RadioCheckedIcon } from 'components/Icons/icon/RadioCheckedIcon';
export { default as StoreStarIcon } from 'components/Icons/icon/24-store-star';
export { default as PaperIcon } from 'components/Icons/icon/PaperIcon';
export { default as EmptyAddressIcon } from 'components/Icons/icon/EmptyAddressIcon';
export { default as PendingPaymentIcon } from 'components/Icons/icon/PendingPaymentIcon';
export { default as PendingCommentIcon } from 'components/Icons/icon/PendingCommentIcon';
export { default as InTransitIcon } from 'components/Icons/icon/InTransitIcon';
export { default as CancelCloseIcon } from 'components/Icons/icon/CancelCloseIcon';
export { default as ResearchIcon } from 'components/Icons/icon/ResearchIcon';
export { default as MedalIcon } from 'components/Icons/icon/MedalIcon';
export { default as LogoIcon } from 'components/Icons/icon/LogoIcon';
export { default as CheckoutCompleteIcon1 } from 'components/Icons/icon/CheckoutCompleteIcon1';
export { default as CheckoutCompleteIcon2 } from 'components/Icons/icon/CheckoutCompleteIcon2';
export { default as CheckoutCompleteIcon3 } from 'components/Icons/icon/CheckoutCompleteIcon3';
export { default as CheckoutCompleteIcon4 } from 'components/Icons/icon/CheckoutCompleteIcon4';
export { default as CartEmptyIcon } from 'components/Icons/icon/CartEmptyIcon';
export { default as CheckboxIcon } from 'components/Icons/icon/CheckboxIcon';
export { default as FCIcon } from 'components/Icons/icon/FCIcon';
export { default as DoneIcon } from 'components/Icons/icon/DoneIcon';
export { default as ErrorIcon } from 'components/Icons/icon/ErrorIcon';
export { default as GermanyIcon } from 'components/Icons/icon/GermanyIcon';
export { default as MedalIcon24 } from 'components/Icons/icon/MedalIcon24';
export { default as WomanIcon } from 'components/Icons/icon/WomanIcon';
export { default as MedalTwoIcon } from 'components/Icons/icon/MedalTwoIcon';

// location
export { default as ProcessingIcon } from 'components/Icons/icon/16-processing';
export { default as RefreshIcon } from 'components/Icons/icon/12-refresh';
export { default as LocationIcon24 } from 'components/Icons/icon/24-location';

// eslint-disable-next-line import/no-unresolved
export { default as CelendarIcon } from 'components/Icons/icon/CelendarIcon';

export { default as NavigateIcon } from 'components/Icons/icon/24-navigate';
