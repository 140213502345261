import { useQuery } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';
import { isSignedIn } from 'utils/Auth';

export const getQrCodeQuery = (id = '') => request('GET', `/wechat_share/qrcode?${id}`);
export const getMyShareDownlineQuery = () => request('GET', '/wechat_share/downline');

export const useGetQrCode = id =>
  useQuery(['sharingProvider.qrCode', id], () => getQrCodeQuery(id), querySetting);

export const useGetMyShareDownline = () =>
  useQuery(
    'sharingProvider.myShareDownline',
    () => getMyShareDownlineQuery().then(response => response.items),
    {
      ...querySetting,
      enabled: isSignedIn(),
    },
  );
