import store from 'store';
import { useHistory } from 'react-router-dom';
import { deleteAuthorization, setAuthorization, getAuthorization } from 'utils/Auth';
import { useGetCheckoutQuote } from 'containers/CheckoutPage/queries';
import routes from 'utils/routes';
import { useGetCartQuote, assignCustomerToGuestCart } from 'containers/CartProvider/queries';
import { getProfile } from 'containers/UserProfile/api';
import {
  deleteBuyNow,
  deleteProceedToCheckout,
  getBuyNow,
  getProceedToCheckout,
} from 'utils/Checkout';
import useCheckout from 'utils/Hooks/useCheckout';
import { resetCart } from 'containers/CartProvider/slices';
import { useDispatch } from 'react-redux';
import useBuyNow from 'utils/Hooks/useBuyNow';
import { setAuth } from 'containers/AuthorizationProvider/slices';

const usePostLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { mutateAsync: getCheckoutQuote } = useGetCheckoutQuote();
  const { refetch: refetchCartQuote } = useGetCartQuote();
  const { checkout } = useCheckout();
  const { buyNow } = useBuyNow();

  return async (token, route = '', toDashboard = true) => {
    const auth = getAuthorization();
    deleteAuthorization();
    setAuthorization(token);
    dispatch(setAuth({ type: 'user', token }));

    const { id: customerId } = await getProfile();
    if (auth.token && customerId) {
      await assignCustomerToGuestCart(
        {
          customerId,
          storeId: 1,
        },
        auth.token,
      );
    }

    // Check if a Buy Now or Proceed to Checkout was initiated before log in
    const buyNowItems = getBuyNow();
    const proceedToCheckout = getProceedToCheckout();

    if (buyNowItems || proceedToCheckout) {
      if (buyNowItems) {
        buyNow(buyNowItems);
      } else if (proceedToCheckout) {
        await dispatch(resetCart());
        await checkout?.(proceedToCheckout);
      }

      deleteBuyNow();
      deleteProceedToCheckout();
      return;
    }

    await getCheckoutQuote();
    await refetchCartQuote();
    if (route !== false) {
      const redirectUrl =
        route || store.get('last_url') || (toDashboard ? routes.dashboard : routes.home);
      if (redirectUrl) {
        store.remove('last_url');
        history.push(redirectUrl);
      }
    }
  };
};

export default usePostLogin;
