/*
 * Validation Messages
 *
 * This contains all messages used for form validation
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.validation';

export default defineMessages({
  required: {
    id: `${scope}.required`,
    defaultMessage: 'This is a required field',
  },
  emailIsNotValid: {
    id: `${scope}.emailIsNotValid`,
    defaultMessage: 'Please enter a valid email address',
  },
  chineseNotValid: {
    id: `${scope}.chineseNotValid`,
    defaultMessage: 'Please enter Chinese',
  },
  phoneIsNotValid: {
    id: `${scope}.phoneIsNotValid`,
    defaultMessage: 'Please enter a valid phone number',
  },
  chineseIDIsNotValid: {
    id: `${scope}.chineseIDIsNotValid`,
    defaultMessage: 'Please enter a valid Chinese ID',
  },
  logInIdIsNotValid: {
    id: `${scope}.logInIdIsNotValid`,
    defaultMessage: 'Please enter a valid email address or phone number',
  },
  dateIsNotValid: {
    id: `${scope}.dateIsNotValid`,
    defaultMessage: 'This is not a valid date',
  },
  postalCodeIsNotValid: {
    id: `${scope}.postalCodeIsNotValid`,
    defaultMessage: 'Please enter a valid postal code',
  },
  zipCodeIsNotValid: {
    id: `${scope}.zipCodeIsNotValid`,
    defaultMessage: 'Please enter a valid zip code',
  },
  authCode: {
    id: `${scope}.authCode`,
    defaultMessage: 'Code must be exactly 4 digits',
  },
  enrollerID: {
    id: `${scope}.enrollerID`,
    defaultMessage: 'Enroller ID can only consist of digits',
  },
  newPasswordMustBeDifferent: {
    id: `${scope}.newPasswordMustBeDifferent`,
    defaultMessage: 'New password must be different',
  },
  bankAccountNumberInvalid: {
    id: `${scope}.bankAccountNumberInvalid`,
    defaultMessage: 'This is not a valid bank account number',
  },
  inputFCLoginId: {
    id: `${scope}.inputFCLoginId`,
    defaultMessage: 'Please enter an eight digit your Franchisee ID',
  },
  inputCorrectFCLoginId: {
    id: `${scope}.inputCorrectFCLoginId`,
    defaultMessage: 'Franchisee ID',
  },
  inputChineseID: {
    id: `${scope}.inputChineseID`,
    defaultMessage: 'Please enter your ID card number',
  },
  checkBank: {
    id: `${scope}.checkBank`,
    defaultMessage: 'Please select a bank',
  },
  receivingAccountName: {
    id: `${scope}.receivingAccountName`,
    defaultMessage: 'Please enter payee',
  },
  receivingAccountNumber: {
    id: `${scope}.receivingAccountNumber`,
    defaultMessage: 'Payee account number',
  },
  representativeName: {
    id: `${scope}.representativeName`,
    defaultMessage: 'Juridical person',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Please enter enterprise name',
  },
  inputEmail: {
    id: `${scope}.inputEmail`,
    defaultMessage: 'Please enter your email',
  },
  bankBranchNamePlaceholder: {
    id: `${scope}.bankBranchNamePlaceholder`,
    defaultMessage: 'Enter province and city',
  },
});
