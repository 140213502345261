import { useQuery, useMutation, useQueryClient } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';
import { getAuthorization, setAuthorization } from 'utils/Auth';
import isUndefined from 'lodash/isUndefined';
import { toggleLockCheckout } from 'containers/CartProvider/slices';
import { useDispatch } from 'react-redux';

export const createGuestCart = () => request('POST', '/guest-carts');
export const addToCartQuoteArray = payload =>
  request('POST', '/partial-checkout/cart/itemsArray', payload);
export const getGuestCart = token => request('GET', `/guest-carts/${token}`);
export const addToGuestCart = (payload, token) =>
  request('POST', `/guest-carts/${token}/items`, payload);
export const updateGuestCartItem = (itemId, payload, token) =>
  request('PUT', `/guest-carts/${token}/items/${itemId}`, payload);
export const removeFromGuestCart = (itemId, token) =>
  request('DELETE', `/guest-carts/${token}/items/${itemId}`);
export const getCartQuote = () => request('GET', '/partial-checkout/cart');
export const addToCartQuote = payload => request('POST', '/partial-checkout/cart/items', payload);
export const updateCartQuoteItem = (itemId, payload) =>
  request('PUT', `/partial-checkout/cart/items/${itemId}`, payload);
export const removeFromCartQuote = itemId =>
  request('DELETE', `/partial-checkout/cart/items/${itemId}`);
export const getShoppingList = (qrcodeId, customerId) =>
  request('GET', `/customers/qrcode-data?qrcode_id=${qrcodeId}&customer_id=${customerId}`);
export const saveShoppingListSku = sku => request('POST', '/customers/qrcode-data', sku);
export const setSourceToCart = payload => request('POST', '/partial-checkout/cart/source', payload);
export const setSourceToGuestCart = (payload, cartId) =>
  request('POST', `/partial-checkout/guest/${cartId}/source`, payload);
export const assignCustomerToGuestCart = (payload, cartId) =>
  request('PUT', `/guest-carts/${cartId}`, payload);
export const batchAddToCart = (payload, cartId) =>
  request('POST', `/carts/${cartId}/batch`, payload);

export const useSaveShoppingListMutation = () => useMutation(sku => saveShoppingListSku(sku));
export const useCheckoutShoppingListMutation = () =>
  useMutation(({ qrcodeId, customerId }) => getShoppingList(qrcodeId, customerId));

export const useAddToCartQuote = () =>
  useMutation(
    payload => {
      const auth = getAuthorization();
      if (auth.type === 'user') {
        return addToCartQuote(payload);
      }
      return addToGuestCart(payload, auth.token);
    },
    {
      mutationKey: 'cart.addToCartQuote',
    },
  );

const createGuestCartRetry = async () => {
  const token = await createGuestCart();
  setAuthorization(token, 'guest');
  return getGuestCart(token);
};

export const useGetCartQuote = () => {
  const dispatch = useDispatch();
  return useQuery(
    'cart.quote',
    async () => {
      const auth = getAuthorization();
      dispatch(toggleLockCheckout({ lock: true }));

      if (!isUndefined(auth.token)) {
        if (auth.type === 'user') {
          return getCartQuote();
        }
        return getGuestCart(auth.token).catch(() => createGuestCartRetry());
      }
      const token = await createGuestCart();
      setAuthorization(token, 'guest');
      return getGuestCart(token);
    },
    {
      ...querySetting,
      onSuccess: () => {
        dispatch(toggleLockCheckout({ lock: false }));
      },
    },
  );
};

export const useRemoveFromCart = () => {
  const auth = getAuthorization();
  const queryClient = useQueryClient();
  return useMutation(
    payload => {
      if (auth.type === 'user') {
        return removeFromCartQuote(payload.itemId);
      }
      return removeFromGuestCart(payload.itemId, auth.token);
    },
    {
      mutationKey: 'cart.removeFromCart',
      onSuccess: () => {
        queryClient.invalidateQueries(['cart.quote']);
      },
    },
  );
};

export const useUpdateCartItem = () => {
  const auth = getAuthorization();
  const { data: quote } = useGetCartQuote();
  const queryClient = useQueryClient();
  return useMutation(
    payload => {
      const newData = JSON.parse(JSON.stringify(payload.data));
      if (auth.type === 'user') {
        newData.cartItem.quote_id = quote.id;
        return updateCartQuoteItem(newData.cartItem.item_id, newData);
      }
      newData.cartItem.quote_id = auth.token;
      return updateGuestCartItem(newData.cartItem.item_id, newData, auth.token);
    },
    {
      mutationKey: 'cart.updateCartItem',
      onSuccess: () => {
        queryClient.invalidateQueries(['cart.quote']);
      },
    },
  );
};

export const useSetSourceToCart = () =>
  useMutation(payload => {
    const auth = getAuthorization();
    if (auth.type === 'user') {
      return setSourceToCart(payload);
    }
    return setSourceToGuestCart(payload, auth.token);
  });

export const useAssignCustomerToGuestCart = () =>
  useMutation(({ cartId, ...rest }) => assignCustomerToGuestCart(rest, cartId));

export const useBatchAddToCart = () => {
  const { data: quote } = useGetCartQuote();
  return useMutation(payload => {
    const auth = getAuthorization();
    if (auth.type === 'user') {
      return batchAddToCart(payload, quote.id);
    }
    return batchAddToCart(payload, auth.token);
  });
};
