import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AngleDownIcon, CheckCircleIcon } from 'components/Icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import DropdownItem from './DropdownItem';

const useStyles = makeStyles(theme => ({
  root: {
    '&$select': {
      paddingRight: theme.spacing(4),
    },
  },
  icon: {
    color: theme.palette.primary.main,
    top: 'auto',
    right: 16,
  },
  select: {
    '&:focus': {
      background: 'none',
      borderRadius: 8,
    },
  },
  listItemText: {
    [theme.breakpoints.up('lg')]: {
      fontWeight: 400,
    },
    '& > .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  listItemIcon: {
    justifyContent: 'flex-end',
    minWidth: 'auto',
  },
}));

const primaryTypographyProps = {
  variant: 'body1',
};

const ArrowIconComponent = props => (
  <SvgIcon
    component={AngleDownIcon}
    viewBox="0 0 12 12"
    fontSize="small"
    color="primary"
    {...props}
  />
);

const Dropdown = forwardRef((props, ref) => {
  const {
    children,
    value,
    rowKey,
    classes: propsClasses,
    options = [],
    textRender,
    MenuItemProps,
    onItemsChecked,
    MenuProps: MenuPropsProp,
    ...other
  } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const history = useHistory();

  const MenuProps = useMemo(
    () => ({
      PaperProps: {
        style: {
          border: `1px solid ${theme.palette.background.darkPeach}`,
          borderRadius: 8,
          boxShadow: '0px 0px 16px rgba(0,0,0,0.08)',
          width: '100%',
          marginTop: 16,
          ...(matchLg
            ? {
                width: 'auto',
                marginTop: 'auto',
                border: `2px solid ${theme.palette.background.peach}`,
              }
            : null),
        },
      },
      MenuListProps: {
        style: {
          padding: 0,
        },
      },
      TransitionComponent: Fade,
      ...MenuPropsProp,
    }),
    [theme, matchLg, MenuPropsProp],
  );

  const handleClick = (option, e) => {
    if (option?.url) {
      history.push(option.url);
    }
    if (onItemsChecked) onItemsChecked(option, e);
  };

  return (
    <Select
      displayEmpty
      classes={{ root: classes.root, icon: classes.icon, select: classes.select }}
      MenuProps={MenuProps}
      value={value}
      {...other}
      ref={ref}
    >
      {children ||
        options?.map(option => {
          const key = option?.[rowKey] ?? option;
          const val = key ?? option.id;
          return (
            <DropdownItem
              key={key}
              value={val}
              option={option}
              onClick={e => handleClick(option, e)}
              MenuItemProps={MenuItemProps}
            >
              <ListItemText
                className={classes.listItemText}
                primary={textRender?.(option) || key}
                primaryTypographyProps={primaryTypographyProps}
              />
              {key === value && (
                <ListItemIcon className={classes.listItemIcon}>
                  <SvgIcon component={CheckCircleIcon} viewBox="0 0 16 16" color="primary" />
                </ListItemIcon>
              )}
            </DropdownItem>
          );
        })}
    </Select>
  );
});

Dropdown.defaultProps = {
  renderValue: text => text,
  rowKey: 'key',
  IconComponent: ArrowIconComponent,
};

Dropdown.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  options: PropTypes.array,
  MenuItemProps: PropTypes.func,
  textRender: PropTypes.func,
  renderValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowKey: PropTypes.string,
  IconComponent: PropTypes.elementType,
  onItemsChecked: PropTypes.func,
  MenuProps: PropTypes.object,
};

export default Dropdown;
