import { FormattedMessage } from 'react-intl';
import messages from 'containers/App/messages';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';

const useCopyMeToClipBoard = () => {
  const notificationSnackbar = useNotificationSnackbar();

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      notificationSnackbar(<FormattedMessage {...messages.copySuccess} />, 'success');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Failed to copy!!!');
    }
  };

  return copyToClipBoard;
};

export default useCopyMeToClipBoard;
