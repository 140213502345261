/**
 * @author zhengji.su
 * @description CartHeader
 */

import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Buttons from 'components/Buttons/Buttons';
import FormatSvgIcon from 'components/FormatMessage/FormatSvgIcon';
import { AngleLeftIcon16, CloseIcon, LocationIcon, QRCodeIcon } from 'components/Icons';
import FormatMessage from 'components/FormatMessage';
import messages from 'containers/CartProvider/messages';
import appMessages from 'containers/App/messages';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import Access, { useAccess } from 'components/Access';
import Skeleton from '@material-ui/lab/Skeleton';
import Hidden from '@material-ui/core/Hidden';
// import { toggleStoreShow } from 'containers/StoreProvider/slices';
import { toggleMiniCart } from 'containers/App/slices';
import { useGetCustomer } from 'containers/UserProfile/queries';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import { useSaveShoppingListMutation } from 'containers/CartProvider/queries';
import useStore from 'containers/StoreProvider/hooks/useStore';
import SharingShoppingDialog from 'containers/SharingProvider/components/SharingShoppingDialog';
import qs from 'qs';

const useStyles = makeStyles(theme => ({
  cartHeader: {
    padding: theme.spacing(0, 2),
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '12px 12px 0 0',
    zIndex: 10,
    height: 50,
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      justifyContent: 'flex-start',
      padding: '24px 16px 8px',
    },
  },
  close: {
    minHeight: 21,
    color: theme.palette.primary.main,
    padding: 0,
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
  },
  goBack: {
    [theme.breakpoints.up('lg')]: {
      fontWeight: 'bold !important',
    },
  },
}));

const CartHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { authorization, loading } = useAccess();
  const dispatch = useDispatch();

  const [openSharingDialog, setOpenSharingDialog] = useState(false);
  const [sharingContent, setSharingContent] = useState(false);

  const { quote, cartItems, selectedItems: selectedIds } = useShoppingCart();
  const { storeSelected } = useStore();

  const selectedItems = cartItems.filter(c => selectedIds.includes(c.item_id));
  const { data: customer = {} } = useGetCustomer();
  const { mutate: saveShoppingList, isLoading: loadingSaveShoppingList } =
    useSaveShoppingListMutation();

  // eslint-disable-next-line consistent-return
  const handleShareShoppingList = async () => {
    // eslint-disable-next-line no-unreachable
    const sku = selectedItems.map(item => ({
      sku: item.sku,
      qty: item.qty,
    }));
    if (!sku.length) return false;

    const subtitle =
      selectedItems.length > 1
        ? `${selectedItems?.[0]?.name}，以及其他${sku.length - 1}件商品`
        : selectedItems?.[0]?.name;

    saveShoppingList(
      {
        sku,
        vendorCode: quote?.extension_attributes?.vendor_code,
      },
      {
        onSuccess: qrcodeId => {
          const params = {
            qrcodeId,
            subtitle,
            customerId: customer.id,
          };
          if (determineEnvironment()) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              // url: `/pages/shareShoppingList/shareShoppingList?qrcodeId=${qrcodeId}&subtitle=${subtitle}&customerId=${customer.id}`,
              url: `/pages/shareShoppingList/shareShoppingList${qs.stringify(params, {
                addQueryPrefix: true,
              })}`,
            });
          } else {
            setOpenSharingDialog(true);
            setSharingContent(params);
          }
        },
      },
    );
  };

  const handleDrawerClose = () => {
    dispatch(toggleMiniCart(false));
  };

  const handleClick = () => {
    // dispatch(toggleStoreShow(true));
  };

  return (
    <>
      <Box width={1} bgcolor="primary.white" className={classes.cartHeader}>
        <Hidden lgUp>
          {loading ? (
            <Skeleton height={25} width="50%" />
          ) : (
            <Box display="flex" alignItems="center">
              <Buttons
                messageId={
                  <Box display="flex" alignItems="center">
                    <FormatSvgIcon
                      icon={<LocationIcon fill={theme.palette.primary.main} />}
                      height={16}
                    />
                    <FormatMessage
                      variant="inherit"
                      message={storeSelected?.name}
                      color="textPrimary"
                      style={{ marginLeft: 5 }}
                    />
                  </Box>
                }
                style={{ padding: 0, marginRight: '1rem' }}
                onClick={handleClick}
              />
              <Access accessible={authorization([4])}>
                <Buttons
                  messageId={
                    <Box display="flex" alignItems="center">
                      <FormatSvgIcon
                        icon={QRCodeIcon}
                        height={16}
                        color="primary"
                        viewBox="0 -3 16 16"
                      />
                      <FormatMessage
                        variant="inherit"
                        message={messages.shoppingListQRCode}
                        color="primary"
                        style={{ marginLeft: 5 }}
                      />
                    </Box>
                  }
                  style={{ padding: 0 }}
                  onClick={handleShareShoppingList}
                  disabled={!selectedItems.length}
                  loading={loadingSaveShoppingList}
                />
              </Access>
            </Box>
          )}
        </Hidden>
        <Buttons
          size="small"
          onClick={handleDrawerClose}
          startIcon={
            matchLg ? (
              <FormatSvgIcon
                icon={AngleLeftIcon16}
                viewBox="2 0 16 16"
                color="primary"
                height={18}
              />
            ) : null
          }
          className={classes.close}
          messageId={
            <Box display="flex" alignItems="center">
              <FormatMessage
                message={matchLg ? messages.continue : appMessages.close}
                color="textPrimary"
                variant="h4"
                className={classes.goBack}
              />
              {matchLg ? null : (
                <FormatSvgIcon
                  icon={CloseIcon}
                  color="primary"
                  height={16}
                  style={{ marginLeft: 8, lineHeight: '20px' }}
                  viewBox="0 0 12 12"
                  iconStyle={{ fontSize: 12 }}
                />
              )}
            </Box>
          }
        />
      </Box>
      <SharingShoppingDialog
        open={openSharingDialog}
        onClose={() => setOpenSharingDialog(false)}
        {...sharingContent}
      />
    </>
  );
};

export default memo(CartHeader);
