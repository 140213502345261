import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { SearchIcon, TimesCircleIcon } from 'components/Icons';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    '& .input': {
      flex: 1,
    },
    '& input[type="search"]::-webkit-search-cancel-button': {
      display: 'none',
    },
  },
  back: {
    paddingRight: theme.spacing(1.5),
    '& .MuiButton-textSizeLarge': {
      padding: 0,
    },
  },
}));

const SearchInput = props => {
  const { onClear, onChange, onKeyDown, value, ...other } = props;
  const classes = useStyles(props);

  const handleChange = e => {
    onChange?.(e.target.value);
  };

  const handleClear = event => {
    onClear?.(event);
  };

  return (
    <Box className={clsx(classes.root, 'search-input')}>
      <Box flex={1}>
        <TextField
          InputProps={{
            startAdornment: <SvgIcon component={SearchIcon} viewBox="0 0 16 16" />,
            ...(value
              ? {
                  endAdornment: (
                    <SvgIcon
                      component={TimesCircleIcon}
                      style={{ cursor: 'pointer' }}
                      color="primary"
                      viewBox="0 0 16 16"
                      onClick={handleClear}
                    />
                  ),
                }
              : null),

            disableUnderline: true,
          }}
          variant="outlined"
          onChange={handleChange}
          autoFocus
          value={value}
          {...other}
        />
      </Box>
    </Box>
  );
};

SearchInput.propTypes = {
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.string,
};

export default SearchInput;
