/**
 * @author zhengji.su
 * @description HeaderTools
 */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Buttons from 'components/Buttons/Buttons';
import MpQrDropdown from 'components/Header/components/MpQrDropdown';
// import Cart from 'components/Header/components/Cart';
// import AccountTag from 'components/Header/components/AccountTag';
import Search from 'components/Header/components/Search';
import Badge from '@material-ui/core/Badge';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import LanguageMenu from 'components/Header/components/LanguageMenu';
import FormatMessage from 'components/FormatMessage';
import { transitionTime } from 'muiTheme';
import routes from 'utils/routes';
import { useHistory } from 'react-router-dom';
import { getAuthorization } from 'utils/Auth';
import useChangeLanguage from 'utils/Hooks/useChangeLanguage';
import { selectLanguage, toggleMiniCart } from 'containers/App/slices';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useShoppingCart from 'utils/Hooks/useShoppingCart';
import clsx from 'clsx';
import { headTools, languageOptions } from '../config';

const useStyles = makeStyles(theme => ({
  container: {},
  divider: {
    margin: theme.spacing(0, 1),
    height: 33,
    backgroundColor: theme.palette.background.darkPeach,
  },
  searchRoot: {
    position: 'absolute',
    right: 0,
    top: '50%',
    height: '100%',
    transform: 'translateY(-50%)',
  },
  buttons: {
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  activeT: {
    '& .end-icon': {
      transform: 'rotate(-180deg)',
      transition: `transform ${transitionTime}s`,
    },
  },
  activeB: {
    '& .end-icon': {
      transform: 'rotate(0deg)',
      transition: `transform ${transitionTime}s`,
    },
  },
}));

const StyledBadge = withStyles(() => ({
  badge: {
    right: 17,
    top: 15,
    // transform: 'scale(0.6)',
    fontSize: 12,
    borderRadius: '50%',
    boxSizing: 'border-box',
  },
}))(Badge);

const HeaderTools = ({ onClickStore }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);
  const { cartItems, showMiniCart } = useShoppingCart();
  const changeLocalLanguage = useChangeLanguage();

  const matchLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [content, setContent] = useState(null);
  const [language, setLanguage] = useState({});
  const [visible, setVisible] = useState({
    cart: false,
    account: false,
    search: false,
    language: false,
    store: false,
  });

  const tools = useMemo(
    () => (visible.search ? [...headTools.slice(2)] : [...headTools]),
    [visible.search],
  );

  useEffect(() => {
    const current = languageOptions.find(item => item.id === lang);

    setLanguage({ ...current });
  }, [lang]);

  useEffect(() => {
    if (matchLg && showMiniCart) {
      setContent('cart');
      setVisible({ ...visible, cart: showMiniCart });
    }
  }, [showMiniCart, matchLg]);

  const handleClose = type =>
    setVisible(() =>
      content
        ? { ...visible, [content]: false, [type]: !visible[type] }
        : { ...visible, [type]: !visible[type] },
    );

  const handleOpen = (event, type) => {
    const auth = getAuthorization();
    if (type === 'account' && (!auth.type || auth.type === 'guest')) {
      history.push(routes.logIn);
    } else {
      setAnchorEl(event.currentTarget);
      setContent(type);
      handleClose(type);
    }
    if (type === 'store') {
      history.push(routes.findStore);
    }

    onClickStore(type === 'store');

    dispatch(toggleMiniCart(type === 'cart'));
  };

  const onCheckLanguage = option => {
    setVisible({ ...visible, language: false });
    if (option.id === lang) return;

    setLanguage(option);
    changeLocalLanguage(option.id);
  };

  const contentRender = type => {
    const render = {
      wechat: <MpQrDropdown anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />,
      // cart: <Cart visible={visible} setVisible={setVisible} />,
      // account: <AccountTag visible={visible} setVisible={setVisible} />,
      language: (
        <LanguageMenu
          visible={visible}
          setVisible={setVisible}
          onCheckLanguage={onCheckLanguage}
          options={languageOptions}
        />
      ),
      // store: <StoreProvider open={visible.store} onClose={() => setVisible((state) => ({ ...state, store: false }))} />,
    };

    return render[type];
  };

  const buttonMessageId = option => {
    let messageId = '';

    if (option.id === 'language') {
      messageId = isEmpty(language) ? (
        option.messageId
      ) : (
        <FormatMessage variant="body2" style={{ fontWeight: 700 }} message={language.title} />
      );
    } else if (option.id === 'store') {
      messageId = option.messageId;
    } else {
      messageId = option.messageId;
    }

    return messageId;
  };

  const button = item => (
    <Box position="relative" display="flex" alignItems="center" height={56} px={1.5}>
      <Buttons
        messageId={buttonMessageId(item)}
        startIcon={item.startIcon && item.startIcon}
        endIcon={item.endIcon && item.endIcon}
        color={item.color && item.color}
        style={item.style && item.style}
        onClick={e => handleOpen(e, item.id)}
        className={clsx(classes.buttons, visible.language ? classes.activeT : classes.activeB)}
      />
      {visible.search && item.id === 'search' && (
        <Box className={classes.searchRoot}>
          <Search visible={visible} setVisible={setVisible} />
        </Box>
      )}
    </Box>
  );

  return (
    <Box className={classes.container}>
      <Box display="flex">
        {tools.map(item => (
          <Box display="flex" alignItems="center" key={item.id}>
            {item.id === 'cart' ? (
              <StyledBadge color="primary" badgeContent={cartItems?.length}>
                {button(item)}
              </StyledBadge>
            ) : (
              button(item)
            )}
            {item.border && (
              <Divider variant="inset" orientation="vertical" className={classes.divider} />
            )}
          </Box>
        ))}
      </Box>
      <Box>{contentRender(content)}</Box>
    </Box>
  );
};
HeaderTools.propTypes = {
  onClickStore: PropTypes.func,
};
export default HeaderTools;
