/*
 * SharingProvider Messages
 *
 * This contains all the text for the SharingProvider container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SharingProvider';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the SharingProvider container!',
  },
  shareProduct: {
    id: `${scope}.shareProduct`,
    defaultMessage: 'Share Product',
  },
  FCInvitation: {
    id: `${scope}.FCInvitation`,
    defaultMessage: 'DC Invitation',
  },
  RCInvitation: {
    id: `${scope}.RCInvitation`,
    defaultMessage: 'RC Invitation',
  },
  inviteToFC: {
    id: `${scope}.inviteToFC`,
    defaultMessage: 'Invite you to become a partner of FEIDEKAI',
  },
  inviteYou: {
    id: `${scope}.inviteYou`,
    defaultMessage: 'Invite you',
  },
  FCSubtitle: {
    id: `${scope}.FCSubtitle`,
    defaultMessage:
      'Scan to open the mini program, become a distributor, and get market dividends together!',
  },
  RCSubtitle: {
    id: `${scope}.RCSubtitle`,
    defaultMessage:
      'Scan to open the mini program, and immediately high-quality German products. There are more shopping offers waiting for you',
  },
  FCDesc: {
    id: `${scope}.FCDesc`,
    defaultMessage:
      'After your friends scan the QR code to log in, they will become a new partner under your recommendation, and you will also get more commissions!',
  },
  RCDesc: {
    id: `${scope}.RCDesc`,
    defaultMessage:
      'After your friend scans the QR code to log in, they will automatically become your exclusive member. In the future, you will get platform rewards for every order your friend purchases using the FEIDEKAI WeChat mall.',
  },
  viewTheFriends: {
    id: `${scope}.viewTheFriends`,
    defaultMessage: 'View the friends I have invited',
  },
  shareWithFriends: {
    id: `${scope}.shareWithFriends`,
    defaultMessage: 'Share',
  },
  savePicture: {
    id: `${scope}.savePicture`,
    defaultMessage: 'Save Picture',
  },
  copyAppId: {
    id: `${scope}.copyAppId`,
    defaultMessage: 'Copy appid',
  },
  copyLink: {
    id: `${scope}.copyLink`,
    defaultMessage: 'Copy Link',
  },
  recommendToYourFriends: {
    id: `${scope}.recommendToYourFriends`,
    defaultMessage:
      'Recommend FEIDEKAI to your friends, this gift voucher code can bring an extra 10% discount for their first order, and an extra 5% discount for repurchase orders!',
  },
  sharedListDesc: {
    id: `${scope}.sharedListDesc`,
    defaultMessage:
      'Share the QR code of this shopping list with your partners so that they can directly scan the code to place an order. This QR code is valid forever and also contains your referral code, which is used to share with new customers to register for an account.',
  },
  somebodySharedAList: {
    id: `${scope}.somebodySharedAList`,
    defaultMessage: '{name} shared a list',
  },
  invitee: {
    id: `${scope}.invitee`,
    defaultMessage: 'Invitee',
  },
  myInvitation: {
    id: `${scope}.myInvitation`,
    defaultMessage: 'I Invited',
  },
  invitationRecord: {
    id: `${scope}.invitationRecord`,
    defaultMessage: 'Invitation record',
  },
  invitationType: {
    id: `${scope}.invitationType`,
    defaultMessage: 'Invitation Type',
  },
  registered: {
    id: `${scope}.registered`,
    defaultMessage: 'Registered',
  },
  emptyInvite: {
    id: `${scope}.emptyInvite`,
    defaultMessage: "You haven't invited anyone",
  },
  invitationStatus: {
    id: `${scope}.invitationStatus`,
    defaultMessage: 'Status',
  },
  partnerInvitation: {
    id: `${scope}.partnerInvitation`,
    defaultMessage: 'Partner invitation',
  },
  generalInvitation: {
    id: `${scope}.generalInvitation`,
    defaultMessage: 'General client invitation',
  },
  invitationCode: {
    id: `${scope}.invitationCode`,
    defaultMessage: 'Invite',
  },
});
