import { actions as notificationActions } from 'utils/Notifications/actions';
import { useDispatch } from 'react-redux';

const useNotificationSnackbar = () => {
  const dispatch = useDispatch();

  const notificationSnackbar = (message, type) => {
    dispatch(
      notificationActions.enqueueSnackbar({
        message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: type,
        },
      }),
    );
  };
  return notificationSnackbar;
};

export default useNotificationSnackbar;
