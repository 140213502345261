exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./FFClanProRegular.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./FFClanProRegular.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./FFClanProBold.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./FFClanProBold.woff"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./HelveticaNeueLTPro-Roman.woff2"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./HelveticaNeueLTPro-Roman.woff"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./HelveticaNeueLTPro-Md.woff2"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./HelveticaNeueLTPro-Md.woff"));

// Module
exports.push([module.id, "/* Clan Pro-500normal - latin */\n@font-face {\n\tfont-family: 'Clan Pro';\n\tfont-style: normal;\n\tfont-display: swap;\n\tfont-weight: 500;\n\tsrc: local('Clan Pro'), local('ClanProRegular'), url(" + ___CSS_LOADER_URL___0___ + ") format('woff2'),\n\t\turl(" + ___CSS_LOADER_URL___1___ + ") format('woff');\n}\n\n/* Clan Pro-700normal - latin */\n@font-face {\n\tfont-family: 'Clan Pro';\n\tfont-style: normal;\n\tfont-display: swap;\n\tfont-weight: 700;\n\tsrc: local('Clan Pro'), local('ClanProBold'), url(" + ___CSS_LOADER_URL___2___ + ") format('woff2'),\n\t\turl(" + ___CSS_LOADER_URL___3___ + ") format('woff');\n}\n\n/* Helvetica Neue LT Pro - Roman */\n@font-face {\n\tfont-family: 'Helvetica Neue LT Pro';\n\tfont-style: normal;\n\tfont-display: swap;\n\tfont-weight: 400;\n\tsrc: local('Helvetica Neue LT Pro'), local('HelveticaNeueLTProRoman'),\n\t\turl(" + ___CSS_LOADER_URL___4___ + ") format('woff2'),\n\t\turl(" + ___CSS_LOADER_URL___5___ + ") format('woff');\n}\n\n/* Helvetica Neue LT Pro - Md */\n@font-face {\n\tfont-family: 'Helvetica Neue LT Pro';\n\tfont-style: normal;\n\tfont-display: swap;\n\tfont-weight: 500;\n\tsrc: local('Helvetica Neue LT Pro'), local('HelveticaNeueLTProMd'),\n\t\turl(" + ___CSS_LOADER_URL___6___ + ") format('woff2'),\n\t\turl(" + ___CSS_LOADER_URL___7___ + ") format('woff');\n}\n", ""]);

