import PropTypes from 'prop-types';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavigateIcon } from 'components/Icons';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderRadius: 8,
    boxShadow: '0 0 16px rgba(0, 0,0,0.08)',
    padding: 24,
  },
  title: {
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 700,
    margin: 0,
  },
  divider: {
    width: 1,
    height: 24,
    margin: '0 24px',
    backgroundColor: '#F6D7A4',
  },
  navigateIcon: {
    width: 24,
    height: 24,
    color: '#CD0039',
    appearance: 'none',
    border: 'none',
    background: 'none',
    padding: 0,
    cursor: 'pointer',
  },
}));
const InfoWindowContent = ({ title, id }) => {
  const classes = useStyles();

  return (
    <div className={clsx('infowindow-root', classes.root)}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.divider} />
      <button
        type="button"
        data-id={id}
        className={clsx('infowindow-navigate-button', classes.navigateIcon)}
      >
        <NavigateIcon />
      </button>
    </div>
  );
};
InfoWindowContent.propTypes = {
  title: PropTypes.any,
  id: PropTypes.string,
};
export default memo(InfoWindowContent);
