/**
 *
 * ConfirmRemoval
 *
 */

import { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import CircularProgress from '@material-ui/core/CircularProgress';
import appMessages from 'containers/App/messages';
import Buttons from 'components/Buttons/Buttons';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import FormatMessage from 'components/FormatMessage';
import messages from 'containers/CartProvider/messages';
import { FitLineBrandColorRed } from '../../muiTheme';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
    },
    '& .MuiPaper-root': {
      width: ({ width }) => width,
    },
  },
  content: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },
  assistant: {
    padding: 0,
    minHeight: 'auto',
  },
}));

function Index(props) {
  const {
    open,
    onClose,
    onAssistant,
    onConfirm,
    loading,
    title,
    description,
    cartName,
    renderContent,
    confirmBtnMsg,
    assistantBtnMsg,
    width,
    ...other
  } = props;
  const classes = useStyles(props);

  const content = () => {
    // if (loading) {
    //   return (
    //     <Box align="center">
    //       <Typography color="primary"><FormattedMessage {...messages.processing} /></Typography>
    //       <CircularProgress size={18} style={{ margin: 5 }} />
    //     </Box>
    //   );
    // }
    if (description && description !== '') {
      return (
        <Typography color="textSecondary" variant="inherit" className={classes.content}>
          {description}
        </Typography>
      );
    }
    return (
      <FormatMessage
        color="textSecondary"
        message={{ ...messages.destructiveAction, values: { cartName } }}
        className={classes.content}
      />
    );
  };

  return (
    <Dialog
      open={open}
      classes={{ container: classes.dialogContainer }}
      onClose={onClose}
      {...other}
    >
      <>
        <Box style={{ textAlign: 'center', padding: '2rem 0 0' }}>
          <Typography variant="h1">
            {title && title !== '' ? title : <FormattedMessage {...messages.deleteItem} />}
          </Typography>
        </Box>
        <Box px={2} py={1} textAlign="center" color="divider">
          {renderContent ? renderContent() : content()}
        </Box>
        <Box p={2} pb={onAssistant ? 3 : 4} className="dialog-buttons">
          {onConfirm && (
            <Box>
              <Buttons
                messageId={confirmBtnMsg}
                onClick={onConfirm}
                loading={loading}
                disabled={loading}
                variant="contained"
                color="primary"
              />
            </Box>
          )}
          {onAssistant && (
            <Box pt={3}>
              <Buttons
                style={{ width: '100%', color: FitLineBrandColorRed }}
                disabled={loading}
                messageId={assistantBtnMsg}
                onClick={onAssistant}
                className={classes.assistant}
              />
            </Box>
          )}
        </Box>
      </>
    </Dialog>
  );
}

Index.defaultProps = {
  loading: false,
  confirmBtnMsg: <FormatMessage message={appMessages.removeItems} variant="h3" bold={700} />,
  assistantBtnMsg: <FormatMessage message={appMessages.moveToWishlist} variant="h3" bold={700} />,
};

Index.propTypes = {
  open: PropTypes.bool.isRequired,
  cartName: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onAssistant: PropTypes.any,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  renderContent: PropTypes.func,
  confirmBtnMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  assistantBtnMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(Index);
